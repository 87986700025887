<template>
  <transition name="modal-transition">
    <div class="custom-modal">
      <div class="custom-modal-wrapper" @click="$emit('close')">
        <div class="custom-modal-container" @click.stop>
          <div>
            <dashboard-header :title="onboarding_data[current_page].title">
              <button class="btn btn-shape btn-top" @click="$emit('close')">
                <i class="fas fa-times" />
              </button>
            </dashboard-header>
            <h6>{{ onboarding_data[current_page].subtitle }}</h6>
            <img
              :src="
                require(`@/assets/${onboarding_data[current_page].img}.png`)
              "
              height="256"
            />
            <div
              style="
                display: flex;
                flex-direction: row;
                justify-content: space-between;
              "
            >
              <button class="btn btn-shape btn-top" @click="previous">
                <i class="fas fa-arrow-left" />
              </button>
              <button class="btn btn-shape btn-top" @click="next">
                <i class="fas fa-arrow-right" />
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </transition>
</template>

<script>
  import DashboardHeader from "@/components/DashboardHeader";

  export default {
    name: "OnboardingModal",
    components: {
      DashboardHeader
    },
    props: {
      title: {
        type: String
      }
    },
    data() {
      return {
        current_page: 0,
        onboarding_data: [
          {
            id: 1,
            title: "Welcome to the new #NotMe dashboard!",
            subtitle:
              "We're excited to share numerous updates that will improve the dashboard's design, your overall user experience and give you more searchable and actionable data.",
            img: "Onboarding-1"
          },
          {
            id: 2,
            title: "Better and more powerful filters",
            subtitle:
              "Our new Filters feature will help you to easily sort, search and explore reports.",
            img: "Onboarding-2"
          },
          {
            id: 3,
            title: "Access tiers and assignment capabilities",
            subtitle:
              "There are now three tiers of accessibility - Administrator, Manager, Subscriber - and the ability to assign reports to designated individuals.",
            img: "Onboarding-3"
          },
          {
            id: 4,
            title: "Enhanced settings for more flexibility",
            subtitle:
              "Manage permissions, access rights and certain elements of customization right from your dashboard.",
            img: "Onboarding-4"
          }
        ]
      };
    },
    methods: {
      next() {
        let limit = this.onboarding_data.length - 1;
        if (this.current_page < limit) {
          this.current_page += 1;
        } else {
          this.$emit("close");
        }
      },
      previous() {
        if (this.current_page > 0) {
          this.current_page -= 1;
        }
      }
    }
  };
</script>

<style lang="scss">
  .custom-modal {
    position: fixed;
    z-index: 2001;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    overflow: auto;
    background-color: rgba(0, 0, 0, 0.4);
    display: table;
    transition: opacity 0.3s ease;

    .custom-modal-wrapper {
      display: table-cell;
      vertical-align: middle;

      .custom-modal-container {
        background-color: $ui-card;
        margin: 0px auto;
        padding: 20px;
        border-radius: $widget-radius;
        max-width: 400px;
        transition: all 0.3s ease;

        .header {
          @media (max-width: 576px) {
            display: flex;
            flex-direction: row;
            justify-content: space-between;
          }
          margin-bottom: 16px;
        }

        h4 {
          font-size: 26px;
        }

        h6 {
          font-size: 14px;
          color: $ui-subtitle;
        }

        .close-button {
          float: right;
          font-size: 12px;
          font-family: "Campton-Medium";
          padding-top: 2px;
          color: $ui-red;
          text-decoration: none;
          cursor: pointer;
          &:hover {
            color: $ui-text;
          }
        }

        .btn-shape {
          border-radius: 20px;
          border-width: 0px;
          height: 35px !important;
          margin-left: 12px;
          &:hover {
            -webkit-box-shadow: $widget-shadow;
            -moz-box-shadow: $widget-shadow;
            box-shadow: $widget-shadow;
          }
        }

        .btn-shape.btn-top {
          vertical-align: top;
          color: $ui-text;
          background-color: $ui-white;
          font-size: 10px;
          width: 35px;
          border: $ui-light_grey solid 1px;
        }
      }
    }
  }

  .modal-transition-enter {
    opacity: 0;
  }

  .modal-transition-leave-active {
    opacity: 0;
  }

  .modal-transition-enter .custom-modal-container,
  .modal-transition-leave-active .custom-modal-container {
    -webkit-transform: scale(1.1);
    transform: scale(1.1);
  }
</style>
