const accumulateEventsForTimeline = (events, keyToCompare, groupKey) => {
  const accumulatedEvents = events.reduce((accumulator, currentValue) => {
    const dateExists = accumulator.findIndex(
      (event) =>
        event.updated_on.isSame(currentValue.updated_on) &&
        event.deletion == currentValue.deletion
    );
    if (dateExists > -1) {
      accumulator[dateExists][groupKey].push(currentValue[keyToCompare]);
    } else {
      accumulator.push({
        ...currentValue,
        [groupKey]: [currentValue[keyToCompare]]
      });
    }
    return accumulator;
  }, []);
  return accumulatedEvents;
};

const groupEventsByDate = (events) => {
  let eventsByDate = {};
  events.forEach((event) => {
    eventsByDate[event.updated_on._i]
      ? eventsByDate[event.updated_on._i].push(event)
      : (eventsByDate[event.updated_on._i] = [event]);
  });
  return eventsByDate;
};

const compareSnapshots = (eventsByDate, keyToCompare) => {
  let tempEvents;
  let previousSnap = [];
  let addedItems = [];
  let removedItems = [];
  Object.keys(eventsByDate).forEach((item) => {
    const added = eventsByDate[item].filter(
      (eventDoc) =>
        !previousSnap.some(
          (x) =>
            drillObjectWithString(x, keyToCompare) ===
            drillObjectWithString(eventDoc, keyToCompare)
        )
    );
    let removed = previousSnap.filter(
      (eventDoc) =>
        !eventsByDate[item].some(
          (x) =>
            drillObjectWithString(x, keyToCompare) ===
            drillObjectWithString(eventDoc, keyToCompare)
        )
    );
    if (removed) {
      removed = removed.map((removedItem) => {
        return {
          ...removedItem,
          updated_on: eventsByDate[item][0]
            ? eventsByDate[item][0].updated_on
            : removedItem.updated_on
        };
      });
    }
    addedItems.push(added);
    removedItems.push(removed);
    previousSnap = eventsByDate[item];
  });
  addedItems = addedItems.flat();
  removedItems = removedItems.flat();
  removedItems = removedItems.map((removedDoc) => {
    return {
      ...removedDoc,
      deletion: true
    };
  });
  tempEvents = addedItems.concat(removedItems);
  return tempEvents;
};

const drillObjectWithString = (obj, str) => {
  return str.split(".").reduce((p, c) => p?.[c], obj);
};

export default {
  groupEventsByDate,
  compareSnapshots,
  accumulateEventsForTimeline
};
