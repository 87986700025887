import api from "./currentApi";
import constants from "./constants";

const getAnalyticsGraphs = (custom_base_url) => {
  return api.request_logged_custom(custom_base_url, constants.ANALYTICS_GRAPHS);
};

const getAnalyticsReport = (
  custom_base_url,
  cid,
  start_date,
  end_date,
  operator,
  admin,
  graphs,
  company_ids,
  format,
  sort_by,
  order_by,
  offset,
  limit
) => {
  const analyticsUrl = admin
    ? constants.ANALYTICS_ADMIN_REPORT
    : `${constants.ANALYTICS_REPORT}${cid}`;
  return api.request_post_custom(custom_base_url, analyticsUrl, {
    response_format: format,
    graphs: graphs,
    company_ids: company_ids,
    date_field: operator ? "submitted_at" : null,
    date_range: operator
      ? {
          start_date: start_date,
          end_date: end_date,
          operator: operator
        }
      : null,
    sort_by,
    order_by,
    offset,
    limit
  });
};

const getAnalyticsReportAll = (
  custom_base_url,
  format,
  graph_ids,
  company_ids,
  start_date,
  end_date
) => {
  return api.request_post_custom(
    custom_base_url,
    constants.ANALYTICS_ADMIN_REPORT,
    {
      response_format: format,
      graphs: graph_ids,
      company_ids: company_ids,

      date_field: "submitted_at",
      date_range: {
        start_date: start_date,
        end_date: end_date,
        operator: "BETWEEN"
      }
    }
  );
};

export default {
  getAnalyticsGraphs,
  getAnalyticsReport,
  getAnalyticsReportAll
};
