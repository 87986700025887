import api from "./currentApi";
import constants from "./constants";

const getReports = (params) => {
  return api.request_logged(constants.REPORTS_URL, params);
};

const getReport = (id) => {
  return api.request_logged(constants.REPORTS_URL + "/" + id);
};

const updateReportStatus = (report_id, status) => {
  return api.request_logged_put(constants.REPORTS_URL + "/" + report_id, {
    status_id: status
  });
};

const updateReportCompany = (report_id, company_id) => {
  return api.request_logged_put(constants.REPORTS_URL + "/" + report_id, {
    company_id: company_id
  });
};

const updateNote = (report_id, new_note) => {
  return api.request_logged_put(constants.REPORTS_URL + "/" + report_id, {
    notes: new_note
  });
};

const updateAssignees = (report_id, assignees) => {
  return api.request_logged_put_json(constants.REPORTS_URL + "/" + report_id, {
    assignees: assignees
  });
};

const updateTags = (report_id, tags) => {
  return api.request_logged_put_json(constants.REPORTS_URL + "/" + report_id, {
    tags
  });
};

const postCreateReport = (email) => {
  return api.request_logged_post(constants.REPORTS_URL, {
    email: email
  });
};

const postReportResolution = (report_id, resolution) => {
  return api.request_logged_post(
    constants.REPORTS_URL +
      "/" +
      report_id +
      "/" +
      constants.REPORT_RESOLUTION_URL,
    {
      type: resolution.type,
      notes: resolution.notes,
      outcomes: resolution.outcomes
    }
  );
};

const getReportStep = (report_id, step_id) => {
  return api.request_logged(
    constants.REPORTS_URL + "/" + report_id + "/step/" + step_id
  );
};

const postReportStep = (report_id, step_id, question) => {
  return api.request_logged_post(
    constants.REPORTS_URL + "/" + report_id + "/step/" + step_id,
    {
      question: question
    }
  );
};

const postEvidence = (report_id, files, onUploadProgress) => {
  return api.request_logged_post(
    constants.REPORTS_URL + "/" + report_id + "/step/" + step_id,
    files,
    onUploadProgress
  );
};

const uploadEvidence = (report_id, files, onUploadProgress) => {
  return api.request_logged_post_multipart_progress(
    constants.REPORTS_URL + "/" + report_id + "/upload",
    files,
    onUploadProgress
  );
};

const deleteEvidence = (report_id, document_id) => {
  return api.request_logged_delete(
    constants.REPORTS_URL + "/" + report_id + "/file/" + document_id
  );
};

export default {
  getReports,
  getReport,
  updateReportStatus,
  updateReportCompany,
  updateNote,
  updateAssignees,
  updateTags,
  postCreateReport,
  postReportResolution,
  getReportStep,
  postReportStep,
  postEvidence,
  uploadEvidence,
  deleteEvidence
};
