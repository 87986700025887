<template>
  <div class="dashboard-cell place-cell">
    <div class="place-container">
      <a style="width: 100%; align-self: center">
        <div class="row place-row">
          <div class="col-lg-8 col-md-8 col-sm-8 col-7 my-auto title-container">
            <p class="place-title">
              {{ place.place }}
            </p>
            <ui-tag
              v-if="place.category_name"
              context="dark_grey"
              subtle
              round
              small
            >
              {{ place.category_name }}
            </ui-tag>
          </div>
          <div class="col-lg-4 col-md-4 col-sm-4 col-5 my-auto">
            <el-progress
              :percentage="setPercentage(place.nb_reports)"
              :show-text="false"
              color="#3a6e58"
            />
          </div>
        </div>
      </a>
      <div class="right-content">
        <router-link :to="{ path: '/reports?place_id=' + place.id }">
          <ui-tag context="dark_green" subtle circle hoverShadow small>{{
            getNumberReport(place.nb_reports)
          }}</ui-tag>
        </router-link>
      </div>
    </div>
  </div>
</template>

<script>
  import UiTag from "@/components/ui-modules/Tag";

  export default {
    name: "PlaceCell",
    components: {
      UiTag
    },
    props: ["place", "maxValue"],
    methods: {
      getNumberReport(nb_report) {
        return (
          nb_report +
          " " +
          (nb_report !== 1 ? this.$t("reports_noun") : this.$t("report_noun"))
        );
      },
      setPercentage(nb_reports) {
        return Math.min(100, nb_reports * (100 / this.maxValue));
      }
    }
  };
</script>

<style lang="scss">
  .place-cell {
    transition: all 0.1s ease-in-out;
    padding-top: 10px;
    padding-bottom: 10px;
    text-align: left;
    a {
      color: $ui-text;
      text-decoration: none;
    }
    p {
      margin-bottom: 0px;
    }

    .place-row {
      margin-right: 0px;
      margin-left: 0px;
      .title-container {
        display: flex;
        align-items: center;
        flex-wrap: wrap;
        gap: 10px;
      }
    }
    .place-container {
      display: flex;
      flex-direction: row;

      .left-content {
        padding-left: 20px;
        padding-right: 20px;
        align-self: center;
      }
      .right-content {
        padding-left: 20px;
        padding-right: 20px;
        align-self: center;
      }

      .place-title {
        text-align: left;
        color: $ui-text;
        font-family: "Campton-Medium";
      }
    }
  }
</style>
