<template>
  <div class="reports">
    <dashboard-header
      title="pages.dashboard.reports"
      description="dashboard_sections.reports.description"
      descriptionLink="https://help.not-me.com/report/reports"
      :isSideFlex="true"
    >
      <!-- Reporter - Report creation button
      -->
      <!-- <button-arrow
        :value="show_filters"
        :on-click="showDropdown"
        title="filters"
      /> -->
      <div class="reports-top-right-container">
        <search-bar
          :placeholder="$t('search_reports')"
          :searchQueries="searchQueries"
          v-on:updateSearchQueries="updateSearchQueries"
          v-on:addToSearchQueries="addToSearchQueries"
          v-on:removeIndexSearchQueries="removeIndexSearchQueries"
        />
        <button-sorting
          :sort_filters="sort_filters"
          :current_index="current_sort_filter_index"
          :order_desc="query.order_by === 'desc'"
          element_title="title"
          :update-sort-by="handle_sort_command"
          :update-order-by="handle_order_command"
        />
      </div>
    </dashboard-header>
    <div v-if="!listLoading">
      <div v-if="reports !== null && reports.length > 0" class="table-reports">
        <report-cell
          v-for="(item, index) in reports"
          :key="index"
          :report="item"
          :items-selected="itemsSelected"
        />
        <pagination-component
          :current_page="current_page"
          :limit="Number(query.limit)"
          :total="total"
          :handle-current-change="handleCurrentChange"
          color="#c1473a"
        />
      </div>
      <no-data-content v-else title="reports_available" />
    </div>
    <div v-loading.lock="listLoading" v-else class="loader-middle" />
    <!-- <div
      :style="Object.values(itemsSelected).filter(item => {return item === true}).length > 0 ? '' : 'display: none;'"
      class="toolbar-banner">
      <div class="toolbar-container">
        <div class="toolbar-container-content">
          <div class="col-1 my-auto">
            <a
              class="clear"
              @click="clearSelection"><i class="fas fa-times"/></a>
          </div>
          <div class="col-5 my-auto">
            <p><span class="number"><a>{{ Object.values(itemsSelected).filter(item => {return item === true}).length }}</a></span>items selected</p>
          </div>
          <div class="col-3 my-auto">
            <el-button class="filter-button">
              <i class="fas fa-pen"/> Status
            </el-button>
          </div>
          <div class="col-3 my-auto">
            <el-button class="filter-button">
              <i class="fas fa-user-friends"/> Assign
            </el-button>
          </div>
        </div>
      </div>
    </div> -->
  </div>
</template>

<script>
  import moment from "moment";
  import reportApi from "@/api/reportApi";
  import DashboardHeader from "@/components/DashboardHeader";
  import NoDataContent from "@/components/NoDataContent";
  import SearchBar from "@/components/SearchBar";
  import ButtonArrow from "@/components/ui-modules/Buttons/ButtonArrow";
  import ButtonSorting from "@/components/ui-modules/Buttons/ButtonSorting";
  import ReportCell from "./components/ReportCell";
  import PaginationComponent from "@/components/PaginationComponent";
  import Multiselect from "vue-multiselect";
  import url_utils from "@/utils/url_utils";
  import report_utils from "@/utils/report_utils";
  import permissions_utils from "@/utils/permissions_utils";
  import search_categories from "@/components/SearchBar/search_categories";

  export default {
    name: "Reports",
    components: {
      DashboardHeader,
      NoDataContent,
      SearchBar,
      ButtonSorting,
      ButtonArrow,
      ReportCell,
      PaginationComponent,
      Multiselect
    },
    data() {
      return {
        reports: null,
        listLoading: false,
        current_sort_filter_index: 0,
        current_date_range_index: 0,
        current_anonymous_index: 0,
        value: "",
        current_page: 0,
        total: null,
        itemsSelected: {},
        sort_filters: [
          {
            title: "submitted_at",
            sort_by: "submitted_at"
          },
          {
            title: "score",
            sort_by: "score"
          }
        ],
        query: {
          offset: 0,
          sort_by: "submitted_at",
          order_by: "desc",
          limit: 10
        },
        initial_query: {
          offset: 0,
          sort_by: "submitted_at",
          order_by: "desc",
          limit: 10,
          start_date: null,
          harasser_id: null,
          user_id: null,
          behavior_id: null,
          place_id: null,
          location_id: null,
          status_ids: [],
          slug: [],
          scores: [],
          tags: [],
          is_anonymous: null
        },
        default_query: {
          offset: 0,
          limit: 10
        },
        show_filters: false,
        comp_tmp: "",
        searchQueries: [],
        updateSearchBar: false
      };
    },
    computed: {
      selected_company() {
        return this.$store.getters.getSelectedCompanies();
      }
    },
    watch: {
      $route(to, from) {
        if (
          from.name === to.name &&
          JSON.stringify(to.query) === JSON.stringify({})
        ) {
          this.current_page = 0;
          this.value = "";
          this.current_sort_filter_index = 0;
          this.current_date_range_index = 0;
          this.updateQuery(this.initial_query);
        } else {
          this.current_page = to.query.offset / to.query.limit + 1;
          this.getReports();
        }
      },
      selected_company(newValue) {
        let newQuery = [];
        if (newValue) {
          newQuery = [...newValue];
        } else {
          newQuery = null;
        }
        this.updateQuery({
          offset: 0,
          company_ids: newQuery
        });
      },
      searchQueries(newValue, oldValue) {
        if (this.updateSearchBar) {
          let query = { offset: 0, limit: 10 };
          query.sort_by = this.query.sort_by;
          query.order_by = this.query.order_by;
          if (this.query.company_ids) {
            query.company_ids = this.query.company_ids;
          }
          if (newValue.length > 0) {
            newValue.forEach((element) => {
              if (element.category.multiple === true) {
                if (query[element.category.query]) {
                  query[element.category.query].push(element.value.id);
                } else {
                  query[element.category.query] = [element.value.id];
                }
              } else {
                query[element.category.query] = element.value.id;
              }
            });
          } else {
            query = query;
          }
          this.updateCurrentQuery(query);
        }
      }
    },
    created() {
      this.initRoute();
    },
    methods: {
      populateSearchBar(current_query) {
        for (const [key, value] of Object.entries(current_query)) {
          let found_element = search_categories.find(
            (x) => x.query === key.toLowerCase()
          );

          if (found_element !== undefined) {
            if (Array.isArray(value)) {
              value.forEach((element) => {
                this.searchQueries.push({
                  category: found_element,
                  value: {
                    id: element
                  }
                });
              });
            } else {
              this.searchQueries.push({
                category: found_element,
                value: {
                  id: value
                }
              });
            }
          }
        }
      },
      populateSort(current_query) {
        let sort_by = current_query.sort_by;
        let order_by = current_query.order_by;
        if (sort_by) {
          const indexSortBy = this.sort_filters.findIndex(
            (e) => e.sort_by === sort_by
          );
          this.query.sort_by = sort_by;
          this.current_sort_filter_index = indexSortBy;
        }
        if (order_by) {
          this.query.order_by = order_by;
        }
      },
      initRoute() {
        var queryTmp = this.$route.query;

        queryTmp = {
          ...queryTmp,
          ...url_utils.urlParamsToObject(window.location.search)
        };

        const selectedCompanies = this.$store.getters.getSelectedCompanies();
        if (selectedCompanies) {
          queryTmp.company_ids = [...selectedCompanies];
        } else {
          queryTmp.company_ids = null;
        }

        if (
          queryTmp.offset !== undefined &&
          queryTmp.offset !== null &&
          queryTmp !== this.query
        ) {
          this.query = queryTmp;
          this.current_page = queryTmp.offset / queryTmp.limit + 1;
        } else if (queryTmp !== this.query) {
          this.query = {
            ...this.query,
            ...queryTmp
          };
          this.current_page = this.query.offset / this.query.limit + 1;
        }

        if (this.query !== {}) {
          this.populateSort(this.query);
          this.populateSearchBar(this.query);
        }

        if (
          Object.keys(this.query).sort().join(",") !==
          Object.keys(this.$route.query).sort().join(",")
        ) {
          this.$router.replace({
            path: "reports",
            query: this.query
          });
        } else {
          this.getReports(this.query);
        }
      },
      getReports(query) {
        // const today = new Date();
        // console.log("GET REPORTS", `${today.getMinutes()}:${today.getSeconds()}.${today.getMilliseconds()}`)
        this.listLoading = true;
        reportApi
          .getReports(query ? query : this.query)
          .then((response) => {
            if (response.status === 200) {
              this.reports = response.data.reports;
              this.total = response.data.page.total;
              this.listLoading = false;
            }
          })
          .catch((_) => {
            this.listLoading = false;
          });
      },
      updateQuery(query) {
        this.query = {
          ...this.query,
          ...query
        };
        let queryToAdd = Object.fromEntries(
          Object.entries(this.query).filter(([_, value]) => value !== null)
        );
        this.$router
          .push({
            path: "reports",
            query: queryToAdd
          })
          .catch(() => {});
      },
      updateCurrentQuery(query) {
        this.query = query;
        this.$router
          .push({
            path: "reports",
            query: this.query
          })
          .catch(() => {});
      },
      handle_sort_command(command) {
        this.current_page = 0;
        this.current_sort_filter_index = command;
        this.updateQuery({
          offset: 0,
          sort_by: this.sort_filters[this.current_sort_filter_index].sort_by
        });
      },
      handle_order_command() {
        this.current_page = 0;
        this.updateQuery({
          offset: 0,
          order_by: this.query.order_by === "desc" ? "asc" : "desc"
        });
      },
      handleCurrentChange(val) {
        this.current_page = val;
        let offset = (val - 1) * Number(this.query.limit);
        this.updateQuery({ offset });
      },
      clearSelection() {
        this.itemsSelected = {};
        this.updateSearchBar = true;
      },
      updateSearchQueries(searchQueries) {
        this.searchQueries = searchQueries;
        this.updateSearchBar = true;
      },
      addToSearchQueries(searchQuery) {
        this.searchQueries.push(searchQuery);
        this.updateSearchBar = true;
      },
      removeIndexSearchQueries(index) {
        this.searchQueries.splice(index, 1);
        this.updateSearchBar = true;
      },
      can(name) {
        return permissions_utils.can(name);
      }
    }
  };
</script>

<style lang="scss">
  .slide-enter-active,
  .slide-leave-active {
    transition: all 0.3s ease-in;
    max-height: 230px;
  }
  .slide-enter,
  .slide-leave-to {
    transform: translateY(-10px);
    opacity: 0;
    max-height: 0px;
  }

  .reports {
    .multiselect__tag {
      background: none;
      border: 1px solid $ui-red;
      color: $ui-red;
      font-family: "Campton-Medium";
      border-radius: 20px;
      .multiselect__tag-icon {
        &:hover {
          background: none;
        }
        &:hover:after {
          font-family: "Campton-Medium";
          color: $ui-red;
        }
        &:after {
          color: $ui-text;
        }
      }
    }

    .reports-top-right-container {
      display: flex;
      flex-direction: row;
      gap: 10px;
      margin-bottom: 0px;
      margin-left: 20px;
      @media (max-width: 575px) {
        flex-direction: column;
        margin: 0px;
      }
    }

    .new-report-button {
      background-color: $ui-red;
      color: white;
      &:hover,
      &:active,
      &:focus {
        background-color: $ui-red;
        color: white;
        font-family: "Campton-Bold";
        border: none;
      }
    }

    .row-filters {
      text-align: left;
      margin-bottom: 20px;

      .clear-filter {
        float: right;
        font-size: 12px;
        font-family: "Campton-Medium";
        padding-top: 2px;
        color: $ui-red;
        text-decoration: none;
        cursor: pointer;
        &:hover {
          color: $ui-text;
        }
      }

      h6 {
        text-transform: uppercase;
        letter-spacing: 0.75px;
        color: #999;
        display: block;
        margin: 0 0 8px;
        font-size: 12px;
        font-family: "Campton-Medium";
        line-height: 1.5em;
      }
    }

    .loader-middle {
      top: 200px;
    }

    .toolbar-banner {
      position: fixed;
      background: #fff;
      padding: 16px;
      border-radius: 36px;
      bottom: calc(#{$layout-padding-size} + 30px);
      left: $sidebar-width-expanded;
      right: 0;
      margin: 0 auto;
      z-index: 1100;
      width: 100%;
      max-width: 496px;
      box-shadow: 0 12px 34px 0 rgba(0, 0, 0, 0.15);

      @media (max-width: 767px) {
        bottom: $layout-padding-size;
        left: $layout-padding-size;
        right: $layout-padding-size;
        max-width: 496px;
      }
      @media (max-width: 590px) {
        max-width: 396px;
      }
      @media (max-width: 490px) {
        max-width: 300px;
      }
      .toolbar-container {
        .toolbar-container-content {
          display: flex;
          align-items: center;
          flex-direction: row;
          .number {
            display: inline-block;
            margin-right: 5px;
            font-family: "Campton-Medium";
            font-size: 12px;
            border-radius: 50%;
            min-width: 20px;
            min-height: 20px;
            padding: 5px;
            background: #e4405ea8;
            color: white;
            text-align: center;
            line-height: 1;
            box-sizing: content-box;
            white-space: nowrap;
          }
          .number:before {
            content: "";
            display: inline-block;
            vertical-align: middle;
            padding-top: 100%;
            height: 0;
          }
          .number span {
            display: inline-block;
            vertical-align: middle;
          }
          p {
            font-family: "Campton-Medium";
            color: $ui-text;
            margin-top: 0px;
            margin-bottom: 0px;
          }
          .clear {
            cursor: pointer;
            &:hover {
              color: $ui-red;
            }
          }
        }
      }
    }

    .selection-toolbar-container {
      position: fixed;
      left: 0;
      right: 0;
      bottom: 10px;
      text-align: center;
      z-index: 1100;

      @media (min-width: 420px) {
        bottom: 24px;
      }

      .selection-toolbar-content {
        display: block;
        margin-bottom: 0;
        margin-left: $sidebar-width-expanded;

        @media (max-width: 767px) {
          margin: 0 20px 10px;
        }

        .selection-toolbar {
          // z-index: 1000;
          // width: 600px;//calc(100% - 237px - 32px - 32px);
          // height: 70px;
          background-color: $ui-card;
          -webkit-box-shadow: $widget-shadow;
          -moz-box-shadow: $widget-shadow;
          box-shadow: $widget-shadow;
          border-radius: 50px;
          // position: fixed;
          // bottom: 50px;
          // margin-left: 270px;
          // margin-right: 270px;
          transition: opacity 0.5s;
          margin: 0 auto;
          display: -webkit-inline-box;
          display: -ms-inline-flexbox;
          display: inline-flex;
          text-align: center;
          -webkit-box-align: center;
          -ms-flex-align: center;
          align-items: center;

          .number {
            display: inline-block;
            margin-right: 5px;
            font-family: "Campton-Bold";
            font-size: 12px;
            border-radius: 50%;
            min-width: 20px;
            min-height: 20px;
            padding: 5px;
            background: #e4405ea8;
            color: white;
            text-align: center;
            line-height: 1;
            box-sizing: content-box;
            white-space: nowrap;
          }
          .number:before {
            content: "";
            display: inline-block;
            vertical-align: middle;
            padding-top: 100%;
            height: 0;
          }
          .number span {
            display: inline-block;
            vertical-align: middle;
          }

          p {
            font-family: "Campton-Medium";
            color: $ui-text;
            margin-top: 0px;
            margin-bottom: 0px;
          }
        }
      }
    }
  }
</style>

<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>
