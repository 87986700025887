var render = function render(){var _vm=this,_c=_vm._self._c;return _c('report-card',{attrs:{"title":"tags_info.title","count":_vm.tags_selected ? _vm.tags_selected.length : 0,"button_icon":_vm.can('update report tags') ? 'fas fa-pencil-alt' : '',"description":"tags_description","description_link":"https://help.not-me.com/report-tagging-system"},on:{"action":_vm.toggleModal}},[_c('div',{staticClass:"tags"},[(!_vm.showTags && _vm.tags_selected.length === 0)?_c('div',{staticStyle:{"text-align":"center","padding":"20px"}},[_c('h5',{staticClass:"title"},[_vm._v(_vm._s(_vm.$t("no_tags")))]),_c('p',{staticClass:"subtitle"},[_vm._v(_vm._s(_vm.$t("no_tags_explanation")))])]):_vm._e(),_c('multiselect',{ref:"multiselect",class:[
        !_vm.showTags && _vm.tags_selected.length === 0 && 'multiselect__hidden',
        !_vm.showTags && 'multiselect__transparent'
      ],attrs:{"options":_vm.tags,"multiple":true,"hide-selected":false,"taggable":true,"placeholder":_vm.$t('search_tags'),"closeOnSelect":false,"track-by":"slug","label":"slug","tagPosition":'bottom'},on:{"open":function($event){_vm.showTags = true},"close":function($event){_vm.showTags = false},"input":_vm.handle_change_command},scopedSlots:_vm._u([{key:"tag",fn:function(props){return [_c('div',{staticClass:"tag-selected-option",style:({
            '--color': props.option.color,
            '--color-bg': props.option.color + '1f'
          })},[_c('div',{staticClass:"tag-selected-option-inner"},[_vm._v(" "+_vm._s(_vm.$t(`tags_info.${props.option.slug}`))+" "),_c('i',{staticClass:"fa fa-times tag-removal",attrs:{"tabindex":"1"},on:{"click":function($event){return _vm.removeTag(props.option.slug)}}})])])]}},{key:"option",fn:function(props){return [_c('div',{staticClass:"option__desc"},[_c('div',{staticClass:"option__left-content"},[_c('div',{staticClass:"option__square",style:({ '--color': props.option.color })}),(props.option.slug)?_c('span',{staticClass:"option__title"},[_vm._v(_vm._s(_vm.$t(`tags_info.${props.option.slug}`)))]):_vm._e()]),(_vm.isSelected(props.option.slug))?_c('i',{staticClass:"fa fa-check"}):_vm._e()])]}}]),model:{value:(_vm.tags_selected),callback:function ($$v) {_vm.tags_selected=$$v},expression:"tags_selected"}},[_c('span',{attrs:{"slot":"noResult"},slot:"noResult"},[_vm._v(" "+_vm._s(_vm.$t("tags_info.not_found"))+" ")])])],1)])
}
var staticRenderFns = []

export { render, staticRenderFns }