<template>
  <div class="button-sorting">
    <el-dropdown trigger="click" @command="updateSortBy">
      <button-arrow
        :title="
          element_title
            ? $t(sort_filters[current_index][element_title])
            : $t(sort_filters[current_index])
        "
      />
      <el-dropdown-menu slot="dropdown" class="filter-popover">
        <el-dropdown-item
          v-for="(item, index) in sort_filters"
          :command="index"
          :key="index"
          class="text"
        >
          {{ element_title ? $t(item[element_title]) : $t(item) }}
        </el-dropdown-item>
      </el-dropdown-menu>
    </el-dropdown>
    <ui-button context="lighter_grey" @click="updateOrderBy">
      <i
        :class="[
          'fas',
          order_desc ? 'fa-sort-amount-down-alt' : 'fa-sort-amount-down'
        ]"
      />
    </ui-button>
  </div>
</template>

<script>
  import ButtonArrow from "@/components/ui-modules/Buttons/ButtonArrow";
  import UiButton from "@/components/ui-modules/Buttons/Button";
  export default {
    name: "ButtonSorting",
    components: {
      ButtonArrow,
      UiButton
    },
    props: {
      sort_filters: {
        type: Array
      },
      current_index: {
        type: Number
      },
      order_desc: {
        type: Boolean,
        default: true
      },
      element_title: {
        type: String
      },
      updateSortBy: {
        type: Function
      },
      updateOrderBy: {
        type: Function
      }
    }
  };
</script>

<style lang="scss">
  .button-sorting {
    display: flex;
    flex-direction: row;
    gap: 10px;
    .notme-button {
      margin: 0px !important;
    }

    .filter-popover {
      border-radius: 13px;
      background-color: $ui-card;
      -webkit-box-shadow: $widget-shadow;
      -moz-box-shadow: $widget-shadow;
      box-shadow: $widget-shadow;

      .title {
        font-family: "Campton-Book";
        font-size: 20px;
        font-weight: 600;
        color: $ui-text;
        border-bottom: 0.5px solid $ui-light_grey;
      }

      .text {
        font-family: "Campton-Book";
        font-size: 15px;
        font-weight: 500;
        color: $ui-text;
      }

      .category {
        margin-top: 10px;
        .title {
          font-family: "Campton-Medium";
          font-size: 16px;
          font-weight: 500;
          color: $ui-text;
          border-bottom: none;
        }
        border-bottom: 0.5px solid $ui-light_grey;
      }

      .el-select .el-input__inner {
        background-color: $ui-card;
        &::placeholder {
          color: $ui-text;
        }
        color: $ui-text;
      }
    }
  }
</style>
