import { EThree } from "@virgilsecurity/e3kit-browser"; //for WebAssembly
import chatApi from "@/api/chatApi";

class Device {
  eThree = null;

  constructor() {}

  /**
   * Initialize a new instance of EThree which tied to specific user.
   */
  async initialize() {
    let getToken = () =>
      chatApi.postChatLogin().then((response) => response.data.token);

    let eThree = null;

    try {
      eThree = await EThree.initialize(getToken);
      this.log("Initializing OK");
    } catch (err) {
      this.log(`Error while initializing: ${err}`);
    }

    this.eThree = eThree;
  }

  /**
   * Return the previously created EThree instance.
   */
  getInstance() {
    return this.eThree;
  }

  log(element) {
    if (process.env.NODE_ENV !== "production") {
      console.log(`** E3Kit ** ${element}`);
    }
  }

  /**
   * Register the user on EThree.
   */
  async register(password) {
    let eThree = this.getInstance();

    this.passwords = await EThree.derivePasswords(password);

    try {
      await eThree.register();
      this.log("User registered");
      await this.backupPrivateKey();
    } catch (err) {
      this.log(`Error while registering: ${err}`);

      if (err.name === "IdentityAlreadyExistsError") {
        this.log("IdentityAlreadyExistsError");
        await this.cleanup();
        await this.rotatePrivateKey();
      }
    }
  }

  /**
   * Check if the user's private key already exists on the device.
   */
  async hasLocalPrivateKey() {
    let eThree = this.getInstance();

    let hasLocalPrivateKey = await eThree.hasLocalPrivateKey();
    this.log("hasLocalPrivateKey");
    return hasLocalPrivateKey;
  }

  /**
   * Backup private key
   */
  async backupPrivateKey() {
    let eThree = this.getInstance();

    try {
      await eThree.backupPrivateKey(this.passwords.backupPassword);
      this.log("Backed up private key");
    } catch (err) {
      this.log(`Error while restoring: ${err}`);
      if (err.name === "CloudEntryExistsError") {
        await this.resetPrivateKeyBackup();
        this.log("Reset private key backup. Trying again...");
        await this.backupPrivateKey();
      }
    }
  }

  /**
   * On each new device or after logout use the EThree.restorePrivateKey(pwd) method with the backup password to download the user's private key from the cloud and decrypt it:
   */
  async restorePrivateKey(password) {
    let eThree = this.getInstance();

    try {
      this.passwords = await EThree.derivePasswords(password);

      await eThree.restorePrivateKey(this.passwords.backupPassword);
      this.log("Private key registered");
    } catch (err) {
      this.log(`Error while restoring: ${err}`);
      switch (err.name) {
        case "WrongKeyknoxPasswordError":
          await this.cleanup();
          await this.rotatePrivateKey();
          break;
        case "PrivateKeyAlreadyExistsError":
          await this.cleanup();
          await this.restorePrivateKey(password);
          break;
        case "PrivateKeyNoBackupError" /** Used for retro-compatibility */:
          await this.cleanup();
          await this.register(password);
          break;
      }
    }
  }

  /**
   * Rotate private key
   */
  async rotatePrivateKey() {
    let eThree = this.getInstance();

    try {
      await eThree.rotatePrivateKey();
      this.log("Rotated private key");
      await this.backupPrivateKey();
    } catch (err) {
      this.log(`Error while rotating: ${err}`);

      if (err.name === "PrivateKeyAlreadyExistsError") {
        await this.cleanup();
        this.log("Cleaned up. Trying again...");
        await this.rotatePrivateKey();
      }
    }
  }

  async cleanup() {
    let eThree = this.getInstance();

    try {
      await eThree.cleanup();

      this.log("Cleaned up (means logout)");
    } catch (err) {
      this.log(`Failed cleaning up: ${err}`);
    }
  }

  async resetPrivateKeyBackup() {
    let eThree = this.getInstance();

    try {
      await eThree.resetPrivateKeyBackup(this.passwords.backupPassword);

      this.log("Reset private key backup");
    } catch (err) {
      this.log(`Failed resetting private key backup: ${err}`);
    }
  }

  async unregister() {
    let eThree = this.getInstance();

    try {
      await eThree.unregister();

      this.log("Unregistered");
    } catch (err) {
      this.log(`Failed unregistering: ${err}`);
    }
  }

  //CHAT

  async findUsers(identifier) {
    let eThree = this.getInstance();

    try {
      this.log("Find users");
      const users = await eThree.findUsers(identifier);
      return users;
    } catch (err) {
      this.log(`Failed finding users: ${err}`);
    }
  }

  async loadGroup(groupId, card) {
    let eThree = this.getInstance();

    try {
      this.log("Load group");
      const group = await eThree.loadGroup(groupId, card);
      return group;
    } catch (err) {
      this.log(`Failed loading group: ${err}`);
    }
  }

  async changePassword(oldPassword, newPassword) {
    let eThree = this.getInstance();

    let oldPasswords = await EThree.derivePasswords(oldPassword);
    this.passwords = await EThree.derivePasswords(newPassword);

    try {
      await eThree.changePassword(
        oldPasswords.backupPassword,
        this.passwords.backupPassword
      );
      this.log("Password updated");
      // await this.backupPrivateKey();
    } catch (err) {
      this.log(`Failed to change password: ${err}`);
    }
  }
}

export default Device;
