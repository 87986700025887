<template>
  <report-card
    title="tags_info.title"
    :count="tags_selected ? tags_selected.length : 0"
    :button_icon="can('update report tags') ? 'fas fa-pencil-alt' : ''"
    v-on:action="toggleModal"
    description="tags_description"
    description_link="https://help.not-me.com/report-tagging-system"
  >
    <div class="tags">
      <div
        style="text-align: center; padding: 20px"
        v-if="!showTags && tags_selected.length === 0"
      >
        <h5 class="title">{{ $t("no_tags") }}</h5>
        <p class="subtitle">{{ $t("no_tags_explanation") }}</p>
      </div>
      <multiselect
        v-model="tags_selected"
        ref="multiselect"
        :class="[
          !showTags && tags_selected.length === 0 && 'multiselect__hidden',
          !showTags && 'multiselect__transparent'
        ]"
        :options="tags"
        :multiple="true"
        :hide-selected="false"
        :taggable="true"
        :placeholder="$t('search_tags')"
        :closeOnSelect="false"
        track-by="slug"
        label="slug"
        :tagPosition="'bottom'"
        @open="showTags = true"
        @close="showTags = false"
        @input="handle_change_command"
      >
        <span slot="noResult">
          {{ $t("tags_info.not_found") }}
        </span>
        <template slot="tag" slot-scope="props">
          <div
            class="tag-selected-option"
            :style="{
              '--color': props.option.color,
              '--color-bg': props.option.color + '1f'
            }"
          >
            <div class="tag-selected-option-inner">
              {{ $t(`tags_info.${props.option.slug}`) }}
              <i
                class="fa fa-times tag-removal"
                tabindex="1"
                @click="removeTag(props.option.slug)"
              />
            </div>
          </div>
        </template>
        <template slot="option" slot-scope="props">
          <div class="option__desc">
            <div class="option__left-content">
              <div
                class="option__square"
                :style="{ '--color': props.option.color }"
              />
              <span class="option__title" v-if="props.option.slug">{{
                $t(`tags_info.${props.option.slug}`)
              }}</span>
            </div>
            <i v-if="isSelected(props.option.slug)" class="fa fa-check" />
          </div>
        </template>
      </multiselect>
    </div>
  </report-card>
</template>

<script>
  import ReportCard from "./ReportCard";
  import Multiselect from "vue-multiselect";
  import permissions_utils from "@/utils/permissions_utils";
  import tag_utils from "@/utils/tag_utils";

  export default {
    name: "ReportTags",
    components: {
      ReportCard,
      Multiselect
    },
    data() {
      return {
        showTags: false,
        tags_selected: [],
        saved_tags: null
      };
    },
    props: {
      tags: {
        type: Array
      },
      report_tags: {
        type: Array
      }
    },
    watch: {
      report_tags(newValue) {
        this.tags_selected = [...this.tags].filter((tag) =>
          newValue.some((report_tag) => report_tag.slug === tag.slug)
        );
      },
      showTags(newValue) {
        if (!newValue) {
          this.evaluateSaveTags();
        }
      }
    },
    methods: {
      can(name) {
        return permissions_utils.can(name);
      },
      closeModal() {
        this.$refs.multiselect.deactivate();
      },
      openModal() {
        this.$refs.multiselect.activate();
      },
      toggleModal() {
        if (this.showTags) {
          this.evaluateSaveTags();
          this.closeModal();
        } else {
          this.openModal();
        }
      },
      saveTags() {
        this.$emit("save-tags", this.tags_selected);
        this.saved_tags = this.tags_selected;
      },
      evaluateSaveTags() {
        if (!this.saved_tags) {
          this.saveTags();
        } else if (this.saved_tags.length !== this.tags_selected.length) {
          this.saveTags();
        } else {
          const areEqual = this.saved_tags.every((val) =>
            this.tags_selected.includes(val)
          );
          if (!areEqual) {
            this.saveTags();
          }
        }
      },
      removeTag(value) {
        if (this.tags_selected) {
          const remainingTags = [
            ...this.tags_selected.filter((tag) => tag.slug !== value)
          ];
          this.tags_selected = remainingTags;
          this.evaluateSaveTags();
        }
      },
      handle_change_command(command) {
        if (command !== "" && command.length > 0) {
          this.tags_selected = command;
        } else {
          this.handle_clear_command();
        }
      },
      handle_clear_command() {
        this.tags_selected = null;
        this.evaluateSaveTags();
      },
      isSelected(slug) {
        if (!this.tags_selected) {
          return false;
        }
        return this.tags_selected.some(
          (tag_selected) => tag_selected.slug === slug
        );
      }
    },
    created() {
      this.tags_selected = [...this.tags].filter((tag) =>
        this.report_tags.some((report_tag) => report_tag.slug === tag.slug)
      );
    }
  };
</script>

<style lang="scss">
  .tags {
    margin-top: -20px;
    margin-right: -10px;
    margin-left: -10px;
    .multiselect.multiselect__hidden {
      display: none;
    }
    .multiselect__content-wrapper {
      ul {
        li {
          margin-top: 0px;
          .option__desc {
            width: 100%;
            display: flex;
            justify-content: space-between;
            align-items: center;
            .option__title {
              display: flex;
              justify-items: center;
              align-items: center;
            }
            i {
              color: $ui-text;
              font-size: 12px;
            }
          }
        }
      }
      border-bottom-right-radius: 8px;
      border-bottom-left-radius: 8px;
      border-color: $ui-light_grey;
      -webkit-box-shadow: $widget-shadow;
      -moz-box-shadow: $widget-shadow;
      box-shadow: $widget-shadow;
      z-index: 20;
    }

    .multiselect__transparent {
      .multiselect__tags {
        border: 1px solid transparent;
      }
    }

    .multiselect__option {
      display: flex;
      flex-direction: row;
      white-space: pre-wrap;
      background-color: $ui-card !important;
      color: $ui-text;
      .option__left-content {
        display: flex;
        justify-content: space-between;
        align-items: center;
        .option__square {
          width: 10px;
          height: 10px;
          border-radius: 2.5px;
          background-color: var(--color);
        }
        .option__title {
          font-size: 15px;
          margin-left: 5px;
          font-weight: 400;
          color: $ui-text;
          font-family: "Campton-Medium" !important;
        }
      }
    }
    .multiselect__select {
      display: none;
    }

    .multiselect__tags {
      margin-top: 5px;
      padding: 5px 10px 5px 10px;
      border: 1px solid $ui-light_grey;
      border-radius: 8px;
      .multiselect__input {
        background-color: inherit;
        margin-top: 10px;
      }
      .multiselect__placeholder {
        color: $ui-text;
      }
      .tag-selected-option {
        display: inline-block;
        padding: 5px 8px 5px 8px;
        margin: 5px 5px 5px 0px;
        border-radius: 5px;
        background-color: var(--color-bg);
        .tag-selected-option-inner {
          color: var(--color);
          font-family: "Campton-Medium";
          display: flex;
          font-size: 13px;
          justify-content: space-between;
          align-items: center;
          .tag-removal {
            padding: 5px 2.5px 5px 5px;
            color: $ui-grey;
            font-size: 12px;
            cursor: pointer;
            z-index: 10;
          }
        }
      }
    }

    .multiselect__option--highlight {
      &:after {
        display: none;
      }
    }

    .multiselect__option--selected {
      &:after {
        display: none;
      }
    }

    .multiselect__tag {
      background: none;
      border: 1px solid $ui-grey;
      color: $ui-text;
      font-family: "Campton-Medium";
      border-radius: 5px;
      margin-right: 0px;
      margin-bottom: 0px;
      white-space: break-spaces;
      text-overflow: inherit;
      .multiselect__tag-icon {
        display: flex;
        justify-content: center;
        align-items: center;
        &:hover {
          background: none;
        }
        &:hover:after {
          font-family: "Campton-Bold";
          color: $ui-red;
        }
        &:after {
          color: $ui-text;
          height: 20px;
          width: 20px;
        }
      }
    }

    .master-selector--title {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: space-between;
      margin-bottom: 4px;
      .master-selector--title-text {
        margin: 0px 10px 0px 0px;
        color: $ui-text;
        font-family: "Campton-Medium";
      }

      .clear-filter {
        float: right;
        font-size: 12px;
        font-family: "Campton-Medium";
        padding-top: 2px;
        color: $ui-red;
        text-decoration: none;
        cursor: pointer;
        &:hover {
          color: $ui-text;
        }
      }

      .info-circle {
        color: $ui-dark_grey;
        align-self: center;
        padding-top: 4px;
        font-size: 16px;
      }
    }

    .el-input__inner {
      border-radius: 6px;
    }
  }
</style>
