<template>
  <div class="conclusion-summary">
    <p v-if="!isSidebar" class="conclusion-title">{{ $t("review_answers") }}</p>

    <section class="section-block">
      <p class="conclusion-subtitle">{{ $t("report_conclusion") }}</p>
      <div
        class="conclusions_selected"
        :class="{ 'wrapped-conclusions': isSidebar }"
      >
        <ui-tag
          v-for="conclusion in sortedConclusionsData"
          :key="conclusion"
          context="dark_blue"
          subtle
          round
          small
        >
          {{ getOutcomeTitle(conclusion, "report") }}
        </ui-tag>
      </div>
      <p class="text-details">{{ conclusionDetailsData }}</p>
    </section>

    <section class="section-block">
      <p class="conclusion-subtitle">{{ $t("accused_outcome") }}</p>
      <div
        class="conclusions_selected"
        :class="{ 'wrapped-conclusions': isSidebar }"
      >
        <ui-tag
          v-for="outcome in sortedAccusedOutcome"
          :key="outcome.id"
          context="dark_blue"
          subtle
          round
          small
        >
          {{ getOutcomeTitle(outcome, "accused") }}
        </ui-tag>
      </div>
      <p class="text-details">{{ accusedOutcomeDetails }}</p>
    </section>
  </div>
</template>

<script>
  import UiTag from "@/components/ui-modules/Tag";

  export default {
    name: "ReportResolutionSummary",
    components: {
      UiTag
    },
    props: {
      conclusionsData: Array,
      conclusionDetailsData: String,
      accusedOutcome: Array,
      accusedOutcomeDetails: String,
      isSidebar: {
        type: Boolean,
        default: false
      }
    },
    computed: {
      sortedConclusionsData() {
        return this.sortOutcomes(this.conclusionsData, "report");
      },
      sortedAccusedOutcome() {
        return this.sortOutcomes(this.accusedOutcome, "accused");
      }
    },
    methods: {
      getOutcomeTitle(outcome, type) {
        if (typeof outcome === "object" && outcome.title) {
          return outcome.title;
        }
        const prefix = `outcomes.${type}.`;
        return this.$t(`${prefix}${outcome}`);
      },
      sortOutcomes(outcomes, type) {
        return [...outcomes].sort((a, b) => {
          const titleA = this.getOutcomeTitle(a, type).toLowerCase();
          const titleB = this.getOutcomeTitle(b, type).toLowerCase();
          return titleA.localeCompare(titleB);
        });
      }
    }
  };
</script>

<style lang="scss">
  .conclusion-summary {
    display: flex;
    flex-direction: column;
    .conclusion-title {
      font-size: 18px;
      text-align: left;
      font-family: "Campton-Bold";
      margin-bottom: 6px;
    }
    .conclusion-subtitle {
      font-size: 14px;
      text-align: left;
      font-family: "Campton-Bold";
      margin-bottom: 6px;
    }

    .section-block {
      margin-bottom: 10px;
    }

    .conclusions_selected {
      display: flex;
      flex-wrap: wrap;
      align-items: center;
      gap: 8px;
      margin-bottom: 6px;

      .notme-tag {
        white-space: nowrap;
        font-size: 13px;
        margin-right: 0px;
      }
    }

    .wrapped-conclusions {
      .notme-tag {
        white-space: normal;
      }
    }

    .text-details {
      margin-top: 10px;
      font-family: "Campton-Medium";
      text-align: left;
      font-size: 14px;
      line-height: 14px;
    }
  }
</style>
