<template>
  <div
    :class="['chart', (data.type === 'anon' || data.isAging) && 'anon-chart']"
  >
    <div class="chart-header">
      <h2>
        {{ data.title }}
        <span v-if="data.subtitle">{{ data.subtitle }} </span>
      </h2>
    </div>
    <Pie
      v-if="data.type === 'pie_chart'"
      :chart-options="chartOptions"
      :chart-data="chartData"
      :css-classes="cssClasses"
    />
    <Doughnut
      v-else-if="data.type === 'donut'"
      :chart-options="chartOptions"
      :chart-data="chartData"
      :css-classes="cssClasses"
    />
    <Bar
      v-else
      :chart-options="chartOptions"
      :chart-data="chartData"
      :css-classes="cssClasses"
    />
  </div>
</template>

<script>
  import { Pie, Bar, Doughnut } from "vue-chartjs/legacy";
  import chart_utils from "@/utils/chart_utils";

  import {
    Chart as ChartJS,
    Title,
    Tooltip,
    Legend,
    ArcElement,
    BarElement,
    LineElement,
    CategoryScale,
    PointElement,
    LinearScale,
    LineController
  } from "chart.js";

  ChartJS.register(
    Title,
    Tooltip,
    Legend,
    ArcElement,
    CategoryScale,
    BarElement,
    LineElement,
    PointElement,
    LinearScale,
    LineController
  );

  export default {
    name: "Chart",
    components: {
      Pie,
      Bar,
      Doughnut
    },
    props: {
      type: {
        type: String
      },
      data: {
        type: Object,
        default: () => {}
      }
    },
    data() {
      return {
        chartData: {},
        chartOptions: {
          responsive: true,
          maintainAspectRatio: true,
          animation: {
            duration: 0 // general animation time
          },
          hover: {
            animationDuration: 0 // duration of animations when hovering an item
          },
          responsiveAnimationDuration: 0, // animation duration after a resize
          plugins: {
            legend: {
              display:
                this.data.type === "pie_chart" ||
                this.data.type === "anon" ||
                this.data.type === "donut",
              position: "bottom",
              labels: {
                boxWidth: 8,
                padding: 8,
                boxHeight: 8,
                usePointStyle: true,
                pointStyle: "circle",
                font: {
                  family: "Campton-Medium"
                }
              }
            },
            tooltip: {
              enabled: true,
              intersect: true,
              mode: "index",
              caretSize: 0,
              backgroundColor: "#fff",
              titleColor: "#4f4f4f",
              titleFont: {
                family: "Campton-Bold"
              },
              bodyFont: {
                family: "Campton-Medium"
              },
              bodyColor: "#4f4f4f",
              borderColor: "#f3f4f4",
              padding: 8,
              borderWidth: 1
            }
          }
        },
        cssClasses: "custom-chart-container"
      };
    },
    computed: {},
    watch: {
      data(newVal, oldVal) {
        this.initChart(newVal);
      }
    },
    async created() {
      this.initChart(this.data);
    },
    methods: {
      initChart(report) {
        let values = [];
        let values_overlap = [];
        let labels = [];
        const isPieChart =
          report.type === "pie_chart" || report.type === "donut";
        const isAnonymousReport = report.type === "anon";
        report.data.map((item) => {
          values.push(item[0]);
          labels.push(item[1]);
          if (isAnonymousReport) {
            values_overlap.push(item[2]);
          }
        });
        const datasets = [
          {
            backgroundColor: chart_utils.getChartColors(report),
            data: values
          }
        ];
        if (isAnonymousReport) {
          datasets[0].backgroundColor = ["#1f5688"];
          datasets[0].label = this.$t("utils.report_status.submitted");
          datasets.push({
            backgroundColor: ["#ffe255"],
            borderColor: "#ffe255",
            data: values_overlap,
            label: this.$t("anonymous"),
            type: "line",
            tension: 0.4,
            pointRadius: 2
          });
          [datasets[0], datasets[1]] = [datasets[1], datasets[0]];
        }
        this.chartData = {
          labels: labels,
          datasets
        };
        this.chartOptions = {
          ...this.chartOptions,
          animation: {
            duration: 0
          },
          cutout: report.type === "donut" ? "70%" : 0,
          hover: {
            animationDuration: 0 // duration of animations when hovering an item
          },
          responsiveAnimationDuration: 0, // animation duration after a resize
          scales: {
            x: {
              ticks: {
                display:
                  isPieChart ||
                  (values.length > 3 && !isAnonymousReport) ||
                  (isAnonymousReport && values.length > 5)
                    ? false
                    : true,
                font: {
                  family: "Campton-Medium"
                }
              },
              grid: {
                display: isAnonymousReport,
                drawBorder: false,
                drawOnChartArea: isAnonymousReport,
                drawTicks: false
              }
            },
            y: {
              ticks: {
                display: false
              },
              grid: {
                display: !isPieChart,
                drawBorder: false,
                drawOnChartArea: !isPieChart,
                drawTicks: !isPieChart
              }
            }
          }
        };
      }
    }
  };
</script>

<style lang="scss" scoped>
  .chart {
    padding: 12px;
    margin-bottom: 24px;
    border-radius: 12px;
    border: 1px solid $ui-light_grey;
    flex-basis: calc(100%);
    @media (min-width: 576px) and (max-width: 991px) {
      max-width: 48%;
    }
    @media (min-width: 991px) {
      max-width: 32%;
    }
    .chart-header {
      span {
        font-size: 15px;
        color: $ui-subtitle;
        text-transform: lowercase;
        display: inline-block;
        font-family: "Campton-Medium";
      }
    }
    h2 {
      font-size: 16px;
      margin-bottom: 0px;
      text-align: left;
      overflow: hidden;
      text-overflow: ellipsis;
      display: -webkit-box;
      -webkit-line-clamp: 2;
      line-clamp: 2;
      -webkit-box-orient: vertical;
      text-transform: capitalize;
      height: 38px;
    }
    .custom-chart-container {
      width: 99%;
    }
  }
  .chart.anon-chart {
    max-width: 100%;
  }
</style>
