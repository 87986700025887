<template>
  <nav class="navbar navbar-expand-lg navbar-light navbar-notme sticky-top">
    <a href="#" class="navbar-hamburger-left" @click.prevent="toggleMenu">
      <i class="fa fa-bars fa-lg" />
    </a>
    <router-link
      :to="{ name: 'overview' }"
      class="navbar-brand navbar-logo mx-auto"
    >
      <img src="@/assets/logo-notme.png" height="25" alt="" />
    </router-link>
  </nav>
</template>

<script>
  export default {
    name: "Navbar",
    methods: {
      toggleMenu() {
        window.bus.$emit("menu/toggle");
      }
    }
  };
</script>

<style lang="scss">
  .navbar {
    font-size: 16px;
    height: 66px;
    background-color: $ui-card;
    border-bottom: 1px solid transparent;
    margin-left: -$layout-padding-size;
    margin-right: -$layout-padding-size;

    @media (max-width: 768px) {
      margin-left: -$layout-padding-size-mobile;
      margin-right: -$layout-padding-size-mobile;
    }
    margin-bottom: 20px;
    -webkit-box-shadow: $widget-shadow;
    -moz-box-shadow: $widget-shadow;
    box-shadow: $widget-shadow;

    .navbar-hamburger-left {
      position: absolute;
      left: 1rem;
      color: $ui-text;
    }
  }
</style>
