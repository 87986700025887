<template>
  <div class="timeline-event">
    <div class="timeline-icon-wrapper">
      <div class="timeline-icon">
        <i class="fas fa-user" />
      </div>
    </div>
    <div class="timeline-content-container">
      <div class="content">
        <div class="title-container">
          <span class="inline-header">
            {{ user ? user.firstname + " " + user.lastname : $t("anonymous") }}
          </span>
          <div
            v-html="this.getAssigneeTranslation()"
            class="translation inline-header"
          />
          <div class="date">
            {{ item.updated_on.format("LLL") }}
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import AvatarName from "@/components/ui-modules/Avatar/Base";

  export default {
    name: "TimelineAssignee",
    components: {
      AvatarName
    },
    props: {
      user: {
        type: Object
      },
      users: {
        type: Object
      },
      item: {
        type: Object
      }
    },
    data() {
      return {};
    },
    methods: {
      getAssigneeTranslation() {
        return this.$t(
          this.item.deletion ? "unassigned_report_from" : "assigned_report_to",
          {
            assignee: this.users[this.item.assignee_id]
              ? this.users[this.item.assignee_id].firstname +
                " " +
                this.users[this.item.assignee_id].lastname
              : this.$t("anonymous")
          }
        );
      }
    }
  };
</script>

<style lang="scss"></style>
