import posthog from "posthog-js";
import Vue from "vue";

export default {
  install(app) {
    app.config.$posthog = posthog.init(process.env.VUE_APP_POSTHOG_TOKEN, {
      api_host: process.env.VUE_APP_POSTHOG_HOST,
      capture_pageview: false
    });
    Vue.prototype.$posthog = posthog;
  }
};
