var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('div',{staticClass:"message-box",attrs:{"hidden":!_vm.isShow}},[_c('div',{staticClass:"message-box-content"},[_c('div',{staticClass:"chat-header"},[_c('div',{staticClass:"header-title"},[_c('h5',[_vm._v(_vm._s(_vm.$t("chat")))]),_c('media',{attrs:{"query":{ maxWidth: 576 }}},[_c('a',{staticClass:"right-btn",on:{"click":function($event){return _vm.$emit('show')}}},[_c('i',{staticClass:"fas fa-times"})])])],1),_c('p',[_vm._v(_vm._s(_vm.$t("chat_description")))])]),(_vm.isChatError)?_c('div',{staticClass:"content welcome-admin"},[_c('p',[_vm._v(_vm._s(_vm.$t("chat_error_message")))])]):(!_vm.isUserAssigned || _vm.chat_disconnected)?_c('div',{directives:[{name:"loading",rawName:"v-loading.lock",value:(_vm.isLoading),expression:"isLoading",modifiers:{"lock":true}}],staticClass:"content welcome-admin"},[_c('p',[_vm._v(" "+_vm._s(_vm.$t( _vm.chat_disconnected ? "chat_disconnected_description" : "chat_not_assigned_description" ))+" ")]),_c('p',{domProps:{"innerHTML":_vm._s(
            _vm.$t(
              _vm.chat_disconnected
                ? 'chat_disconnected_action'
                : 'chat_not_assigned_action'
            )
          )}}),_c('ui-button',{attrs:{"context":"green","disabled":!_vm.can('assign company report') && !_vm.can('assign public report'),"full":""},on:{"click":function($event){_vm.chat_disconnected ? _vm.reconnectAction() : _vm.assignMeAction()}}},[_vm._v(" "+_vm._s(_vm.$t(_vm.chat_disconnected ? "reconnect" : "assign_me"))+" ")])],1):_c('div',{directives:[{name:"loading",rawName:"v-loading.lock",value:(_vm.isLoading),expression:"isLoading",modifiers:{"lock":true}}],staticClass:"content",attrs:{"element-loading-text":_vm.$t('loading_assignment')}},[_c('div',{directives:[{name:"loading",rawName:"v-loading.lock",value:(!_vm.isLoading && _vm.loadMessages),expression:"!isLoading && loadMessages",modifiers:{"lock":true}}]}),(!_vm.isLoading)?_c('div',[_c('div',{staticClass:"box",staticStyle:{"bottom":"61px"}},[_c('div',{directives:[{name:"chat-scroll",rawName:"v-chat-scroll",value:({
                always: false,
                handlePrepend: true,
                smooth: true,
                notSmoothOnInit: true
              }),expression:"{\n                always: false,\n                handlePrepend: true,\n                smooth: true,\n                notSmoothOnInit: true\n              }"}],ref:"chatContainer",attrs:{"id":"chat-container"},on:{"v-chat-scroll-top-reached":_vm.loadOlderMessages}},[_c('ul',{staticStyle:{"list-style":"none","-webkit-padding-start":"0px"}},_vm._l((this.messages.slice().reverse()),function(item,index){return _c('report-chat-bubble',{key:index,attrs:{"isSubscriber":item.subscriber,"isInfo":item.subscriber === undefined,"content":item.content,"date":item.created_at.seconds}})}),1)])]),_c('div',{staticClass:"footer"},[_c('div',{staticClass:"input-group"},[_c('report-chat-text-area',{attrs:{"placeholder":_vm.$t('type_a_message'),"min-height":61,"max-height":133},on:{"enterAction":_vm.sendMessage,"shiftEnterAction":_vm.newline},model:{value:(_vm.messageText),callback:function ($$v) {_vm.messageText=$$v},expression:"messageText"}}),(_vm.messageText)?_c('div',{staticClass:"input-group-append"},[_c('span',{staticClass:"input-group-text send_btn",on:{"click":_vm.sendMessage}},[_vm._v(_vm._s(_vm.$t("send")))])]):_vm._e()],1)])]):_vm._e()])])]),_c('transition',{attrs:{"name":"fade"}},[(_vm.can('chat') && _vm.chat_token && _vm.isReporterUser)?_c('ui-button',{staticClass:"btn-message",attrs:{"context":"green","hoverShadow":"","circle":""},on:{"click":function($event){return _vm.$emit('show')}}},[(_vm.isShow)?_c('i',{staticClass:"fas fa-times"}):_c('i',{staticClass:"fas fa-comment"})]):_vm._e()],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }