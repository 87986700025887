<template>
  <div>
    <div :hidden="!isShow" class="message-box">
      <div class="message-box-content">
        <div class="chat-header">
          <div class="header-title">
            <h5>{{ $t("chat") }}</h5>
            <media :query="{ maxWidth: 576 }">
              <a class="right-btn" @click="$emit('show')">
                <i class="fas fa-times"></i>
              </a>
            </media>
          </div>
          <p>{{ $t("chat_description") }}</p>
        </div>

        <div v-if="isChatError" class="content welcome-admin">
          <p>{{ $t("chat_error_message") }}</p>
        </div>

        <div
          v-else-if="!isUserAssigned || chat_disconnected"
          v-loading.lock="isLoading"
          class="content welcome-admin"
        >
          <p>
            {{
              $t(
                chat_disconnected
                  ? "chat_disconnected_description"
                  : "chat_not_assigned_description"
              )
            }}
          </p>
          <p
            v-html="
              $t(
                chat_disconnected
                  ? 'chat_disconnected_action'
                  : 'chat_not_assigned_action'
              )
            "
          ></p>
          <ui-button
            context="green"
            :disabled="
              !can('assign company report') && !can('assign public report')
            "
            full
            @click="chat_disconnected ? reconnectAction() : assignMeAction()"
          >
            {{ $t(chat_disconnected ? "reconnect" : "assign_me") }}
          </ui-button>
        </div>
        <div
          v-else
          class="content"
          v-loading.lock="isLoading"
          :element-loading-text="$t('loading_assignment')"
        >
          <div v-loading.lock="!isLoading && loadMessages" />
          <div v-if="!isLoading">
            <div class="box" style="bottom: 61px">
              <div
                v-chat-scroll="{
                  always: false,
                  handlePrepend: true,
                  smooth: true,
                  notSmoothOnInit: true
                }"
                id="chat-container"
                ref="chatContainer"
                @v-chat-scroll-top-reached="loadOlderMessages"
              >
                <ul style="list-style: none; -webkit-padding-start: 0px">
                  <report-chat-bubble
                    v-for="(item, index) in this.messages.slice().reverse()"
                    :key="index"
                    :isSubscriber="item.subscriber"
                    :isInfo="item.subscriber === undefined"
                    :content="item.content"
                    :date="item.created_at.seconds"
                  />
                </ul>
              </div>
            </div>
            <div class="footer">
              <div class="input-group">
                <report-chat-text-area
                  v-model="messageText"
                  :placeholder="$t('type_a_message')"
                  :min-height="61"
                  :max-height="133"
                  v-on:enterAction="sendMessage"
                  v-on:shiftEnterAction="newline"
                ></report-chat-text-area>
                <div v-if="messageText" class="input-group-append">
                  <span
                    class="input-group-text send_btn"
                    @click="sendMessage"
                    >{{ $t("send") }}</span
                  >
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <transition name="fade">
      <ui-button
        class="btn-message"
        context="green"
        hoverShadow
        circle
        v-if="can('chat') && chat_token && isReporterUser"
        @click="$emit('show')"
      >
        <i v-if="isShow" class="fas fa-times"></i>
        <i v-else class="fas fa-comment"></i>
      </ui-button>
    </transition>
  </div>
</template>

<script>
  import Media from "vue-media";
  import ReportChatBubble from "./ReportChatBubble";
  import ReportChatTextArea from "./ReportChatTextArea";
  import UiButton from "@/components/ui-modules/Buttons/Button";

  import { getAuth, signInWithCustomToken } from "firebase/auth";
  import {
    getFirestore,
    collection,
    Timestamp,
    query,
    where,
    orderBy,
    getDocs,
    onSnapshot,
    limit,
    addDoc,
    startAfter
  } from "firebase/firestore";
  import jwt_decode from "jwt-decode";
  import moment from "moment";

  import permissions_utils from "@/utils/permissions_utils";
  import dashboardApi from "@/api/dashboardApi";
  import api from "@/api/api";
  import * as Sentry from "@sentry/vue";

  export default {
    name: "ReportChat",
    components: {
      Media,
      ReportChatBubble,
      ReportChatTextArea,
      UiButton
    },
    data() {
      return {
        messages: [],
        messageText: "",
        chat_token: null,
        loadMessages: true,

        company_card: null,
        participants: [],
        group: null,
        listeners: [],
        db: null,

        e2e_since: new Date("2020-08-13T20:00:00Z"),
        current_user_id: JSON.parse(this.$store.state.auth.user).id,

        startTime: null,
        loadingEarlier: false,

        messagesCollection: "messages-eu",
        chat_disconnected: false
      };
    },
    props: {
      report_id: {
        type: Number
      },
      isShow: {
        type: Boolean,
        default: true
      },
      isUserAssigned: {
        type: Boolean,
        default: true
      },
      e2e_enabled: {
        type: Boolean,
        default: false
      },
      canLoadMessages: {
        type: Boolean
      },
      isReporterUser: {
        type: Boolean
      },
      isChatError: {
        type: Boolean
      },
      isLoading: {
        type: Boolean
      }
    },
    mounted() {
      this.addChatScrollEventListener();
    },
    watch: {
      async isUserAssigned(newVal, oldVal) {
        if (this.isE2EEnabled()) {
          if (newVal === true) {
            this.isLoading = true;
            this.loadMessages = true;
            const company_id = `company_${
              JSON.parse(this.$store.state.auth.user).company.id
            }`;
            const group_id = `report-chat_${this.report_id}`;

            let self = this;

            var e2e_limit = 0;

            var waitTillInside = setInterval(async () => {
              try {
                const company_card =
                  await self.$store.state.device.findUsers(company_id);
                let _participants = await self.getParticipants(
                  group_id,
                  company_id,
                  company_card
                );
                let user_id = `user_${self.current_user_id}`;
                if (_participants.find((x) => x === user_id) !== undefined) {
                  await self.initMessaging();
                  self.loadMessages = false;
                  this.isLoading = false;
                  clearInterval(waitTillInside);
                }
              } catch (err) {
                if (++e2e_limit == 2) {
                  clearInterval(waitTillInside);
                }
              }
            }, 5000);
          }
        }
      },
      async canLoadMessages(newVal, oldVal) {
        if (newVal === true) {
          await this.initMessaging();
        }
      }
    },
    async created() {
      if (this.canLoadMessages) {
        await this.initMessaging();
      }
    },
    methods: {
      async initMessaging() {
        try {
          const response = await dashboardApi.getMessageToken(this.report_id);

          if (response.status === 200) {
            this.chat_token = response.data.token;
            let chat_results = jwt_decode(response.data.token);

            if (this.isE2EEnabled() && this.isUserAssigned) {
              await this.initEndToEnd();
            }
            const auth = getAuth();
            const signInResponse = signInWithCustomToken(auth, this.chat_token);

            this.db = getFirestore();
            await this.getMessages();

            this.chat_disconnected = false;
            this.isChatError = false;
          }
        } catch (error) {
          this.chat_disconnected = true;
          this.isChatError = true;
          Sentry.captureException(error);
        }
      },
      async initEndToEnd() {
        const company_id = `company_${
          JSON.parse(this.$store.state.auth.user).company.id
        }`;
        const group_id = `report-chat_${this.report_id}`;

        const company_card =
          await this.$store.state.device.findUsers(company_id);
        let _participants = await this.getParticipants(
          group_id,
          company_id,
          company_card
        );

        const participants = await Promise.all(
          _participants.map(async (participant) => {
            const card = await this.$store.state.device.findUsers(participant);
            return card;
          })
        );

        const group = await this.$store.state.device.loadGroup(
          group_id,
          company_card
        );

        this.company_card = company_card;
        this.participants = participants;
        this.group = group;
      },
      async getMessages() {
        const q = query(
          collection(this.db, this.messagesCollection),
          orderBy("created_at", "desc"),
          where("report_id", "==", this.report_id),
          limit(10)
        );

        try {
          const querySnapshots = await getDocs(q);

          if (querySnapshots) {
            this.startTime =
              querySnapshots.docs[querySnapshots.docs.length - 1];

            const listener = onSnapshot(
              q,
              async (snapshot) => {
                var tempCollection = [];
                this.messages = [];
                let before_e2e = true;

                snapshot.docChanges().forEach((change) => {
                  if (change.type === "removed") {
                    this.startTime =
                      querySnapshots.docs[querySnapshots.docs.length - 1];
                  }
                });

                snapshot.forEach((doc) => {
                  tempCollection.push(doc.data());
                });

                if (this.isE2EEnabled()) {
                  let decyptionPromise = tempCollection.map(
                    async (message_content) => {
                      const decrypted =
                        await this.decryptMessage(message_content);
                      return decrypted;
                    }
                  );
                  const decyptedMessages = await Promise.all(
                    decyptionPromise.map((p) => p.catch((e) => e))
                  );
                  this.messages = decyptedMessages.filter(
                    (result) => !(result instanceof Error)
                  );
                } else {
                  this.messages = tempCollection;
                  this.$nextTick(() => {
                    this.$refs.chatContainer?.scrollTo(
                      0,
                      this.$refs.chatContainer.scrollHeight
                    );
                  });
                }
                this.loadMessages = false;
              },
              (error) => {
                this.chat_disconnected = true;
              }
            );
            this.listeners.push(listener);
          }
        } catch (err) {
          console.log(err);
        }
      },
      async getMoreMessages() {
        if (this.startTime === undefined) {
          this.loadMessages = false;
          return;
        }

        const q = query(
          collection(this.db, this.messagesCollection),
          orderBy("created_at", "desc"),
          startAfter(this.startTime),
          where("report_id", "==", this.report_id),
          limit(10)
        );

        // We get the current height to avoid scrolling to the top
        const currentScrollHeight = this.$refs.chatContainer.scrollHeight;

        try {
          const querySnapshots = await getDocs(q);

          if (querySnapshots) {
            this.startTime =
              querySnapshots.docs[querySnapshots.docs.length - 1];

            const listener = onSnapshot(
              q,
              async (snapshot) => {
                var tempCollection = [];
                let before_e2e = true;

                snapshot.forEach((doc) => {
                  tempCollection.push(doc.data());
                });

                if (this.isE2EEnabled()) {
                  let decyptionPromise = tempCollection.map(
                    async (message_content) => {
                      const decrypted =
                        await this.decryptMessage(message_content);
                      return decrypted;
                    }
                  );
                  const decyptedMessages = await Promise.all(
                    decyptionPromise.map((p) => p.catch((e) => e))
                  );
                  let new_messages = decyptedMessages.filter(
                    (result) => !(result instanceof Error)
                  );
                  this.messages = this.messages.concat(new_messages);
                } else {
                  this.messages = this.messages.concat(tempCollection);
                }

                // Simulate loading scroll
                this.$nextTick(() => {
                  const container = this.$refs.chatContainer;
                  if (container) {
                    const newScrollHeight = container.scrollHeight;

                    // Then we scroll to the difference between the new height and the old min-height
                    // to simulate the same scroll position.
                    // The offset helps to bring a smooth scroll experience by scrolling up a little bit
                    // To show new messages
                    container.scrollTo({
                      top: newScrollHeight - currentScrollHeight - 150
                    });
                  }
                });

                this.loadMessages = false;
              },
              (error) => {
                this.chat_disconnected = true;
              }
            );
            this.listeners.push(listener);
          }
        } catch (err) {
          console.log(err);
        }
      },
      async sendMessage() {
        if (this.messageText.length === 0) {
          return;
        }
        let message_to_send = this.messageText;
        this.messageText = "";

        const auth = getAuth();

        try {
          const signInResponse = await signInWithCustomToken(
            auth,
            this.chat_token
          );
          const created = new Date();

          /** BEGIN ENCRYPT */

          let content = message_to_send;

          let messageData = {
            report_id: this.report_id,
            created_at: created,
            user_id: `user_${this.current_user_id}`,
            subscriber: true
          };
          if (this.isE2EEnabled()) {
            const encrypted = await this.group.encrypt(content);
            let message = {
              content: encrypted,
              ...messageData
            };
            console.log(message);
            this.startTime = null;
            await addDoc(collection(this.db, this.messagesCollection), message);
          } else {
            let message = {
              content: content,
              ...messageData
            };
            this.startTime = null;
            await addDoc(collection(this.db, this.messagesCollection), message);
          }
        } catch (err) {
          console.log(err);
        }
      },
      loadOlderMessages() {
        if (!this.loadMessages) {
          this.loadMessages = true;
          this.getMoreMessages();
        }
      },
      async getParticipants(group_id, company_id, company_card) {
        let participants = await this.$store.state.device.loadGroup(
          group_id,
          company_card
        );

        return participants.participants.filter(
          (element) => element !== company_id
        );
      },
      detachListeners() {
        this.listeners = [];
      },
      createdToDate(item) {
        return moment.unix(item.created_at.seconds).toDate();
      },
      newline() {
        this.messageText += "\\n";
      },
      e2e_message() {
        return {
          content:
            "🔒 Messages you send to this chat are now secured with end-to-end encryption.",
          created_at: Timestamp.fromDate(this.e2e_since)
        };
      },
      can(name) {
        return permissions_utils.can(name);
      },
      async decryptMessage(message_content) {
        let current_message = message_content;

        let user_id = current_message.user_id || "user_247";

        let user_card = this.participants.find(
          (card) => card.identity === user_id
        );

        try {
          let decrypted = await this.group.decrypt(
            current_message.content,
            user_card
          );
          return { ...message_content, content: decrypted };
        } catch (err) {
          return Promise.reject(new Error(err));
        }
      },
      assignMeAction() {
        this.$emit("assign-current-user");
        this.$nextTick(() => {
          this.addChatScrollEventListener();
        });
      },
      reconnectAction() {
        this.initMessaging();
      },
      addChatScrollEventListener() {
        if (this.isChatError) return;

        const el = this.$refs.chatContainer;
        if (el !== undefined) {
          el.addEventListener("scroll", (e) => {
            if (el.scrollTop === 0) {
              this.loadOlderMessages();
            }
          });
        }
      },
      isE2EEnabled() {
        return this.$store.getters.isE2Eenabled() && this.e2e_enabled;
      }
    }
  };
</script>

<style lang="scss">
  @import "~bootstrap/scss/mixins/_breakpoints";
  @import "~bootstrap/scss/_functions";
  @import "~bootstrap/scss/_variables";

  .fade-enter-active,
  .fade-leave-active {
    transition: opacity 0.5s;
  }
  .fade-enter,
  .fade-leave-to {
    opacity: 0;
  }

  .btn-message {
    height: 60px;
    width: 60px;
    position: fixed;
    z-index: 3;
    right: 0;
    bottom: 0;
    line-height: 20px;
    margin-right: $layout-padding-size;
    margin-bottom: $layout-padding-size;
    font-size: 20px;
    @media (max-width: 768px) {
      margin-right: $layout-padding-size-mobile;
      margin-bottom: $layout-padding-size-mobile;
    }
  }

  .message-box {
    width: 376px;
    border-radius: $widget-radius;
    bottom: 100px;
    padding: 0px;
    z-index: 214;
    min-height: 250px;
    max-height: 604px;
    height: calc(100% - 130px);
    position: fixed;
    right: $layout-padding-size;
    background-color: white;
    box-shadow: $widget-shadow;
    opacity: 1;
    overflow: hidden;
    display: block;
    @media (max-width: 767px) {
      height: calc(100% - 190px);
      right: $layout-padding-size-mobile;
    }

    @media (max-width: 576px) {
      min-height: 250px;
      opacity: 1;
      z-index: 200001;
      width: 100%;
      height: 100%;
      max-height: none;
      top: 0px;
      left: 0px;
      right: 0px;
      bottom: 0px;
      position: fixed;
      overflow: hidden;
      border-radius: 0px;
    }

    .message-box-content {
      display: flex;
      flex-direction: column;
      -webkit-box-pack: start;
      justify-content: flex-start;
      position: absolute;
      top: 0px;
      bottom: 0px;
      left: 0px;
      right: 0px;
      overflow: hidden;

      .chat-header {
        position: relative;
        min-height: 75px;
        transition: height 160ms ease-out 0s;
        padding: 10px;
        color: $ui-text;
        .header-title {
          display: flex;
          flex-direction: row;
          justify-content: space-between;
          border-bottom: 1px solid $ui-light_grey;
          .right-btn {
            height: 32px;
            width: 32px;
            margin-left: auto;
            margin-right: 0;
            padding: 5px;
            align-self: center;
            text-align: center;
            border-radius: 5px;
            vertical-align: middle;
            &:hover {
              background-color: $ui-light_grey;
              color: $ui-red;
            }
          }
        }
        h5 {
          font-family: "Campton-Medium";
          font-size: 28px;
        }
        p {
          border-radius: $widget-radius;
          padding: 5px;
          text-align: center;
          margin: 10px 0px 10px 0px;
          font-size: 14px;
          background: #eee;
          color: #000;
        }
      }

      .content {
        position: relative;
        background-color: white;
        flex: 1 1 0%;

        .el-loading-mask {
          .circular {
            margin-top: 3px;
            height: 14px;
            width: 14px;
          }
        }

        .box {
          display: flex;
          flex-direction: column;
          position: absolute;
          top: 0px;
          left: 0px;
          right: 0px;
          bottom: 0px;
          overflow-x: hidden;
          outline-offset: -5px;

          #chat-container {
            overflow-y: auto;
            padding: 10px;
          }
        }
        .footer {
          position: absolute;
          bottom: 0px;
          left: 0px;
          right: 0px;
          min-height: 56px;
          max-height: 200px;
          border-top: 1px solid rgb(230, 230, 230);
          background-color: #fff;
          padding: 0px;

          .type_msg {
            border: 0 !important;
            color: black !important;
            height: 60px !important;
            overflow-y: auto;
            resize: none;
            border-radius: 0 0 0 0;
          }
          .type_msg:focus {
            box-shadow: none !important;
            outline: 0px !important;
          }
          .send_btn {
            border-radius: 0 0 0 0 !important;
            background: none;
            border: 0 !important;
            color: $ui-red;
            cursor: pointer;
          }
          .input-group-append {
            border-left: 1px solid #e6e6e6;
            &:after {
              border-left: none;
            }
          }
        }
      }

      .welcome-admin {
        margin: 32px 24px;
        text-align: center;
        font-weight: 500;
        font-size: 14px;
        color: $ui-text;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
      }
    }
  }
</style>
