<template>
  <div class="graphs-selector">
    <div v-if="categories.length > 0" class="categories-container">
      <div
        v-for="category of categories"
        :key="category"
        class="category-container"
      >
        <div class="header-container">
          <h5>{{ $t(category.toLowerCase()) }}</h5>
          <a
            class="select-all"
            @click="
              select_all(mappedGraphs[category], allItemsSelected(category))
            "
          >
            {{ $t(allItemsSelected(category) ? "deselect_all" : "select_all") }}
          </a>
        </div>
        <div
          v-for="graph of mappedGraphs[category]"
          :key="graph.id"
          class="graph-item"
        >
          <el-checkbox
            :value="isActiveItem(graph.graph_id)"
            @change="toggleActiveItem(graph.graph_id)"
          >
            <i :class="['fas', icons[graph.graph_type]]" />
            <p>{{ graph.graph_title }}</p>
          </el-checkbox>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  export default {
    name: "GraphsSelector",
    components: {},
    props: {
      graphs: {
        type: Array
      },
      activeGraphs: {
        type: Array
      },
      toggleActiveItem: {
        type: Function
      }
    },
    data() {
      return {
        mappedGraphs: {},
        categories: [],
        icons: {
          pie_chart: "fa-chart-pie",
          vertical_bar_graph: "fa-chart-area",
          horizontal_bar_graph: "fa-chart-bar"
        }
      };
    },
    created() {
      this.graphs.forEach((graph) => {
        this.mappedGraphs[graph.category]
          ? this.mappedGraphs[graph.category].push(graph)
          : (this.mappedGraphs[graph.category] = [graph]);
      });
      if (Object.keys(this.mappedGraphs).length > 0) {
        this.categories = Object.keys(this.mappedGraphs);
      }
    },
    methods: {
      isActiveItem(item) {
        return this.activeGraphs.includes(item);
      },
      select_all(items, allItemsSelected) {
        items.forEach((i) => {
          if (this.isActiveItem(i.graph_id) === allItemsSelected) {
            this.toggleActiveItem(i.graph_id);
          }
        });
      },
      allItemsSelected(category) {
        let allItemsActive = true;
        this.mappedGraphs[category].forEach((graph) => {
          if (!this.isActiveItem(graph.graph_id)) {
            allItemsActive = false;
          }
        });
        return allItemsActive;
      }
    }
  };
</script>

<style lang="scss">
  .graphs-selector {
    width: 100%;
    .categories-container {
      background-color: $ui-background;
      padding: 20px;
      width: 100%;
      border-radius: 8px;
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      justify-content: center;
      .category-container {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        justify-content: center;
        width: 100%;
        margin-bottom: 8px;
        .header-container {
          display: flex;
          flex-direction: row;
          justify-content: space-between;
          align-items: center;
          width: 100%;
          margin-bottom: 10px;
          h5 {
            font-size: 18px;
            text-transform: capitalize;
            margin-bottom: 0px;
          }
          a {
            color: $ui-green;
            font-size: 12px;
            font-weight: 700;
            text-decoration: none;
            cursor: pointer;
          }
        }
        .graph-item {
          background-color: $ui-background;
          width: 100%;
          display: flex;
          align-items: center;
          margin-bottom: 10px;
          border-radius: 5px;
          i {
            margin-right: 10px;
            margin-left: 10px;
          }
          .el-checkbox {
            margin-bottom: 0px;
            width: 100%;
            display: flex;
            padding: 10px;
            .el-checkbox__label {
              display: flex;
              white-space: normal;
              p {
                text-align: left;
                margin: 0px;
              }
            }
          }
          .el-checkbox.is-checked {
            i {
              color: $ui-blue;
            }
          }
        }
      }
    }
  }
</style>
