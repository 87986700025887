<template>
  <div class="dashboard-cell reporter-cell container-fluid">
    <div class="reporter-container row">
      <div class="name-container my-auto col-lg-3 col-6">
        <div>
          <avatar-initial
            :id="reporter.id"
            :name="reporter.fullname"
            color="12, 32, 61"
          />
        </div>
        <p class="reporter-title" style="font-weight: 700">
          {{ reporter.fullname }}
        </p>
      </div>
      <div class="col-lg-3 col-6 my-auto">
        <div v-if="getUserStatus()" class="company-container">
          <company-logo
            v-if="
              $store.state.auth.role.title.localeCompare('super admin') === 0 ||
              ($store.state.subcompanies &&
                $store.state.subcompanies.length > 0)
            "
            style="display: inline-block; margin-left: 5px"
            :company="company"
            :size="30"
          />
          <p class="company-name" style="font-weight: 700">
            {{ company.name }}
          </p>
        </div>
        <div v-else class="inactive-banner">
          <information-popover :description="$t('inactive_description')">
            {{ $t("inactive") }}
          </information-popover>
        </div>
      </div>
      <div class="reporter-job col-lg-3 col-6 my-auto">
        <p>{{ reporter.job_title }}</p>
      </div>
      <div class="col-lg-3 col-6 my-auto right-content">
        <router-link :to="{ path: '/reports?user_id=' + reporter.id }">
          <ui-tag context="dark_blue" subtle circle hoverShadow small>{{
            getNumberReport(reporter.nb_reports)
          }}</ui-tag>
        </router-link>
      </div>
    </div>
  </div>
</template>

<script>
  import UiTag from "@/components/ui-modules/Tag";
  import AvatarInitial from "@/components/AvatarInitial";
  import CompanyLogo from "@/components/CompanyLogo";
  import InformationPopover from "@/components/InformationPopover";

  export default {
    name: "ReporterCell",
    components: {
      UiTag,
      AvatarInitial,
      CompanyLogo,
      InformationPopover
    },
    props: {
      companies: {
        type: Array,
        default: []
      },
      activeCompanies: {
        type: Array,
        default: []
      },
      reporter: {
        type: Object,
        default: {}
      }
    },
    data() {
      return {
        company: this.getReporterCompany()
      };
    },
    methods: {
      getNumberReport(nb_report) {
        return (
          nb_report +
          " " +
          (nb_report !== 1 ? this.$t("reports_noun") : this.$t("report_noun"))
        );
      },
      getReporterCompany() {
        let companyLogo = this.companies.filter(
          (comp) => comp.id === this.reporter.company_id
        );
        if (companyLogo.length < 1) {
          const user = this.$store.getters.getUser();
          if (user) {
            const parsedUser = JSON.parse(user);
            companyLogo = [parsedUser.company];
          }
        }
        return companyLogo[0];
      },
      getUserStatus() {
        if (this.activeCompanies.length > 0) {
          return this.activeCompanies.includes(this.reporter.company_id);
        } else {
          return true;
        }
      }
    }
  };
</script>

<style lang="scss">
  .reporter-cell {
    transition: all 0.1s ease-in-out;
    padding-top: 10px;
    padding-bottom: 10px;
    text-align: left;
    a {
      color: $ui-text;
      text-decoration: none;
    }
    p {
      margin-bottom: 0px;
    }

    .name-container {
      display: flex;
      align-items: center;
    }
    .inactive-banner {
      border-width: 1px;
      border-style: solid;
      border-radius: 6px;
      display: inline-block;
      padding-right: 12px;
      padding-left: 12px;
      padding-top: 4px;
      padding-bottom: 4px;
      font-family: "Campton-Medium";
      margin: 0px;
      border-color: $ui-grey;
      color: $ui-subtitle;
      cursor: default;
    }
    .reporter-job {
      font-family: "Campton-Medium";
      @media (max-width: 767px) {
        text-align: center;
      }
    }

    .company-container {
      display: flex;
      align-items: center;
    }

    .reporter-row {
      margin-right: 0px;
      margin-left: 0px;
    }
    .reporter-container {
      display: flex;
      flex-direction: row;

      .reporter-title {
        text-align: left;
        color: $ui-text;
        font-weight: 400;
        font-size: 20px;
        padding-left: 10px;
      }

      .right-content {
        @media (min-width: 992px) {
          display: flex;
          justify-content: flex-end;
        }
      }
    }
  }
</style>
