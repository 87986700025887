<template>
  <div class="timeline-event">
    <div class="timeline-icon-wrapper">
      <div class="timeline-icon">
        <i class="fas fa-tag" />
      </div>
    </div>
    <div class="timeline-content-container">
      <div class="content">
        <div class="title-container">
          <span class="inline-header">
            {{ getUpdatedBy() }}
          </span>
          <div class="translation inline-header">
            {{
              item.deletion ? $t("tags_info.removed") : $t("tags_info.added")
            }}
          </div>
          <i18n
            v-if="tags"
            :path="
              tags.length > 1
                ? 'tags_info.tags_message'
                : 'tags_info.tag_message'
            "
            class="translation"
          >
            <div class="timeline-tags-container">
              <div
                v-for="(tag, index) in tags"
                :key="index"
                class="timeline-tag-container"
              >
                <ui-tag :context="tag.color_name" subtle round small>
                  {{ $t(`tags_info.${tag.slug}`) }}
                </ui-tag>
              </div>
            </div>
          </i18n>
          <div class="date">
            {{ item.updated_on.format("LLL") }}
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import UiTag from "@/components/ui-modules/Tag";
  import tag_utils from "@/utils/tag_utils";

  export default {
    name: "TimelineTag",
    components: { UiTag },
    props: {
      user: {
        type: Object
      },
      item: {
        type: Object
      }
    },
    data() {
      return {};
    },
    computed: {
      tag() {
        return tag_utils.getTag(this.item.slug);
      },
      tags() {
        return this.item.slugs.map((slug) => tag_utils.getTag(slug));
      }
    },
    methods: {
      getUpdatedBy() {
        return this.user
          ? this.user.firstname + " " + this.user.lastname
          : this.$t("anonymous");
      }
    }
  };
</script>

<style lang="scss">
  .timeline-event {
    .timeline-tags-container {
      position: relative;
      display: inline;
      margin-right: -5px;
    }
    .timeline-tag-container {
      position: relative;
      display: inline;
      top: -2px;
      .notme-tag {
        margin: 0px;
        margin-right: 5px;
        margin-bottom: 5px;
      }
    }
  }
</style>
