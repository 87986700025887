<template>
  <div id="app">
    <router-view />
  </div>
</template>

<script>
  import store from "./store/index.js";
  // To be removed
  import VueCookies from "vue-cookies";
  import { Translation } from "@/utils/i18n/Translation";
  import url_utils from "./utils/url_utils.js";

  export default {
    name: "App",
    store,
    async created() {
      await this.setupLanguage();
      if (this.$store.state.auth.token) {
        await this.getUser();
        // To be removed
        await this.checkOldToken();
        this.removeOldRegion();
      }
    },
    methods: {
      getUser: async function () {
        await this.$store.dispatch("getUser");
        if (this.$store.getters.isE2Eenabled()) {
          await this.$store.dispatch("initializeDevice");
        }
      },
      // To be removed
      checkOldToken: async function () {
        const oldToken = localStorage.getItem("token");
        if (oldToken) {
          const tokenName = url_utils.getTokenName();
          const tokenExpirationDays =
            url_utils.getTokenExpirationDays(oldToken);
          VueCookies.set(
            tokenName,
            oldToken,
            tokenExpirationDays + "d",
            null,
            null,
            true
          );
          localStorage.removeItem("token");
        }
      },
      //To be removed
      removeOldRegion: function () {
        localStorage.removeItem("api_url");
        localStorage.removeItem("region");
      },
      setupLanguage: async function () {
        /**
         * Use browser language on first load
         * => If not found, use english.
         */
        if (this.$store.state.language === null) {
          const browserLanguage = Translation.getBrowserLocale();
          if (Translation.isLangSupported(browserLanguage)) {
            Translation.changeLanguage(browserLanguage);
          } else {
            Translation.changeLanguage("en");
          }
        }
        this.$i18n.locale = this.$store.state.language;
        this.moment.locale(this.$i18n.locale);
      }
    }
  };
</script>

<style lang="scss">
  @import "./styles/fonts/CamptonFontKit.css";

  #app {
    font-family: "Campton-Book", "Avenir", Helvetica, Arial, sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    text-align: center;
    background-color: $ui-card;
    color: #001b39;
    width: 100%;
    height: 100%;

    h1 {
      line-height: 1;
    }

    h1,
    h2,
    h3 {
      font-family: "Campton-Bold";
    }

    h4 {
      font-family: "Campton-Bold";
    }

    h5,
    h6 {
      font-family: "Campton-Bold";
    }
  }
</style>
