<template>
  <el-tooltip
    v-if="company"
    :content="company.name"
    placement="top-start"
    class="item"
    effect="dark"
  >
    <img
      v-if="company"
      :src="company.logo"
      class="company-logo"
      :style="'height: ' + size + 'px;' + 'width: ' + size + 'px;'"
    />
  </el-tooltip>
</template>

<script>
  export default {
    name: "CompanyLogo",
    props: {
      company: {
        type: Object,
        default: null
      },
      size: {
        type: Number,
        default: 40
      }
    }
  };
</script>

<style lang="scss">
  .company-logo {
    object-fit: contain;
    border-radius: 6px;
    -webkit-box-shadow: $widget-shadow;
    box-shadow: $widget-shadow;
    margin-right: 10px;
  }
</style>
