<template>
  <text-value :value="value" />
</template>

<script>
  import TextValue from "../TypeQueryValue/TextValue";

  export default {
    name: "StatusQueryValue",
    components: {
      TextValue
    },
    props: {
      value: {
        type: Object
      }
    },
    created() {
      if (this.value.value === undefined) {
        this.value.value = this.value.id;
      }
    }
  };
</script>

<style></style>
