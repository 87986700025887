<template>
  <div class="category-step">
    <div v-for="(item, index) of formatted_answers" :key="index">
      <div class="category-step--header">
        <!-- <img :src="item.category ? item.category.icon : ''" /> -->
        <p class="category-step--header-title">
          {{ item.category !== null ? item.category.name : "Other" }}
        </p>
      </div>
      <ui-tag
        v-for="(answer, i) of item.element"
        :key="i"
        context="dark_blue"
        circle
      >
        {{ answer.answer.answer }}
      </ui-tag>
    </div>
  </div>
</template>

<script>
  import UiTag from "@/components/ui-modules/Tag";

  export default {
    name: "CategoryStep",
    components: {
      UiTag
    },
    data() {
      return {
        formatted_answers: []
      };
    },
    props: {
      step: {
        type: Object,
        default: null
      },
      answers: {
        type: Array,
        default: null
      }
    },
    created() {
      let grouped = Array.from(
        this.answers
          .reduce(
            (m, o) =>
              m.set(
                o.answer.category_id,
                (m.get(o.answer.category_id) || []).concat(o)
              ),
            new Map()
          )
          .values()
      );

      grouped.forEach((element) => {
        let object = {
          element,
          category: element[0].answer.category
        };
        this.formatted_answers.push(object);
      });
    }
  };
</script>

<style lang="scss">
  .category-step {
    .category-step--header {
      .category-step--header-title {
        text-transform: uppercase;
        font-family: "Campton-Medium";
        color: $ui-text;
        margin-bottom: 0px;
        font-size: 14px;
        color: $ui-blue;
      }
    }
  }
</style>
