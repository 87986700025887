<template>
  <div class="master-selector" v-if="companies.length > 0">
    <div class="master-selector--container">
      <div class="master-selector--title">
        <p class="master-selector--title-text">{{ $t("organizations") }}</p>
        <a
          v-if="company_selected_index"
          class="clear-filter"
          @click="handle_clear_command"
          >{{ $t("clear") }}</a
        >
      </div>
      <multiselect
        v-model="company_selected_index"
        :options="companies"
        :multiple="true"
        :hide-selected="true"
        :taggable="false"
        :placeholder="$t('search_entity')"
        :selectLabel="''"
        track-by="id"
        label="name"
        @input="handle_change_command"
      >
        <span slot="noResult">
          {{ $t("no_company_found") }}
        </span>
        <template slot="option" slot-scope="props"
          ><img
            class="option__image"
            :src="props.option.logo"
            alt="Sub-entity Logo"
          />
          <div class="option__desc">
            <span class="option__title">{{ props.option.name }}</span>
          </div>
        </template>
      </multiselect>
    </div>
  </div>
</template>

<script>
  import companyApi from "@/api/companyApi";
  import Multiselect from "vue-multiselect";
  import permissions_utils from "@/utils/permissions_utils";

  export default {
    name: "MasterSelector",
    components: {
      Multiselect
    },
    data() {
      return {
        company_selected_index: null,
        companies: this.$store.getters.getSubcompanies() || []
      };
    },
    created() {
      this.getCompanyList();
    },
    methods: {
      getCompanyList() {
        if (this.can("invite subscribers to any company")) {
          companyApi
            .getCompanies()
            .then((response) => {
              const companies = response.data;
              this.companies = companies;
              this.$store.dispatch("updateSubcompanies", companies);
              if (this.$store.state.selected_companies) {
                const storedCompanies = this.$store.state.selected_companies;
                const found = this.companies.filter((x) =>
                  storedCompanies.includes(x.id)
                );
                this.company_selected_index = found;
              }
            })
            .catch((err) => {});
        } else {
          companyApi
            .getCompany()
            .then((response) => {
              if (
                response.data?.subcompanies &&
                response.data?.subcompanies.length > 0
              ) {
                const { subcompanies, ...rest } = response.data;
                const subentities = [rest, ...subcompanies];
                this.companies = subentities;
                this.$store.dispatch("updateSubcompanies", subentities);
                if (this.$store.state.selected_companies) {
                  const storedCompanies = this.$store.state.selected_companies;
                  const found = this.companies.filter((x) =>
                    storedCompanies.includes(x.id)
                  );
                  this.company_selected_index = found;
                  if (found.length > 0) {
                    const validItems = found.map(
                      (storedCompany) => storedCompany.id
                    );
                    this.$store.dispatch("updateSelectedCompanies", validItems);
                  } else {
                    this.$store.dispatch("updateSelectedCompanies", []);
                  }
                }
              } else {
                this.companies = [];
                this.$store.dispatch("updateSubcompanies", []);
                this.$store.dispatch("updateSelectedCompanies", []);
              }
            })
            .catch((err) => {});
        }
      },
      handle_change_command(command) {
        if (command !== "" && command.length > 0) {
          const commandIds = command.map((c) => c.id);
          this.$store.dispatch("updateSelectedCompanies", commandIds);
          this.company_selected_index = command;
        } else {
          this.handle_clear_command();
        }
      },
      handle_clear_command() {
        this.$store.dispatch("deleteSelectedCompanies");
        this.company_selected_index = null;
      },
      can(name) {
        return permissions_utils.can(name);
      }
    }
  };
</script>

<style lang="scss">
  .master-selector {
    .master-selector--container {
      background-color: $ui-lighter_grey;
      border-radius: 10px;
      margin: 0px 20px;
      padding: 10px;
      text-align: left;
      .option__image {
        width: 18px;
        display: inline-block;
        vertical-align: middle;
        object-fit: contain;
      }

      .multiselect__content-wrapper {
        ul {
          li {
            &:first-child {
              margin-top: 0px;
            }
            .option__desc {
              display: flex;
              justify-items: center;
              align-items: center;
              .option__title {
                display: flex;
                justify-items: center;
                align-items: center;
              }
            }
          }
        }
      }

      .multiselect__option {
        display: flex;
        flex-direction: row;
        white-space: pre-wrap;
        .option__title {
          font-size: 14px;
          margin-left: 5px;
          color: $ui-text;
          font-family: "Campton-Medium" !important;
        }
      }
      .multiselect__tags {
        background-color: $ui-background;
        .multiselect__input {
          background-color: inherit;
        }
        .multiselect__placeholder {
          color: $ui-text;
        }
      }
      .multiselect__option--highlight {
        background-color: $ui-light_grey !important;
        color: $ui-text;
      }

      .multiselect__tag {
        background: none;
        border: 1px solid $ui-grey;
        color: $ui-text;
        font-family: "Campton-Medium";
        border-radius: 5px;
        margin-right: 0px;
        margin-bottom: 0px;
        white-space: break-spaces;
        text-overflow: inherit;
        .multiselect__tag-icon {
          display: flex;
          justify-content: center;
          align-items: center;
          &:hover {
            background: none;
          }
          &:hover:after {
            font-family: "Campton-Bold";
            color: $ui-red;
          }
          &:after {
            color: $ui-text;
            height: 20px;
            width: 20px;
          }
        }
      }

      .master-selector--title {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-between;
        margin-bottom: 4px;
        .master-selector--title-text {
          margin: 0px 10px 0px 0px;
          color: $ui-text;
          font-family: "Campton-Medium";
        }

        .clear-filter {
          float: right;
          font-size: 12px;
          font-family: "Campton-Medium";
          padding-top: 2px;
          color: $ui-red;
          text-decoration: none;
          cursor: pointer;
          &:hover {
            color: $ui-text;
          }
        }

        .info-circle {
          color: $ui-dark_grey;
          align-self: center;
          padding-top: 4px;
          font-size: 16px;
        }
      }

      .el-input__inner {
        border-radius: 6px;
      }
    }
  }
  .master-selector--select {
    border-radius: 6px;
  }
</style>
