var icons = [
  "airport",
  "amusement",
  "aquarium",
  "art_gallery",
  "atm",
  "baby",
  "bank_dollar",
  "bank_euro",
  "bank_pound",
  "bank_yen",
  "bar",
  "barber",
  "baseball",
  "beach",
  "bicycle",
  "bowling",
  "bus",
  "cafe",
  "camping",
  "car_dealer",
  "car_rental",
  "car_repair",
  "casino",
  "civic_building",
  "convenience",
  "courthouse",
  "dentist",
  "doctor",
  "electronics",
  "fitness",
  "flower",
  "gas_station",
  "generic_business",
  "generic_recreational",
  "geocode",
  "golf",
  "government",
  "historic",
  "jewelry",
  "library",
  "lodging",
  "monument",
  "mountain",
  "movies",
  "museum",
  "pet",
  "police",
  "post_office",
  "repair",
  "restaurant",
  "school",
  "shopping",
  "ski",
  "stadium",
  "supermarket",
  "taxi",
  "tennis",
  "train",
  "travel_agent",
  "truck",
  "university",
  "wine",
  "worship_christian",
  "worship_general",
  "worship_hindu",
  "worship_islam",
  "worship_jewish",
  "zoo"
];

const getMapIcon = (types) => {
  let icon_type = "geocode";
  let icon_found = false;
  types.forEach((icon) => {
    if (!icon_found) {
      icon_found = icons.includes(icon);
      if (icon_found) {
        icon_type = icon;
      }
    }
  });
  return (
    "https://maps.gstatic.com/mapfiles/place_api/icons/" + icon_type + "-71.png"
  );
};

const getAdministrativeArea = (data) => {
  const addressComponents = data.address_components.reduce((acc, item) => {
    if (item.types.includes("locality")) {
      acc.name = item.long_name;
    } else if (item.types.includes("administrative_area_level_1")) {
      acc.administrativeLevel1 = item.short_name;
    } else if (item.types.includes("country")) {
      acc.country = item.long_name;
    }
    return acc;
  }, {});
  const { name, administrativeLevel1, country } = addressComponents;

  const datas = [name, administrativeLevel1, country].filter(
    (str) => str !== null
  );

  return datas.join(", ");
};

export default {
  getMapIcon,
  getAdministrativeArea
};
