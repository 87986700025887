<template>
  <el-tooltip effect="dark" :disabled="!user" placement="bottom">
    <div v-if="user" style="text-transform: capitalize" slot="content">
      {{ user.firstname + " " + user.lastname }}<br />{{ $t(user.role) }}
    </div>
    <div
      :style="{
        '--color': title
          ? '#637482'
          : user
            ? generateColorId(user.id)
            : generateColorId(id)
      }"
      class="avatar-circle"
    >
      <span v-if="user" class="initials">{{
        getInitial(user.firstname + " " + user.lastname)
      }}</span>
      <span v-else class="initials"> {{ title || getInitial(name) }}</span>
    </div>
  </el-tooltip>
</template>

<script>
  export default {
    name: "AvatarInitial",
    props: {
      user: {
        type: Object
      },
      name: {
        type: String
      },
      color: {
        type: String
      },
      title: {
        type: String
      },
      id: {
        type: Number
      }
    },
    methods: {
      getInitial(name) {
        return name
          .split(" ")
          .map((n) => n[0])
          .join("")
          .slice(0, 2);
      },
      generateColor(name) {
        let hex = this.intToRGB(this.hashCode(name));
        let red = `0x${hex[0]}${hex[1]}`;
        let blue = `0x${hex[2]}${hex[3]}`;
        let green = `0x${hex[4]}${hex[5]}`;
        return `${parseInt(red)}, ${parseInt(blue)}, ${parseInt(green)}`;
      },
      generateColorId(id) {
        let lastDigits = id % 100;
        switch (true) {
          case lastDigits < 10:
            return "#522d6e";
          case lastDigits < 20:
            return "#001B39";
          case lastDigits < 30:
            return "#c1473a";
          case lastDigits < 40:
            return "#3a6e58";
          case lastDigits < 50:
            return "#5f9e83";
          case lastDigits < 60:
            return "#1c78a5";
          case lastDigits < 70:
            return "#1f5688";
          case lastDigits < 80:
            return "#c47300";
          case lastDigits < 90:
            return "#d8ad00";
          case lastDigits <= 100:
            return "#312fc2";
          default:
            return "#312fc2";
        }
      },
      hashCode(str) {
        var hash = 0;
        for (var i = 0; i < str.length; i++) {
          hash = str.charCodeAt(i) + ((hash << 5) - hash);
        }
        return hash;
      },
      intToRGB(i) {
        var c = (i & 0x00ffffff).toString(16).toUpperCase();
        return "00000".substring(0, 6 - c.length) + c;
      }
    }
  };
</script>

<style lang="scss">
  .avatar-circle {
    @include avatar(50px);
  }
</style>
