<template>
  <div :class="rootClass">
    <i :class="['fas fa-circle-notch fa-spin spinner', iconClass]"></i>
  </div>
</template>

<script>
  const baseClass = "notme-loading";

  export default {
    name: "Tag",
    props: {
      context: {
        type: String,
        default: "grey"
      },
      full: {
        type: Boolean,
        default: false
      },
      big: {
        type: Boolean,
        default: false
      },
      small: {
        type: Boolean,
        default: false
      },
      className: {
        type: String
      }
    },
    computed: {
      rootClass() {
        return [
          baseClass,
          this.context && `${baseClass}-${this.context}`,
          this.full && `${baseClass}-full`,
          this.big && `${baseClass}-big`,
          this.small && `${baseClass}-small`,
          this.className
        ];
      },
      iconClass() {
        return [
          this.big && `${baseClass}-icon-big`,
          this.small && `${baseClass}-icon-small`
        ];
      }
    }
  };
</script>

<style lang="scss" scoped>
  .notme-loading {
    align-self: center;
    text-align: center;
    font-size: 18px;
    line-height: 16px;
    padding: 10px 0px;
  }

  .notme-loading-full {
    width: 100%;
  }

  .notme-loading-big {
    line-height: 24px;
  }

  .notme-loading-icon-big {
    font-size: 26px;
  }

  .notme-loading-small {
    line-height: 11px;
    padding: 4px;
  }

  .notme-loading-icon-small {
    font-size: 12px;
  }

  @mixin notme-loading-theme($color) {
    color: $color;
  }

  .notme-loading-blue {
    @include notme-loading-theme($ui-blue);
  }
  .notme-loading-dark_blue {
    @include notme-loading-theme($ui-dark_blue);
  }
  .notme-loading-light_blue {
    @include notme-loading-theme($ui-light_blue);
  }
  .notme-loading-red {
    @include notme-loading-theme($ui-red);
  }
  .notme-loading-dark_red {
    @include notme-loading-theme($ui-dark_red);
  }
  .notme-loading-light_red {
    @include notme-loading-theme($ui-light_red);
  }
  .notme-loading-green {
    @include notme-loading-theme($ui-green);
  }
  .notme-loading-dark_green {
    @include notme-loading-theme($ui-dark_green);
  }
  .notme-loading-yellow {
    @include notme-loading-theme($ui-yellow);
  }
  .notme-loading-dark_yellow {
    @include notme-loading-theme($ui-dark_yellow);
  }
  .notme-loading-grey {
    @include notme-loading-theme($ui-grey);
  }
  .notme-loading-dark_grey {
    @include notme-loading-theme($ui-dark_grey);
  }
  .notme-loading-light_grey {
    @include notme-loading-theme($ui-light_grey);
  }

  // <div style="width: 100%; align-self: center; text-align: center;">
  // .notme-tag {
  //   display: inline-block;
  //   border: 1px solid $ui-dark_blue;
  //   color: $ui-text;
  //   padding: 4px 14px 2px 14px;
  //   font-size: 16px;
  //   transition: 0.1s;
  //   font-family: "Campton-Medium";
  //   margin: 4px 8px 4px 0px;

  //   &:last-child {
  //     margin-right: 0px;
  //   }
  // }

  // @mixin notme-tag-theme-solid($color) {
  //   background: $color;
  //   color: $ui-white;
  //   border: none;
  // }

  // @mixin notme-tag-theme-subtle($color, $background) {
  //   background: $background;
  //   color: $color;
  //   border: none;
  // }

  // .notme-tag-circle {
  //   border-radius: 30px;
  // }

  // .notme-tag-round {
  //   border-radius: 6px;
  // }

  // .notme-tag-clickable {
  //   cursor: pointer;
  // }

  // .notme-tag-shadow {
  //   &:hover {
  //     -webkit-box-shadow: $widget-shadow;
  //     -moz-box-shadow: $widget-shadow;
  //     box-shadow: $widget-shadow;
  //   }
  // }

  // .notme-tag-small {
  //   font-size: 12px;
  //   font-family: "Campton-Medium";
  //   padding: 6px 10px 4px 11px;
  //   margin: 0px 8px 0px 0px;
  //   box-sizing: border-box;
  //   white-space: nowrap;
  // }

  // .notme-tag-blue-solid        { @include notme-tag-theme-solid($ui-blue);       }
  // .notme-tag-dark_blue-solid   { @include notme-tag-theme-solid($ui-dark_blue);  }
  // .notme-tag-light_blue-solid  { @include notme-tag-theme-solid($ui-light_blue); }
  // .notme-tag-red-solid         { @include notme-tag-theme-solid($ui-red);        }
  // .notme-tag-dark_red-solid    { @include notme-tag-theme-solid($ui-dark_red);   }
  // .notme-tag-light_red-solid   { @include notme-tag-theme-solid($ui-light_red);  }
  // .notme-tag-green-solid       { @include notme-tag-theme-solid($ui-green);      }
  // .notme-tag-dark_green-solid  { @include notme-tag-theme-solid($ui-dark_green); }
  // .notme-tag-yellow-solid      { @include notme-tag-theme-solid($ui-yellow);     }
  // .notme-tag-grey-solid        { @include notme-tag-theme-solid($ui-grey);       }
  // .notme-tag-dark_grey-solid   { @include notme-tag-theme-solid($ui-dark_grey);  }
  // .notme-tag-light_grey-solid  { @include notme-tag-theme-solid($ui-light_grey); }

  // .notme-tag-blue-subtle        { @include notme-tag-theme-subtle($ui-blue, $ui-blue-a10);       }
  // .notme-tag-dark_blue-subtle   { @include notme-tag-theme-subtle($ui-dark_blue, $ui-dark_blue-a10);  }
  // .notme-tag-light_blue-subtle  { @include notme-tag-theme-subtle($ui-light_blue, $ui-light_blue-a10); }
  // .notme-tag-red-subtle         { @include notme-tag-theme-subtle($ui-red, $ui-red-a10);        }
  // .notme-tag-dark_red-subtle    { @include notme-tag-theme-subtle($ui-dark_red, $ui-dark_red-a10);   }
  // .notme-tag-light_red-subtle   { @include notme-tag-theme-subtle($ui-light_red, $ui-light_red-a10);  }
  // .notme-tag-green-subtle       { @include notme-tag-theme-subtle($ui-green, $ui-green-a10);      }
  // .notme-tag-dark_green-subtle  { @include notme-tag-theme-subtle($ui-dark_green, $ui-dark_green-a10); }
  // .notme-tag-yellow-subtle      { @include notme-tag-theme-subtle($ui-yellow, $ui-yellow-a10);     }
  // .notme-tag-grey-subtle        { @include notme-tag-theme-subtle($ui-grey, $ui-grey-a10);       }
  // .notme-tag-dark_grey-subtle   { @include notme-tag-theme-subtle($ui-dark_grey, $ui-dark_grey-a10);  }
  // .notme-tag-light_grey-subtle  { @include notme-tag-theme-subtle($ui-light_grey, $ui-light_grey-a10); }
</style>
