<template>
  <el-tooltip
    effect="dark"
    :content="name"
    placement="top-start"
    :disabled="!showTooltip"
  >
    <div
      class="notme-avatar"
      :styles="this.styles"
      :style="{
        '--color': id ? generateColorId(id) : '#637482',
        '--size': size + 'px'
      }"
    >
      <span
        :class="['notme-avatar-text', `notme-avatar-text--${titleLength}`]"
        >{{ getName(name) }}</span
      >
      <div v-if="counter && counter > 0" class="counter-container">
        <div class="counter">
          <p>
            {{ counter }}
          </p>
        </div>
      </div>
    </div>
  </el-tooltip>
</template>

<script>
  const baseClass = "notme-avatar";

  export default {
    name: "Avatar",
    props: {
      name: {
        type: String
      },
      showTooltip: {
        type: Boolean
      },
      size: {
        type: Number,
        default: 44
      },
      id: {
        type: Number
      },
      counter: {
        type: Number
      },
      styles: {
        type: Object
      }
    },
    data() {
      return {
        titleLength: 0
      };
    },
    methods: {
      getName(name) {
        let text = name.trim();
        let result = text;
        if (text.length > 5) {
          result = text
            .split(/\s/g)
            .map((n) => n[0])
            .join("")
            .slice(0, 4);
        }
        this.titleLength = result.length;
        return result;
      },
      generateColorId(id) {
        let lastDigits = id % 100;
        switch (true) {
          case lastDigits < 10:
            return "#522d6e";
          case lastDigits < 20:
            return "#001B39";
          case lastDigits < 30:
            return "#c1473a";
          case lastDigits < 40:
            return "#3a6e58";
          case lastDigits < 50:
            return "#5f9e83";
          case lastDigits < 60:
            return "#1c78a5";
          case lastDigits < 70:
            return "#1f5688";
          case lastDigits < 80:
            return "#c47300";
          case lastDigits < 90:
            return "#d8ad00";
          case lastDigits <= 100:
            return "#312fc2";
          default:
            return "#312fc2";
        }
      }
    }
  };
</script>

<style lang="scss" scoped>
  .notme-avatar {
    display: inline-block;
    font-weight: 400;
    width: var(--size);
    height: var(--size);
    background-color: var(--color);
    border-radius: 50%;
    white-space: nowrap;
    vertical-align: middle;
    padding: 0;
    text-align: center;

    .notme-avatar-text {
      position: relative;
      color: #fff;
      font-family: "Campton-Bold";
      font-size: calc(var(--size) / 2.6);
      line-height: calc(var(--size));
      margin: 0 auto;
      text-transform: uppercase;

      &--3 {
        font-size: 0.9rem;
      }
      &--4 {
        font-size: 0.8rem;
      }
      &--5 {
        font-size: 0.7rem;
      }
      &--6 {
        font-size: 0.6rem;
      }
    }
    .counter-container {
      margin-top: -12px;
      margin-right: -6px;
      display: flex;
      justify-content: flex-end;
      .counter {
        background-color: $ui-subtitle;
        border-radius: 4px;
        border: 1px solid $ui-white;
        p {
          margin-bottom: 0px;
          font-size: 12px;
          padding-left: 5px;
          padding-right: 5px;
          padding-top: 2px;
          color: $ui-white;
          font-family: "Campton-Medium";
        }
      }
    }
  }
</style>
