import posthog from "posthog-js";
import env from "@/utils/env";

const identifyUser = (user) => {
  const allowedProperties = {
    company_id: user.company?.id,
    user_id: user.id,
    user_role: user.role
  };

  if (user.company) {
    posthog.group("company", user.company.id, { name: user.company.name });
  }
  posthog.identify(String(user.id), allowedProperties);
};

const resetUser = () => {
  posthog.reset();
};

const capturePageView = () => {
  if (!env.isProd()) {
    console.debug("Debug: Register pageview");
  }

  posthog.capture("$pageview");
};

export default { identifyUser, resetUser, capturePageView };
