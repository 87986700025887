<template>
  <li class="text-category-cell" @click="$emit('select')">
    <div class="text-category-cell-content">
      <i
        v-if="color"
        class="fas fa-circle color-icon"
        :style="{ '--color': `rgb(${color})` }"
      />
      <i
        v-else-if="color_hex"
        class="fas fa-circle color-icon"
        :style="{ '--color': color_hex }"
      />
      <p class="text-category-cell-text">{{ text }}</p>
    </div>
  </li>
</template>

<script>
  export default {
    name: "TextCategoryCell",
    props: {
      text: {
        type: String
      },
      color: {
        type: String,
        default: null
      },
      color_hex: {
        type: String,
        default: null
      }
    }
  };
</script>

<style lang="scss">
  .text-category-cell {
    padding: 6px 6px;
    cursor: pointer;
    &:hover {
      background: $ui-lighter_grey;
    }

    .text-category-cell-content {
      display: flex;
      flex-direction: row;
      gap: 10px;
      align-items: center;
      .color-icon {
        color: var(--color);
        font-size: 10px;
      }
      .text-category-cell-text {
        font-size: 15px;
        margin: 0px;
        margin-bottom: -3px;
        text-align: left;
        font-family: "Campton-Medium";
      }
    }
  }
</style>
