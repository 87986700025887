<template>
  <div class="report-timeline-container">
    <div>
      <div class="timeline-actions">
        <button-arrow
          :value="show_filters"
          :on-click="toggleFilters"
          :counter="
            selected_categories.length > 0 ? selected_categories.length : null
          "
          title="filters"
        />
        <multiselect
          ref="multiselect"
          v-model="selected_categories"
          :options="categories"
          :multiple="true"
          :hide-selected="false"
          :searchable="false"
          :close-on-select="false"
          :showLabels="false"
          @close="filter_status = false"
        >
          <template slot="option" slot-scope="props">
            <div class="option__desc">
              <i :class="`fa ${categoryIcon[props.option]}`" />
              <span class="option__title">{{ $t(props.option) }}</span>
              <div v-if="filterIsSelected(props.option)" class="option__active">
                <i class="fa fa-check" />
              </div>
            </div>
          </template>
        </multiselect>
      </div>
      <div class="timeline-header">
        <span>{{ $t("new_report_submitted") }}</span>
        <div>
          <div v-html="getReportDetails()" class="header-content" />
          <div v-html="getSubmittedBy()" class="header-content" />
        </div>
      </div>
      <div
        class="timeline-events-container"
        id="timeline-scrollable"
        v-if="!isLoading && !isLoadingUsers && timeline.length > 0"
      >
        <timeline-event
          :key="index"
          v-for="(item, index) in timeline"
          :item="item"
          :report="report"
          :users="users"
        />
      </div>
      <ui-loading big v-if="getIsLoadingTimeline()" />
    </div>
  </div>
</template>

<script>
  import TimelineEvent from "./ReportTimelineEvent.vue";
  import permissions_utils from "@/utils/permissions_utils";
  import timeline_utils from "@/utils/timeline_utils";
  import Multiselect from "vue-multiselect";
  import ButtonArrow from "@/components/ui-modules/Buttons/ButtonArrow";
  import UiLoading from "@/components/ui-modules/Loading";
  import moment from "moment";
  import talesApi from "@/api/talesApi";
  import userApi from "@/api/userApi";
  import url_utils from "@/utils/url_utils.js";

  export default {
    name: "ReportTimeline",
    components: {
      TimelineEvent,
      UiLoading,
      Multiselect,
      ButtonArrow
    },
    props: {
      report: {
        type: Object
      },
      itemToRefresh: {
        type: String
      }
    },
    data() {
      return {
        full_timeline: [],
        timeline: [],
        categories: [
          "info",
          "notes",
          "docs",
          "assignees",
          "tags",
          "resolution"
        ],
        categoryIcon: {
          info: "fa-clipboard",
          notes: "fa-comments",
          docs: "fa-paperclip",
          assignees: "fa-user",
          tags: "fa-tag",
          resolution: "fa-clipboard-check"
        },
        blocked: false,
        selected_categories: [],
        show_filters: false,
        filter_status: false,
        users: {},
        isLoading: false,
        isLoadingUsers: false,
        isUpdatingTimeline: false,
        refreshAttempts: 0
      };
    },
    watch: {
      itemToRefresh(newV, oldV) {
        if (newV) {
          this.isUpdatingTimeline = true;
          this.updateTimeline(newV);
        }
      },
      selected_categories(newV) {
        if (newV.length > 0) {
          this.timeline = [...this.full_timeline].filter((i) =>
            newV.includes(i.type)
          );
        } else {
          this.timeline = [...this.full_timeline];
        }
      },
      full_timeline(newV) {
        if (this.selected_categories.length > 0) {
          this.timeline = [...newV].filter((i) =>
            this.selected_categories.includes(i.type)
          );
        } else {
          this.timeline = [...newV];
        }
      },
      timeline(newV, oldV) {
        if (this.itemToRefresh) {
          if (oldV.length >= newV.length && this.refreshAttempts <= 3) {
            this.updateTimeline(this.itemToRefresh);
          } else {
            this.isUpdatingTimeline = false;
            this.$emit("clear-item-to-refresh");
            this.refreshAttempts = 0;
          }
        }
      },
      filter_status(newValue) {
        if (newValue !== this.show_filters) {
          this.show_filters = newValue;
          this.block();
        }
      },
      show_filters(newValue) {
        if (newValue) {
          this.$refs.multiselect.activate();
          this.filter_status = true;
        } else {
          this.$refs.multiselect.deactivate();
          this.filter_status = false;
        }
      }
    },
    created() {
      this.getTimeline();
    },
    methods: {
      getTimeline(type) {
        this.isLoading = type ? false : true;
        let itemsToFetch = type ? [type] : this.categories;
        let tales_api_url = url_utils.getEnvVariable("tales_api_url");

        const fetchPromises = itemsToFetch.map((itemType) => {
          // Check if the category is 'resolution' and handle accordingly
          if (itemType === "resolution") {
            return [
              talesApi.getTimelineElement(
                tales_api_url,
                this.report.id,
                itemType,
                { outcome_type: "report" }
              ),
              talesApi.getTimelineElement(
                tales_api_url,
                this.report.id,
                itemType,
                { outcome_type: "accused" }
              )
            ];
          } else {
            return talesApi.getTimelineElement(
              tales_api_url,
              this.report.id,
              itemType
            );
          }
        });

        Promise.allSettled(fetchPromises.flat())
          .then((results) => {
            /* Get only successful responses */
            const fulfilledResponses = results.filter(
              (result) => result.status === "fulfilled"
            );
            /* Map them into usable items to display */
            const timelineMapped = this.processTimelineData(
              fulfilledResponses,
              type
            );
            /* Sort the timeline events by date */
            let timelineSorted = [];
            if (type && this.full_timeline.length > 0) {
              let timelineWFilteredType = this.full_timeline.filter(
                (timelineEvent) => {
                  return timelineEvent.type !== type;
                }
              );
              timelineSorted = [...timelineWFilteredType, ...timelineMapped]
                .flat()
                .sort((a, b) => a.updated_on.diff(b.updated_on));
            } else {
              timelineSorted = timelineMapped
                .flat()
                .sort((a, b) => a.updated_on.diff(b.updated_on));
            }
            this.full_timeline = timelineSorted;
          })
          .catch((err) => console.log("err", err))
          .finally(() => {
            this.isLoading = false;
          });
      },
      async updateTimeline(type) {
        await new Promise((resolve) => {
          setTimeout(resolve, 2000);
        });
        this.refreshAttempts = this.refreshAttempts + 1;
        this.getTimeline(type);
      },
      processTimelineData(responses, timelineTypeRequest) {
        let timelineUserIds = [];
        const reportAssigneesIds = this.report?.assignees.map((assignee) => {
          this.users = {
            ...this.users,
            [assignee.id]: assignee
          };
          return String(assignee.id);
        });
        const utcOffset = moment().utcOffset();
        const mappedData = responses.map((res) => {
          const url = res.value?.config?.url;
          const type = url.substring(url.lastIndexOf("/") + 1);
          /* If the type is info, correctly sort the data from oldest to latest */
          if (type === "info") {
            res.value?.data.sort((a, b) => {
              const dateA = moment(a.updated_on);
              const dateB = moment(b.updated_on);
              return dateA.isBefore(dateB) ? -1 : dateA.isAfter(dateB) ? 1 : 0;
            });
          }
          let events = [];
          if (res.value?.data.length > 0) {
            let previousEvent = null;
            events = res.value?.data.map((item, index) => {
              let eventFile = null;
              let prev_status_id = null;
              let prev_score = null;
              if (!timelineUserIds.includes(item.updated_by)) {
                timelineUserIds.push(item.updated_by);
              }
              if (type === "assignees") {
                if (!timelineUserIds.includes(item.assignee_id)) {
                  timelineUserIds.push(item.assignee_id);
                }
              }
              if (type === "info") {
                if (previousEvent) {
                  if (previousEvent.status_id !== item.status_id) {
                    prev_status_id = previousEvent.status_id;
                  }
                  if (previousEvent.score !== item.score) {
                    prev_score = previousEvent.score;
                  }
                }
                previousEvent = item;
              }
              if (type === "docs") {
                const file = this.report.report_files.filter(
                  (reportFile) => reportFile.id == item.doc_id
                );
                if (file.length > 0) {
                  eventFile = file[0];
                }
              }

              return {
                type: type,
                updated_by: item.updated_by,
                assignee_id: item.assignee_id,
                updated_on: moment
                  .utc(item.updated_on, "YYYY-MM-DD HH:mm:ss")
                  .utcOffset(utcOffset),
                data: item.notes,
                outcome: item.outcome,
                outcome_type: item.outcome_type,
                file: eventFile,
                doc_id: item.doc_id,
                status_id: item.status_id,
                prev_status_id: prev_status_id,
                score: item.score,
                prev_score: prev_score,
                slug: item.slug
              };
            });
          }
          if (type === "assignees") {
            events = this.processTimelineAssignees(events);
          }
          if (type === "docs") {
            events = this.processTimelineDocuments(events);
          }
          if (type === "tags") {
            events = this.processTimelineTags(events);
          }
          if (type === "resolution") {
            events = this.processTimelineResolutions(events);
          }
          events = events.filter((timelineEvent) => {
            return (
              timelineEvent.type !== "info" ||
              timelineEvent.prev_score ||
              timelineEvent.prev_status_id
            );
          });
          if (events.length > 0 && type === "info") {
            // Set initialScoreAdded and initialStatusAdded variables to false to keep track of whether an initial score and status have been added to the timelineEvent
            let initialScoreAdded = false;
            let initialStatusAdded = false;

            for (const timelineEvent of events) {
              if (!initialScoreAdded && timelineEvent.prev_score !== null) {
                timelineEvent.initial_score = true;
                initialScoreAdded = true;
              }

              if (
                !initialStatusAdded &&
                timelineEvent.prev_status_id !== null
              ) {
                timelineEvent.initial_status = true;
                initialStatusAdded = true;
              }

              // If both an initial score and status have been added to timelineEvent
              if (initialScoreAdded && initialStatusAdded) {
                // Exit the loop
                break;
              }
            }
          }
          return events;
        });
        /* Check if available user info differs from required user info */
        const missingUsers = timelineUserIds.filter(
          (timelineUser) => !reportAssigneesIds.includes(timelineUser)
        );
        if (missingUsers.length > 0) {
          /* Get missing users data */
          this.getTimelineMissingUsers(missingUsers, timelineTypeRequest);
        }
        return mappedData;
      },
      getTimelineMissingUsers(missingUsers, timelineTypeRequest) {
        this.isLoadingUsers = timelineTypeRequest ? false : true;
        Promise.allSettled(
          missingUsers.map((missingUser) => {
            return userApi.getUserById(missingUser);
          })
        )
          .then((results) => {
            const fulfilledUsers = results.filter(
              (result) => result.status === "fulfilled"
            );
            fulfilledUsers.forEach((user) => {
              this.users = {
                ...this.users,
                [user.value?.data?.id]: user.value?.data
              };
            });
          })
          .catch((err) => console.log("err", err))
          .finally(() => {
            this.isLoadingUsers = false;
          });
      },
      processTimelineAssignees(events) {
        let tempEvents = [...events];
        /* Remove assignee duplication */
        tempEvents = tempEvents.filter(
          (value, index, self) =>
            index ===
            self.findIndex(
              (t) =>
                t.assignee_id === value.assignee_id &&
                t.updated_by === value.updated_by &&
                t.updated_on.isSame(value.updated_on)
            )
        );
        /* Group by dates */
        const eventsByDate = timeline_utils.groupEventsByDate(tempEvents);
        /* Compare snapshot differences and create events list */
        tempEvents = timeline_utils.compareSnapshots(
          eventsByDate,
          "assignee_id"
        );
        return tempEvents;
      },
      processTimelineTags(events) {
        let tempEvents = [...events];
        /* Remove tag duplication */
        tempEvents = tempEvents.filter(
          (value, index, self) =>
            index ===
            self.findIndex(
              (t) =>
                t.slug === value.slug &&
                t.updated_by === value.updated_by &&
                t.updated_on.isSame(value.updated_on)
            )
        );
        /* Group by dates */
        const eventsByDate = timeline_utils.groupEventsByDate(tempEvents);
        /* Compare snapshot differences and create events list */
        tempEvents = timeline_utils.compareSnapshots(eventsByDate, "slug");
        return timeline_utils.accumulateEventsForTimeline(
          tempEvents,
          "slug",
          "slugs"
        );
      },
      processTimelineDocuments(events) {
        let tempEvents = [...events];
        /* Group by dates */
        const documentsByDate = timeline_utils.groupEventsByDate(tempEvents);
        /* Compare snapshot differences and create events list */
        tempEvents = timeline_utils.compareSnapshots(documentsByDate, "doc_id");
        return timeline_utils.accumulateEventsForTimeline(
          tempEvents,
          "file",
          "files"
        );
      },
      processTimelineResolutions(events) {
        // 1. Group events by date
        const groupedEvents = timeline_utils.groupEventsByDate(events);

        // 2. Process each group of events
        const processedEvents = Object.values(groupedEvents).map(
          (group, index) => {
            const outcomes = group.flatMap((event) => event.outcome); // Use flatMap instead of map
            const notes = group[0].data;
            const updated_by = group[0].updated_by;
            const updated_on = group[0].updated_on;

            return {
              index,
              type: "resolution",
              updated_by,
              updated_on: moment(updated_on).utcOffset(moment().utcOffset()),
              data: notes,
              outcomes,
              outcome_type: group[0].outcome_type
            };
          }
        );

        // 3. Accumulate events for the timeline
        return timeline_utils.accumulateEventsForTimeline(
          processedEvents,
          "outcomes",
          "outcomes"
        );
      },
      getReportDetails() {
        let categoriesArray = [];
        if (this.report.report_steps[1]?.report_step_questions) {
          this.report.report_steps[1]?.report_step_questions.forEach(
            (question) => {
              question.answers.forEach((answerObj) => {
                if (answerObj.answer.category) {
                  if (
                    !categoriesArray.includes(answerObj.answer.category.name)
                  ) {
                    categoriesArray.push(answerObj.answer.category.name);
                  }
                }
              });
            }
          );
        }
        if (categoriesArray.length > 0) {
          const lastItem = categoriesArray.pop();
          return categoriesArray.length > 0
            ? this.$t("report_summary_and", {
                content: categoriesArray.join(", "),
                end: lastItem
              })
            : this.$t("report_summary", {
                end: lastItem
              });
        } else {
          return null;
        }
      },
      getSubmittedBy() {
        return this.report.is_anonymous || !this.report.user
          ? this.$t("report_submitted_anon", {
              when: moment(this.report.submitted_at).format("LLLL")
            })
          : this.$t("report_submitted_by", {
              who: this.report.user.firstname + " " + this.report.user.lastname,
              when: moment(this.report.submitted_at).format("LLLL")
            });
      },
      getIsLoadingTimeline() {
        return this.isLoading || this.isLoadingUsers || this.isUpdatingTimeline;
      },
      filterIsSelected(filterItem) {
        return this.selected_categories.includes(filterItem);
      },
      toggleFilters() {
        if (!this.blocked) {
          this.show_filters = !this.show_filters;
        }
      },
      block() {
        this.blocked = true;
        setTimeout(() => {
          this.blocked = false;
        }, 200);
      },
      can(name) {
        return permissions_utils.can(name);
      }
    }
  };
</script>

<style lang="scss">
  .report-timeline-container {
    .timeline-actions {
      display: flex;
      align-items: center;
      justify-content: flex-end;
      margin-bottom: 10px;
      position: relative;
      .multiselect {
        position: absolute;
        width: 170px;
        z-index: 1;
        right: 0;
        bottom: -50px;
        .multiselect__select {
          display: none;
        }
        .multiselect__tags {
          display: none;
          .multiselect__placeholder {
            font-family: "Campton-Medium";
            color: $ui-text;
            font-size: 16px;
            margin-bottom: 0px;
          }
        }
        .multiselect__content-wrapper {
          background-color: $ui-card;
          -webkit-box-shadow: $widget-shadow;
          -moz-box-shadow: $widget-shadow;
          box-shadow: $widget-shadow;
          border-radius: 6px;
          .multiselect__option--selected {
            background-color: $ui-card;
            font-weight: 400;
          }
          .multiselect__option--highlight {
            background-color: $ui-card;
          }
          .option__desc {
            display: flex;
            flex-direction: row;
            align-items: center;
            i {
              width: 15px;
              color: $ui-blue;
            }
            .option__title {
              margin-left: 10px;
              font-size: 14px;
              color: $ui-text;
              font-family: "Campton-Medium" !important;
            }
            .option__active {
              flex: 1;
              display: flex;
              align-items: center;
              justify-content: flex-end;
              i {
                font-size: 10px;
              }
            }
          }
        }
      }
    }
    .timeline-header {
      background-color: $ui-background;
      border-radius: 10px;
      padding: 10px 15px;
      text-align: left;
      font-family: "Campton-Medium";
      span {
        font-family: "Campton-Bold";
        color: $ui-text;
      }
      .header-content {
        display: inline;
        padding: 0px;
        margin: 0px;
      }
    }
    .timeline-events-container {
      margin-top: 10px;
      margin-bottom: 10px;
      padding-left: 25px;
    }
    .loader-timeline {
      position: relative !important;
      width: 100%;
      height: 50px;
    }
  }
</style>
