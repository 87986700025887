<template>
  <div
    v-if="can('view report assignees')"
    style="display: inline-block; vertical-align: top"
  >
    <ul class="assignee-pile">
      <li v-for="(assignee, index) in assignees.slice(0, 3)" :key="index">
        <avatar-initial :user="assignee" />
      </li>
      <li v-if="assignees.length > 3">
        <el-tooltip effect="dark" placement="bottom">
          <div slot="content">
            <a v-for="(assignee, index) in assignees">
              {{ assignee.firstname + " " + assignee.lastname }}<br />
            </a>
          </div>
          <avatar-initial :title="`+${assignees.length - 3}`" />
        </el-tooltip>
      </li>
    </ul>
  </div>
</template>

<script>
  import AvatarInitial from "@/components/AvatarInitial";
  import permissions_utils from "@/utils/permissions_utils";

  export default {
    name: "AssigneeInitial",
    components: {
      AvatarInitial
    },
    props: {
      assignees: {
        type: Array
      }
    },
    methods: {
      can(name) {
        return permissions_utils.can(name);
      }
    }
  };
</script>

<style lang="scss">
  .assignee-pile {
    display: flex;
    align-items: center;
    list-style-type: none;
    line-height: 0;
    margin-top: 0;
    margin-bottom: 0;
    padding-inline-start: 16px;

    li {
      margin-top: -3px;
      border-radius: 50%;
      border: 3px solid white;
      margin-left: -10px;
    }

    .avatar-circle {
      @include avatar(35px);
    }
  }
</style>
