<template>
  <div class="no-data">
    <img src="@/assets/reports.png" height="200" alt="" />
    <h4>
      {{ show_no ? $t("no_data") : "" }}
      {{ show_no ? $t(title).toLowerCase() : $t(title) }}.
    </h4>
  </div>
</template>

<script>
  export default {
    name: "NoDataContent",
    props: {
      title: {
        type: String
      },
      show_no: {
        type: Boolean,
        default: true
      }
    }
  };
</script>

<style lang="scss">
  .no-data {
    text-align: center;
    margin: 0 auto;
    margin-top: 150px;
    max-width: 567px;
    @media (max-width: 767px) {
      margin-top: 80px;
    }
    h4 {
      font-size: 1.25rem;
      color: $ui-text;
    }
  }
</style>
