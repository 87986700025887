<template>
  <div class="report-avatar-container">
    <avatar-name
      :name="
        !is_anonymous
          ? `${reportUser().firstname} ${reportUser().lastname}`
          : $t('anonymous')
      "
      :id="!is_anonymous ? user.id : 0"
    />
    <ui-tag
      context="light_blue"
      class="executive-tag"
      subtle
      circle
      small
      v-if="isReporterExecutive() && is_report_header"
    >
      <i class="fas fa-id-badge" /> {{ $t("executive_report") }}
    </ui-tag>
  </div>
</template>

<script>
  import AvatarName from "@/components/ui-modules/Avatar/BaseName";
  import UiTag from "@/components/ui-modules/Tag";
  import report_utils from "@/utils/report_utils";

  export default {
    name: "ReportUser",
    components: {
      AvatarName,
      UiTag
    },
    methods: {
      reportUser() {
        if (!this.isAnonymous && this.user) {
          return this.user;
        }
        return {
          firstname: "Anonymous",
          lastname: "",
          job_title: "",
          role: "user"
        };
      },
      isReporterExecutive() {
        return report_utils.isReporterExecutive(this.user, this.is_anonymous);
      }
    },
    props: {
      user: {
        type: Object,
        default: null
      },
      is_anonymous: {
        type: Boolean
      },
      is_report_header: {
        type: Boolean
      }
    }
  };
</script>

<style lang="scss">
  .report-avatar-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    .executive-tag {
      margin-top: 10px;
      margin-bottom: -10px;
      width: min-content;
      i {
        margin-right: 5px;
      }
    }
  }
</style>
