<template>
  <div v-if="initialLoadDone" class="container-fluid overview">
    <div class="row row-height">
      <div class="col-12 col-lg-9 dashboard-main">
        <dashboard-header
          title="pages.dashboard.overview"
          :user="user"
          descriptionLink="https://help.not-me.com/overview-screen"
        >
          <date-picker :value="dateValue" v-on:changeDate="changeDate" />
        </dashboard-header>
        <div
          v-if="Object.keys(metrics).length > 0"
          class="row row-flex-shelf"
          style="margin-top: -32px"
        >
          <top-widget
            v-if="metrics.new_reports"
            :data_nb="String(metrics.new_reports)"
            :data_title="$t('dashboard_sections.overview.received')"
            color="#c1473a"
            to="reports"
            data_icon="fa-paper-plane"
            class="widget-container"
          />
          <top-widget
            v-if="metrics.all_resolved_reports"
            :data_nb="String(metrics.all_resolved_reports)"
            :data_title="$t('dashboard_sections.overview.resolved')"
            color="#5f9e83"
            to="reports?status_ids=6"
            data_icon="fa-clipboard-check"
            class="widget-container"
          />
          <top-widget
            v-if="metrics.res_time_avg_days"
            :data_nb="String(metrics.res_time_avg_days) + ' ' + $t('days')"
            :data_title="$t('dashboard_sections.overview.time_to_resolution')"
            color="#96C3D9"
            data_icon="fa-calendar-check"
            class="widget-container"
          />
        </div>
        <div class="banner-container">
          <h4>{{ $t("dashboard_sections.overview.overview_banner_title") }}</h4>
          <h5>({{ $t("dashboard_sections.overview.selected_period") }})</h5>
        </div>
        <div v-if="charts_data.length > 0" class="row charts-row">
          <chart
            v-for="(chart, index) in charts_data"
            :key="chart.id"
            :data="chart"
            :class="index < 2 && 'top-chart'"
          />
        </div>
        <ui-loading v-else-if="loading_charts_data" big context="blue" />
        <no-data-content v-else title="pages.dashboard.analytics" />
      </div>
      <div class="col-12 col-lg-3 dashboard-sidebar">
        <sidebar
          :users="dashboard_users"
          :reports="reports"
          :count="reports_per_user"
          :anonymous_reports="anonymous_reports"
          :loading_anonymous_reports="loading_anonymous_reports"
          :loading_dashboard_users="loading_dashboard_users"
          :loading_reports="loading_reports"
          :report_aging="report_aging"
          :all_active_reports="all_active_reports"
        />
      </div>
    </div>
  </div>
  <div v-loading.lock="!initialLoadDone" v-else class="loader-middle" />
</template>

<script>
  import DashboardHeader from "@/components/DashboardHeader";
  import DropdownButton from "@/components/DropdownButton";
  import DatePicker from "@/components/DatePicker";

  import Sidebar from "./components/sidebar/index.vue";
  import TopWidget from "./components/TopWidget";
  import Chart from "./components/Chart";
  import NoDataContent from "@/components/NoDataContent";
  import UiLoading from "@/components/ui-modules/Loading";
  import OnboardingModal from "@/components/modal/OnboardingModal";

  import moment from "moment";

  import companyApi from "../../../api/companyApi";
  import reportApi from "../../../api/reportApi";
  import analyticsApi from "@/api/analyticsApi";

  import permissions_utils from "@/utils/permissions_utils";
  import url_utils from "@/utils/url_utils";
  import report_utils from "@/utils/report_utils";

  export default {
    name: "Overview",
    components: {
      DashboardHeader,
      DropdownButton,
      DatePicker,
      TopWidget,
      Chart,
      OnboardingModal,
      NoDataContent,
      UiLoading,
      Sidebar
    },
    data() {
      return {
        all_charts: [],
        charts_data: [],
        loading_charts_data: false,
        anonymous_reports: {},
        loading_anonymous_reports: false,
        report_aging: {},
        loading_report_aging: false,
        metrics: {},
        all_active_reports: null,
        reports_per_user: 0,
        placeId: null,
        dateValue: [
          new Date(
            moment().subtract(3, "months").add(1, "day").format("YYYY-MM-DD")
          ),
          new Date(moment().add(1, "day").format("YYYY-MM-DD"))
        ],
        currentFilterIndex: 0,
        end_date: moment().format("YYYY-MM-DD"),
        start_date: moment().subtract(3, "month").format("YYYY-MM-DD"),
        company_ids: null,
        subcompany_ids: null,
        filters: ["this_week", "last_week", "this_month", "last_month"],
        dashboard_users: [],
        loading_dashboard_users: false,
        reports: 0,
        loading_reports: false,
        query: {
          offset: 0,
          sort_by: "submitted_at",
          order_by: "desc",
          start_date: null,
          harasser_id: null,
          assignee_id: null,
          behavior_id: null,
          place_id: null,
          location_id: null,
          limit: 3,
          status_ids: [],
          scores: [],
          is_anonymous: null
        },
        analytics_api_url: "",
        user: this.getUser()
      };
    },
    computed: {
      placeDetailsRequest() {
        if (this.placeId) {
          return {
            placeId: this.placeId
          };
        }
      },
      initialLoadDone() {
        return (
          this.charts_data.length > 0 ||
          this.reports.length > 0 ||
          Object.keys(this.anonymous_reports).length > 0
        );
      },
      selected_company() {
        return this.$store.getters.getSelectedCompanies();
      },
      subentities() {
        return this.$store.getters.getSubcompanies();
      },
      selected_dates() {
        return this.start_date + this.end_date;
      }
    },
    watch: {
      selected_company(newValue) {
        if (newValue) {
          let newQuery = [];
          if (newValue) {
            newQuery = [...newValue];
          } else {
            newQuery = this.subcompany_ids;
          }
          this.company_ids = newQuery;
        } else {
          this.company_ids = this.subentities
            ? this.subentities.map((subcompany) => subcompany.id)
            : [];
        }
        this.getDashboard();
      },
      subentities(newValue) {
        if (newValue) {
          const subcompanies = newValue.map(
            (subcompany) => subcompany && subcompany.id
          );
          if (
            subcompanies.length > 0 &&
            (!this.company_ids || this.company_ids?.length === 0)
          ) {
            this.company_ids = subcompanies;
            this.getDashboard();
          }
          this.subcompany_ids = subcompanies;
        }
      },
      selected_dates(newValue, oldValue) {
        if (newValue !== oldValue) {
          this.getAnalyticsCharts();
        }
      }
    },
    async created() {
      this.analytics_api_url = url_utils.getEnvVariable("analytics_api_url");
      this.setupDateFilters();
    },
    methods: {
      getUser() {
        const user = this.$store.getters.getUser();
        return typeof user === "string" ? JSON.parse(user) : user;
      },
      getDashboard() {
        this.getDashboardUsers();
        this.getRecentlySubmittedReports();
        this.getAnalyticsCharts();
        this.getSidebarAnalytics();
      },
      getSidebarAnalytics() {
        this.loading_anonymous_reports = true;
        const user = JSON.parse(this.$store.state.auth.user);
        const cid = this.company
          ? this.company.id
          : user.company && user.company.id;
        const now = moment().format("YYYY-MM-DD");
        const yearAgo = moment().subtract(1, "year").format("YYYY-MM-DD");
        const isAdmin = this.company_ids?.length > 0 ? true : false;
        analyticsApi
          .getAnalyticsReport(
            this.analytics_api_url,
            cid,
            yearAgo,
            now,
            "BETWEEN",
            isAdmin,
            [16],
            this.company_ids,
            "JSON"
          )
          .then((response) => {
            this.reports_per_user =
              response.data.metrics.avg_reports_per_member;
            if (response.data.dataset) {
              const mappedData = response.data.dataset[0].data.map((i) => {
                // Swap places between first and second items to match all the other graphs
                [i[0], i[1]] = [i[1], i[0]];
                return i;
              });
              this.anonymous_reports = {
                ...response.data.dataset[0],
                type: "anon",
                data: mappedData.reverse(),
                title: response.data.dataset[0].title,
                subtitle: `(${this.$t("last_x_months", { x: "12" })})`
              };
            }
          })
          .catch((err) => {
            this.reports_per_user = null;
            console.log("getAnalyticsReport err", err);
          })
          .finally(() => (this.loading_anonymous_reports = false));
      },
      getAnalyticsCharts() {
        this.loading_charts_data = true;
        this.loading_report_aging = true;
        const user = JSON.parse(this.$store.state.auth.user);
        const cid = this.company
          ? this.company.id
          : user.company && user.company.id;
        const chartIds = [14, 15, 3, 2, 7];
        const isAdmin = this.company_ids?.length > 0 ? true : false;

        analyticsApi
          .getAnalyticsReport(
            this.analytics_api_url,
            cid,
            null,
            null,
            null,
            isAdmin,
            [13],
            this.company_ids,
            "JSON"
          )
          .then((response) => {
            this.report_aging = {
              ...response.data?.dataset[0],
              title: `${this.$t("utils.organization_status.active")} 
            ${response.data?.dataset[0].title}`,
              isAging: true
            };
            this.all_active_reports = response.data.metrics.active_reports;
          })
          .catch((err) => {
            console.log("getAnalyticsReport no date err", err);
          })
          .finally(() => (this.loading_report_aging = false));

        analyticsApi
          .getAnalyticsReport(
            this.analytics_api_url,
            cid,
            this.start_date,
            this.end_date,
            "BETWEEN",
            isAdmin,
            chartIds,
            this.company_ids,
            "JSON"
          )
          .then((response) => {
            this.charts_data = response.data?.dataset
              .map((d) => {
                // If the chart is Severity, make it a donut
                if (d.id === 14) {
                  return { ...d, type: "donut" };
                }
                return d;
              })
              .sort((a, b) => chartIds.indexOf(a.id) - chartIds.indexOf(b.id));
            this.metrics = response.data?.metrics;
          })
          .catch((err) => {
            console.log("getAnalyticsReport err", err);
          })
          .finally(() => (this.loading_charts_data = false));
      },
      getDashboardUsers() {
        this.loading_dashboard_users = true;
        companyApi
          .getCompanyDashboardUsers({
            company_ids: this.company_ids ? this.company_ids : null
          })
          .then((response) => {
            let users = response.data.users;
            Promise.all(
              users.map((dashboardUser) => {
                return this.getNbReportUser(dashboardUser.id);
              })
            )
              .then((results) => {
                const result = results.reduce(
                  (map, obj) => (
                    (map[obj.config.params.assignee_id] = obj.data.page.total),
                    map
                  ),
                  {}
                );
                this.dashboard_users = users
                  .filter((user) => user.id)
                  .map((user) => {
                    return {
                      ...user,
                      assigned_reports: result[user.id]
                    };
                  });
              })
              .catch((err) => {
                this.dashboard_users = users;
              });
          })
          .catch((_) => {})
          .finally(() => (this.loading_dashboard_users = false));
      },
      getNbReportUser(userId) {
        return reportApi.getReports({
          limit: 0,
          assignee_id: userId,
          status_ids: [3, 4, 5]
        });
      },
      getRecentlySubmittedReports() {
        this.loading_reports = true;
        reportApi
          .getReports({
            offset: 0,
            sort_by: "submitted_at",
            order_by: "desc",
            limit: 0,
            has_assignee: false,
            company_ids: this.company_ids ? this.company_ids : null
          })
          .then((response) => {
            this.reports = response.data.page?.total;
          })
          .catch((_) => {})
          .finally(() => (this.loading_reports = false));
      },
      setupDateFilters() {
        if (this.selected_company && this.selected_company?.length > 0) {
          this.company_ids = [...this.selected_company];
        } else if (this.subentities) {
          const subentity_ids = this.subentities.map(
            (subcompany) => subcompany.id
          );
          this.company_ids = subentity_ids;
        }
        if (this.selected_company || this.subentities) {
          this.getDashboard();
        }
      },
      can(name) {
        return permissions_utils.can(name);
      },
      clearDates() {
        this.dateValue = [];
      },
      changeDate(date) {
        this.dateValue = date;
        if (date) {
          this.start_date = moment(date[0]).format("YYYY-MM-DD");
          this.end_date = moment(date[1]).format("YYYY-MM-DD");
        }
      },
      getStatusTitle(status_id) {
        return this.$t(report_utils.getStatusTitle(status_id));
      },
      parseCookies(cookies) {
        return document.cookie.split(";").reduce((res, c) => {
          const [key, val] = c.trim().split("=").map(decodeURIComponent);
          try {
            return Object.assign(res, { [key]: JSON.parse(val) });
          } catch (e) {
            return Object.assign(res, { [key]: val });
          }
        }, {});
      }
    }
  };
</script>
<style lang="scss">
  .row-height {
    @media (min-width: 991px) {
      margin-right: -30px;
      height: 100vh;
      .dashboard-main,
      .dashboard-sidebar {
        padding-top: 15px;
        height: 100%;
        overflow-y: scroll;
        .top-widget {
          padding-left: 0px;
          padding-right: 0px;
        }
      }
      .dashboard-sidebar {
        display: flex;
        border-left: 1px solid $ui-light_grey;
        .top-widget {
          flex: 0 0 100% !important;
          max-width: 100% !important;
          height: min-content;
          .widget-body {
            padding: 0px;
          }
        }
      }
    }
    @media (min-width: 576px) {
      .right-content {
        .el-dropdown {
          margin-bottom: 8px;
        }
      }
    }
    @media (max-width: 991px) {
      .dashboard-main {
        padding-right: 0px;
        .row {
          margin-right: 0px;
          margin-left: 0px;
        }
      }
      .dashboard-sidebar {
        padding-right: 0px;
      }
      .sidebar {
        padding-right: 15px;
        padding-left: 15px;
        padding-bottom: 30px;
        .top-widget {
          padding: 0px;
        }
      }
      .dashboard-header {
        padding-right: 15px;
        padding-left: 15px;
        padding-top: 20px;
      }
    }
  }

  .overview {
    margin-left: -32px;
    width: calc(100% + 47px);
    margin-top: -15px;
  }
  .charts-row {
    padding-right: 16px;
    padding-left: 16px;
    padding-bottom: 32px;
    justify-content: space-between;
    @media (max-width: 768px) {
      margin-left: 0px;
      margin-right: 0px;
      padding-bottom: 0px;
    }
    .chart.top-chart {
      width: 100% !important;
      max-width: 49% !important;
      @media (min-width: 768px) and (max-width: 1600px) {
        .custom-chart-container {
          max-width: 280px;
          margin-left: auto !important;
          margin-right: auto !important;
        }
      }
      @media (min-width: 1600px) {
        .custom-chart-container {
          max-width: 340px;
          margin-left: auto !important;
          margin-right: auto !important;
        }
      }
    }
  }

  .dashboard-main {
    .banner-container {
      h4 {
        color: $ui-text;
      }
      h5 {
        color: $ui-subtitle;
        font-size: 18px;
      }
    }
    .el-button--dropdown {
      border-radius: 20px;
      background-color: $ui-lighter_grey;
      color: $ui-text;
      border-width: 0px;
      &:hover {
        -webkit-box-shadow: $widget-shadow;
        -moz-box-shadow: $widget-shadow;
        box-shadow: $widget-shadow;
      }
    }
    .title-dashboard {
      text-align: left;
      margin-bottom: 20px;
    }
    @media (max-width: 576px) {
      .el-range-editor--daterange,
      .el-input__inner {
        width: 100% !important;
      }
    }
    .el-range-editor--daterange,
    .el-input__inner {
      justify-content: space-around;
    }
    .row-flex-shelf {
      display: flex;
      justify-content: space-between;
      flex-wrap: wrap;
      padding-top: 12px;
      padding-bottom: 12px;
      padding-left: 16px;
      padding-right: 16px;
      .widget-container {
        padding-left: 0px;
        padding-right: 0px;
        .widget-body {
          padding-left: 0px;
        }
        flex-basis: calc(100%);
        @media (min-width: 576px) and (max-width: 991px) {
          max-width: 48%;
        }
        @media (min-width: 991px) {
          max-width: 32%;
        }
      }
    }
  }

  .loader-middle {
    top: 200px;
  }

  .widget0-style {
    color: $ui-red;
  }

  .widget1-style {
    color: $ui-text;
  }

  .widget2-style {
    color: $ui-blue;
  }

  .widget3-style {
    color: $ui-light_blue;
  }
</style>
