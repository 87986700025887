<template>
  <div class="company-search-container">
    <a
      v-if="company_selected_index"
      class="clear-filter"
      @click="handle_change_command"
      >{{ $t("clear") }}</a
    >
    <multiselect
      class="company-search"
      v-model="company_selected_index"
      :options="companies"
      :multiple="true"
      :hide-selected="true"
      :taggable="false"
      :placeholder="$t('search_organization')"
      :selectLabel="''"
      track-by="id"
      label="name"
      @input="handle_change_command"
    >
      <span slot="noResult">
        {{ $t("no_company_found") }}
      </span>
      <template slot="option" slot-scope="props"
        ><img
          class="option__image"
          :src="props.option.logo"
          alt="Sub-entity Logo"
        />
        <div class="option__desc">
          <span class="option__title">{{ props.option.name }}</span>
        </div>
      </template>
    </multiselect>
  </div>
</template>

<script>
  import Multiselect from "vue-multiselect";
  import companyApi from "@/api/companyApi";
  import permissions_utils from "@/utils/permissions_utils";

  export default {
    name: "CompanySearch",
    components: {
      Multiselect
    },
    props: {
      activeCompanies: {
        type: Array
      },
      toggleActiveCompanies: {
        type: Function
      }
    },
    data() {
      return {
        companies: [],
        company_selected_index: null
      };
    },
    created() {
      this.current_company = this.company || {};
      this.getCompanies();
    },
    computed: {
      companiesToShow() {
        return this.companies.filter((company) => {
          return (
            company.name
              .toLowerCase()
              ?.includes(this.searchText.toLowerCase()) ||
            company.id == this.searchText
          );
        });
      }
    },
    methods: {
      getCompanies() {
        if (
          this.can("update report company") ||
          this.can("invite subscribers to any company")
        ) {
          companyApi
            .getCompanies()
            .then((response) => {
              this.companies = response.data;
            })
            .catch((err) => {
              console.log("err", err);
            });
        } else {
          this.companies = this.$store.getters.getSubcompanies();
          const storedCompanies = this.$store.state.selected_companies;
          const found = this.companies.filter((x) =>
            storedCompanies?.includes(x.id)
          );
          if (found.length > 0) {
            this.company_selected_index = found;
            const companyIds = found.map((company) => company.id);
            this.toggleActiveCompanies(companyIds);
          }
        }
      },
      handle_change_command(command) {
        if (command !== "" && command.length > 0) {
          this.company_selected_index = command;
          const companyIds = command.map((company) => company.id);
          this.toggleActiveCompanies(companyIds);
        } else {
          this.handle_clear_command();
          this.toggleActiveCompanies(null);
        }
      },
      handle_clear_command() {
        this.company_selected_index = null;
      },
      can(name) {
        return permissions_utils.can(name);
      }
    }
  };
</script>

<style lang="scss">
  .company-search-container {
    width: 100%;
    a.clear-filter {
      float: right;
      font-size: 12px;
      font-weight: 700;
      padding-top: 2px;
      color: $ui-red;
      margin-top: -25px;
      cursor: pointer;
      &:hover {
        color: $ui-dark_blue;
      }
    }
    .company-search {
      border-radius: 10px;
      text-align: left;
      margin-bottom: 16px;
      .option__image {
        width: 18px;
        display: inline-block;
        vertical-align: middle;
        object-fit: contain;
      }
      .multiselect__content-wrapper {
        ul {
          li {
            &:first-child {
              margin-top: 0px;
            }
            .option__desc {
              display: flex;
              justify-items: center;
              align-items: center;
              .option__title {
                display: flex;
                justify-items: center;
                align-items: center;
              }
            }
          }
        }
      }

      .multiselect__option {
        display: flex;
        flex-direction: row;
        white-space: pre-wrap;
        .option__title {
          font-size: 14px;
          margin-left: 5px;
          color: $ui-dark_blue;
          font-family: "Campton-Medium" !important;
        }
      }
      .multiselect__tags {
        .multiselect__input {
          background-color: inherit;
        }
        input::placeholder {
          color: $ui-grey;
          font-size: 14px;
        }
        .multiselect__placeholder {
          color: $ui-grey;
        }
      }
      .multiselect__option--highlight {
        background-color: $ui-light_grey !important;
        color: $ui-dark_blue;
      }

      .multiselect__tag {
        background: none;
        border: 1px solid $ui-grey;
        color: $ui-dark_blue;
        font-weight: 700;
        border-radius: 5px;
        margin-right: 0px;
        margin-bottom: 0px;
        white-space: break-spaces;
        text-overflow: inherit;
        margin-right: 5px;
        .multiselect__tag-icon {
          display: flex;
          justify-content: center;
          align-items: center;
          &:hover {
            background: none;
          }
          &:hover:after {
            font-weight: 700;
            color: $ui-red;
          }
          &:after {
            color: $ui-dark_blue;
            height: 20px;
            width: 20px;
          }
        }
      }

      .master-selector--title {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-between;
        margin-bottom: 4px;
        .master-selector--title-text {
          margin: 0px 10px 0px 0px;
          color: $ui-dark_blue;
          font-weight: 700;
        }

        .info-circle {
          color: $ui-dark_grey;
          align-self: center;
          padding-top: 4px;
          font-size: 16px;
        }
      }

      .el-input__inner {
        border-radius: 6px;
      }
    }
  }
</style>
