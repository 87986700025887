<template>
  <div :class="['notme-card', flat ? 'notme-card--flat' : 'widget-box']">
    <div class="notme-card-header">
      <img v-if="icon" :src="require(`@/assets/${icon}.png`)" height="50" />
      <p :class="['notme-card-header--title', icon ? 'next-icon' : '']">
        {{ $t(title) }}
      </p>
      <information-popover v-if="description" :description="description">
        <i class="fas fa-info-circle prompt" />
      </information-popover>
    </div>
    <div class="notme-card-body">
      <!-- <div v-if="title" class="widget-header">
      {{ title }}
      <information-popover v-if="description" :description="description">
        <i class="fas fa-info-circle prompt" />
      </information-popover>
      <button v-if="filterAction" class="btn btn-filter" @click="filterAction">
        {{ buttonTitle }}
      </button>
    </div> -->
      <slot />
    </div>
  </div>
</template>

<script>
  import InformationPopover from "@/components/InformationPopover";

  export default {
    name: "Card",
    components: {
      InformationPopover
    },
    props: {
      title: {
        type: String
      },
      icon: {
        type: String,
        default: ""
      },
      description: {
        type: String
      },
      flat: {
        type: Boolean,
        default: false
      }
    }
  };
</script>

<style lang="scss">
  .notme-card {
    position: relative;
    display: flex;
    flex-direction: column;
    min-width: 0;
    word-wrap: break-word;
    background-color: $ui-white;
    background-clip: border-box;
    border-radius: 0.25rem;
    padding: 1.25rem;

    .flat-card {
      background-color: red;
    }

    .notme-card-header {
      display: flex;
      align-items: center;
      padding: 0 0 1.25rem 0;

      .notme-card-header--title {
        font-family: "Campton-Medium";
        color: $ui-text;
        font-size: 22px;
        margin-bottom: 0;
      }

      .next-icon {
        margin-left: 20px;
      }

      .prompt {
        color: $ui-dark_grey;
        margin-left: 10px;
        font-size: 18px;
      }
    }

    .notme-card-body {
      flex: 1 1 auto;
      min-height: 1px;
    }
  }

  .notme-card--flat {
    background-color: $ui-white;
    border: 1px solid $ui-light_grey;
    border-radius: 12px;
  }
</style>
