<template>
  <ui-tag context="dark_blue" circle>
    {{ setupData(answer) }}
  </ui-tag>
</template>

<script>
  import UiTag from "@/components/ui-modules/Tag";
  import moment from "moment";

  export default {
    name: "Dates",
    components: {
      UiTag
    },
    props: {
      answer: {
        type: String,
        default: null
      }
    },
    methods: {
      setupData(data) {
        return moment(data).format("LL");
      }
    }
  };
</script>

<style></style>
