<template>
  <div class="report-tabs" v-if="tabs.length > 1">
    <ul>
      <li
        v-for="(tab, index) in tabs"
        v-bind:key="index"
        :class="activeTab === tab.title && 'active'"
        v-on:click="setActiveTab(tab.title)"
      >
        <span><i :class="['fas', tab.icon]" /> {{ $t(tab.title) }}</span>
      </li>
    </ul>
  </div>
</template>

<script>
  export default {
    name: "ReportTab",
    props: {
      tabs: {
        type: Array
      },
      activeTab: {
        type: String
      },
      setActiveTab: {
        type: Function
      }
    }
  };
</script>

<style lang="scss">
  .report-tabs {
    @media (max-width: 768px) {
      padding-top: 10px;
    }
    ul {
      list-style-type: none;
      margin: 0;
      padding: 0;
      overflow: hidden;
      margin-bottom: 10px;
    }
    li {
      float: left;
      cursor: pointer;
      border-radius: 6px;
      margin-right: 10px;
      span {
        display: block;
        text-align: center;
        padding: 8px 12px;
        line-height: 10px;
        font-family: "Campton-Medium";
        color: $ui-subtitle;
        text-decoration: none;
        i {
          padding-right: 4px;
        }
      }
    }
    .active {
      background-color: $ui-lighter_grey;
      span {
        color: $ui-blue;
      }
    }
  }
</style>
