<template>
  <div class="report-banner">
    <i class="fas fa-tools report-banner-icon"></i>
    <p class="report-banner-text">{{ $t("chat_maintenance_description") }}</p>
  </div>
</template>

<script>
  export default {
    name: "ReportBanner"
  };
</script>

<style lang="scss">
  .report-banner {
    display: flex;
    flex-direction: row;
    background-color: $ui-light_red;
    margin: 0px 0px 10px 0px;
    padding: 16px;
    border-radius: 10px;
    align-items: center;

    .report-banner-icon {
      font-size: 20px;
      margin-right: 16px;
      color: $ui-text;
    }

    .report-banner-text {
      color: $ui-text;
      font-family: "Campton-Medium";
      font-size: 16px;
      text-align: left;
      margin: 0px 0px -4px 0px;
    }
  }
</style>
