<template>
  <div class="content-overlay" @click.prevent="closeMobileMenu" />
</template>

<script>
  export default {
    methods: {
      closeMobileMenu() {
        window.bus.$emit("menu/toggle");
      }
    }
  };
</script>

<style lang="scss">
  .content-overlay {
    position: fixed;
    display: none;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background-color: rgba(0, 0, 0, 0.5);
    z-index: 1499;
    pointer-events: auto;
  }
</style>
