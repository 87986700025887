<template>
  <el-select
    v-model="current_lang_index"
    :placeholder="$t('select')"
    style="display: block"
    @change="handleLangUpdate"
    :size="size"
    :disabled="disabled"
  >
    <el-option
      v-for="(item, index) in available_lang"
      :key="index"
      :label="item.title"
      :value="index"
    />
  </el-select>
</template>

<script>
  import { Translation } from "@/utils/i18n/Translation";

  export default {
    name: "LanguageSelector",
    props: {
      size: {
        type: String,
        default: "large"
      },
      onChange: {
        type: Function,
        default: null
      },
      locales: {
        type: Array,
        default: null
      },
      disabled: {
        type: Boolean,
        default: false
      }
    },
    data() {
      return {
        available_lang: [],
        current_lang_index: 0
      };
    },
    created() {
      // If the locales prop is provided and has a length greater than 0, filter the supportedLanguagesHuman array
      // If not, use the entire supportedLanguagesHuman array
      this.available_lang = (
        this.locales && this.locales.length > 0
          ? Translation.supportedLanguagesHuman.filter((x) =>
              this.locales.includes(x.locale)
            )
          : Translation.supportedLanguagesHuman
      ).map((x) => {
        return { title: x.name, locale: x.locale };
      });

      const index = this.available_lang.findIndex(
        (x) => x.locale === Translation.currentLanguage
      );

      // If the index is found (not equal to -1), set current_lang_index to the found index
      // Otherwise, set current_lang_index to 0 as a fallback
      this.current_lang_index = index !== -1 ? index : 0;
    },
    methods: {
      handleLangUpdate(command) {
        this.current_lang_index = command;
        if (this.onChange) {
          this.onChange(this.available_lang[command].locale);
        } else {
          Translation.changeLanguage(Translation.supportedLanguages[command]);
          this.moment.locale(Translation.supportedLanguages[command]);
        }
      }
    }
  };
</script>

<style></style>
