<template>
  <div class="places">
    <dashboard-header
      title="pages.dashboard.places"
      description="dashboard_sections.places.description"
      descriptionLink="https://help.not-me.com/places-page"
    >
      <dropdown-button
        :content="sort_filters"
        :current_index="current_sort_filter_index"
        :on-click="handle_sort_command"
        title="sort_by"
        element_title="title"
      />
      <button-arrow
        :value="show_filters"
        :on-click="showDropdown"
        title="filters"
      />
    </dashboard-header>
    <transition name="slide">
      <div v-if="show_filters" class="row row-filters">
        <div class="col-lg-4 col-md-6 col-sm-12">
          <a v-if="value !== ''" class="clear-filter" @click="clearDates">{{
            $t("clear")
          }}</a>
          <h6>{{ $t("date_range") }}</h6>
          <el-select
            v-model="value"
            filterable
            :placeholder="$t('select')"
            style="display: block; margin-bottom: 10px"
            @change="handle_date_range_command"
          >
            <el-option
              v-for="(item, index) in date_range"
              :key="index"
              :label="$t(item.value, { x: item.range })"
              :value="index"
            />
          </el-select>
        </div>
      </div>
    </transition>

    <div v-if="!this.isLoading">
      <div
        v-if="this.places !== null && this.places.length > 0"
        class="table-places"
      >
        <place-cell
          v-for="(item, index) in places"
          :key="index"
          :place="item"
          :max-value="query.max_value"
        />
        <pagination-component
          :current_page="this.current_page"
          :limit="Number(this.query.limit)"
          :total="total"
          :handle-current-change="handleCurrentChange"
          color="#c1473a"
        />
      </div>
      <no-data-content v-else title="pages.dashboard.places" />
    </div>
    <div v-loading.lock="isLoading" v-else class="loader-middle" />
  </div>
</template>

<script>
  import DashboardHeader from "@/components/DashboardHeader";
  import PaginationComponent from "@/components/PaginationComponent";
  import NoDataContent from "@/components/NoDataContent";
  import PlaceCell from "./components/PlaceCell";
  import ButtonArrow from "@/components/ui-modules/Buttons/ButtonArrow";
  import DropdownButton from "@/components/DropdownButton";
  import url_utils from "@/utils/url_utils";
  import permissions_utils from "@/utils/permissions_utils";
  import moment from "moment";
  import dashboardApi from "@/api/dashboardApi";

  export default {
    name: "Places",
    components: {
      DashboardHeader,
      PaginationComponent,
      NoDataContent,
      PlaceCell,
      ButtonArrow,
      DropdownButton
    },
    data() {
      return {
        places: null,
        isLoading: false,
        current_page: 0,
        current_sort_filter_index: 0,
        current_date_range_index: 0,
        value: "",
        total: null,
        sort_filters: [
          {
            title: "reports_most_least",
            sort_by: "nb_report",
            order_by: "desc"
          },
          {
            title: "reports_least_most",
            sort_by: "nb_report",
            order_by: "asc"
          },
          {
            title: "alphabetical_az",
            sort_by: "place",
            order_by: "asc"
          },
          {
            title: "alphabetical_za",
            sort_by: "place",
            order_by: "desc"
          }
        ],
        date_range: [
          {
            value: "last_week",
            range: 1,
            type: "week"
          },
          {
            value: "last_x_weeks",
            range: 2,
            type: "week"
          },
          {
            value: "last_month",
            range: 1,
            type: "months"
          },
          {
            value: "last_x_months",
            range: 2,
            type: "months"
          },
          {
            value: "last_x_months",
            range: 3,
            type: "months"
          },
          {
            value: "last_x_months",
            range: 6,
            type: "months"
          },
          {
            value: "last_x_months",
            range: 12,
            type: "months"
          },
          {
            value: "all_time"
          }
        ],
        show_filters: false,
        query: {
          offset: 0,
          limit: 10,
          sort_by: "nb_report",
          order_by: "desc",
          start_date: null,
          max_value: 0
        },
        initial_query: {
          offset: 0,
          limit: 10,
          sort_by: "nb_report",
          order_by: "desc",
          start_date: null,
          max_value: 0
        }
      };
    },
    computed: {
      selected_company() {
        return this.$store.getters.getSelectedCompanies();
      }
    },
    watch: {
      $route(to, from) {
        if (
          from.name === to.name &&
          JSON.stringify(to.query) === JSON.stringify({})
        ) {
          this.current_page = 0;
          this.value = "";
          this.updateQuery(this.initial_query);
        } else {
          this.query = to.query;
          this.current_page = to.query.offset / to.query.limit + 1;
          this.getPlaces();
        }
      },
      selected_company(newValue) {
        let newQuery = [];
        if (newValue) {
          newQuery = [...newValue];
        } else {
          newQuery = null;
        }
        this.updateQuery({
          company_ids: newQuery
        });
      }
    },
    created() {
      this.initRoute();
    },
    methods: {
      initRoute() {
        var queryTmp = this.$route.query;

        Object.keys(queryTmp).forEach(function (el) {
          if (queryTmp[el] && !isNaN(queryTmp[el])) {
            if (!Array.isArray(queryTmp[el])) {
              queryTmp[el] = parseInt(queryTmp[el]);
            }
          } else if (isNaN(queryTmp[el])) {
            queryTmp[el] = null;
          }
        });

        queryTmp = {
          ...queryTmp,
          ...url_utils.urlParamsToObject(window.location.search)
        };

        const selectedCompanies = this.$store.getters.getSelectedCompanies();
        if (selectedCompanies) {
          queryTmp.company_ids = [...selectedCompanies];
        } else {
          queryTmp.company_ids = null;
        }

        if (
          queryTmp.offset !== undefined &&
          queryTmp.offset !== null &&
          queryTmp !== this.query
        ) {
          this.query = queryTmp;
          this.current_page = queryTmp.offset / queryTmp.limit + 1;
        } else if (queryTmp !== this.query) {
          this.query = {
            ...this.query,
            ...queryTmp
          };
          this.current_page = this.query.offset / this.query.limit + 1;
        }

        this.$router.replace({
          path: "places",
          query: this.query
        });
      },
      getPlaces() {
        this.isLoading = true;
        dashboardApi
          .getPlaces(this.query)
          .then((response) => {
            if (response.status === 200) {
              this.places = response.data.places;
              this.total = response.data.page.total;
              if (this.query.offset == 0) {
                this.updateQuery({
                  max_value: this.places[0].nb_reports
                });
              }
              this.isLoading = false;
            }
          })
          .catch((_) => {
            this.isLoading = false;
          });
      },
      updateQuery(query) {
        this.query = {
          ...this.query,
          ...query
        };
        this.$router
          .push({
            path: "places",
            query: this.query
          })
          .catch(() => {});
      },
      showDropdown() {
        this.show_filters = !this.show_filters;
      },
      handle_sort_command(command) {
        this.current_page = 0;
        this.current_sort_filter_index = command;
        this.updateQuery({
          offset: 0,
          sort_by: this.sort_filters[this.current_sort_filter_index].sort_by,
          order_by: this.sort_filters[this.current_sort_filter_index].order_by
        });
      },
      handle_date_range_command(command) {
        this.current_page = 0;
        this.current_date_range_index = command;
        if (this.date_range[command].range) {
          this.updateQuery({
            offset: 0,
            start_date: moment()
              .subtract(
                this.date_range[command].range,
                this.date_range[command].type
              )
              .format("YYYY-MM-DD")
          });
        } else {
          this.updateQuery({
            offset: 0,
            start_date: null
          });
        }
      },
      handleCurrentChange(val) {
        this.current_page = val;
        let offset = (val - 1) * Number(this.query.limit);
        this.updateQuery({ offset });
      },
      clearDates() {
        this.current_page = 0;
        this.value = "";
        this.updateQuery({
          offset: 0,
          start_date: null
        });
      },
      can(name) {
        return permissions_utils.can(name);
      }
    }
  };
</script>

<style lang="scss">
  .slide-enter-active,
  .slide-leave-active {
    transition: all 0.3s ease-in;
    max-height: 230px;
  }
  .slide-enter,
  .slide-leave-to {
    transform: translateY(-10px);
    opacity: 0;
    max-height: 0px;
  }

  .places {
    .row-filters {
      text-align: left;
      margin-bottom: 20px;

      .clear-filter {
        float: right;
        font-size: 12px;
        font-family: "Campton-Medium";
        padding-top: 2px;
        color: $ui-red;
        text-decoration: none;
        cursor: pointer;
        &:hover {
          color: $ui-text;
        }
      }

      h6 {
        text-transform: uppercase;
        letter-spacing: 0.75px;
        color: #999;
        display: block;
        margin: 0 0 8px;
        font-size: 12px;
        font-family: "Campton-Medium";
        line-height: 1.5em;
      }
    }

    .loader-middle {
      top: 200px;
    }
  }
</style>
