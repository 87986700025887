<template>
  <div
    class="report-file"
    v-on:mouseover="mouseOver"
    v-on:mouseleave="mouseLeave"
    v-on:click="$emit('preview-file')"
  >
    <div class="report-file--img-container">
      <img
        v-if="is_image"
        class="report-file-img"
        :src="report_file.file"
        :key="report_file.id"
      />
      <div v-else class="report-file-img report-file-doc">
        <i
          v-if="report_file.is_external_link"
          class="fab fa-google-drive fa-fw"
        ></i>
        <i v-else class="fas fa-file fa-fw"></i>
      </div>
    </div>
    <div class="report-file--content-container">
      <h5 class="report-file-name">
        {{
          report_file.title
            ? report_file.title
            : $t("file_type." + file_type) + " - #" + report_file.id
        }}
      </h5>
      <p class="report-file-text" v-if="!is_evidence">
        <i class="fas fa-user fa-fw"></i>
        {{ userFormatter(report_file.uploader) }}
      </p>
      <p class="report-file-text">
        <i class="fas fa-calendar-alt fa-fw"></i>
        {{ beautifyDate(report_file.created_at) }}
      </p>
    </div>
    <transition name="fade">
      <div v-show="showDownloadButton" class="download-btn-container">
        <ui-button
          class="download-btn"
          smallSquare
          hoverShadow
          outline
          @click.stop="downloadAction"
        >
          <i class="fas fa-cloud-download-alt" aria-hidden="true" />
        </ui-button>
        <ui-button
          v-if="canDeleteFile && !isTimeline"
          class="delete-btn"
          context="red"
          smallSquare
          hoverShadow
          outline
          @click.stop="deleteDocument(report_file.id)"
        >
          <i class="fas fa-trash" aria-hidden="true" />
        </ui-button>
      </div>
    </transition>
  </div>
</template>

<script>
  import UiButton from "@/components/ui-modules/Buttons/Button";
  import moment from "moment";
  import url_utils from "@/utils/url_utils";
  import report_utils from "@/utils/report_utils";

  export default {
    name: "ReportFile",
    components: {
      UiButton
    },
    props: {
      deleteDocument: {
        type: Function
      },
      report_file: {
        type: Object
      },
      report_status_id: {
        type: Number
      },
      is_evidence: {
        type: Boolean
      },
      isTimeline: {
        type: Boolean,
        default: false
      }
    },
    data() {
      return {
        showDownloadButton: false,
        is_image: false,
        file_type: ""
      };
    },
    computed: {
      canDeleteFile() {
        return (
          this.report_status_id < 6 &&
          this.report_file.uploader?.id ===
            JSON.parse(this.$store.state.auth.user)?.id
        );
      }
    },
    created() {
      this.file_type = this.getFileType(this.report_file.file);
      this.is_image = this.isImage(this.report_file.file);
    },
    methods: {
      beautifyDate(date) {
        return moment(date).format("lll");
      },
      mouseOver() {
        this.showDownloadButton = true;
      },
      mouseLeave() {
        this.showDownloadButton = false;
      },
      userFormatter(uploader) {
        if (!uploader) {
          return this.$t("anonymous");
        }
        return uploader.firstname + " " + uploader.lastname;
      },
      downloadAction() {
        window.open(this.report_file.file, "_blank");
      },
      getFileType(file_url) {
        let file_type = report_utils.getFileTypeFromURL(file_url);
        return report_utils.getFileFormat(file_type);
      },
      isImage(file_url) {
        let file_type = report_utils.getFileTypeFromURL(file_url);
        return report_utils.isImage(file_type);
      }
    }
  };
</script>

<style lang="scss" scope>
  .report-file {
    display: flex;
    flex-direction: row;
    margin: 0px 0px 10px 0px;
    overflow: hidden;
    color: $ui-text;
    vertical-align: middle;
    padding: 5px;
    border: 1px solid $ui-light_grey;
    border-radius: 6px;
    min-height: 65px;

    &:hover {
      cursor: pointer;
    }

    .report-file--img-container {
      width: 70px;
      margin-right: 10px;
    }

    .report-file--content-container {
      display: flex;
      flex-direction: column;
      justify-content: center;
    }

    .report-file-img {
      width: 100%;
      object-fit: cover;
      object-position: 50% 50%;
      height: 100%;
      max-height: 70px;
      border-radius: 4px;
    }

    .report-file-doc {
      background-color: $ui-blue;
      color: white;
      text-align: center;
      line-height: 70px;
      font-size: 26px;
    }

    .report-file-name {
      font-size: 16px;
      font-family: "Campton-Bold";
      margin-bottom: 4px;
    }

    .report-file-text {
      font-size: 14px;
      font-weight: 500;
      margin: 0;
      color: $ui-subtitle;
    }

    .download-btn-container {
      position: absolute;
      z-index: 2;
      right: 0;
      margin-top: 2px;
      display: inline-block;
      text-align: center;
      vertical-align: bottom;
      margin-right: 22px;
      transition: all 0.2s ease-in;
      @media (max-width: 991px) {
        margin-right: 7px;
      }
      .download-btn,
      .delete-btn {
        height: 28px;
        width: 28px;
        padding: 6px;
        background-color: $ui-white;
        text-align: center;
        float: right;
        font-size: 12px;
      }
      .delete-btn {
        margin-right: 5px;
      }
    }
  }

  .report-file + .report-file {
    margin-top: 10px;
  }
</style>
