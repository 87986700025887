<template>
  <ul class="status-search-list">
    <ui-loading v-if="loading" />
    <user-category-cell
      v-else
      v-for="(user, index) in searchResults"
      :key="index"
      :user="user"
      v-on:select="$emit('select', user)"
    />
  </ul>
</template>

<script>
  import UiLoading from "@/components/ui-modules/Loading";
  import UserCategoryCell from "../CategoryCell/UserCategoryCell";
  import companyApi from "@/api/companyApi";

  export default {
    name: "AssigneeSearchList",
    components: {
      UiLoading,
      UserCategoryCell
    },
    props: {
      categorySelected: {
        type: Object
      },
      searchQuery: {
        type: String,
        default: ""
      }
    },
    data() {
      return {
        loading: false,
        data: []
      };
    },
    computed: {
      searchResults() {
        return this.data.filter((element) => {
          return (
            element["firstname"]
              .toLowerCase()
              .includes(this.searchQuery.toLowerCase()) ||
            element["lastname"]
              .toLowerCase()
              .includes(this.searchQuery.toLowerCase()) ||
            (element["firstname"] + " " + element["lastname"])
              .toLowerCase()
              .includes(this.searchQuery.toLowerCase())
          );
        });
      }
    },
    async created() {
      this.data = await this.fetchData();
    },
    methods: {
      async fetchData() {
        this.loading = true;
        try {
          const response = await companyApi.getCompanyDashboardUsers();
          if (response.status === 200) {
            this.loading = false;
            return response.data.users;
          }
        } catch (error) {
          this.loading = false;
          return [];
        }
      },
      searchData() {}
    }
  };
</script>

<style></style>
