<template>
  <div class="file-preview-modal">
    <custom-modal
      :title="$t('document_id', { id: selectedFile.id })"
      @close="closeModal"
    >
      <img
        :src="selectedFile.file"
        class="img-responsive img-fluid"
        style="width: 100%"
      />
      <div class="modal-info">
        <ui-button
          class="modal-open-file"
          smallSquare
          hoverShadow
          outline
          @click="downloadAction()"
        >
          <i class="fas fa-cloud-download-alt" aria-hidden="true" />
        </ui-button>
        <ui-button
          v-if="canDeleteFile && !isTimeline"
          class="modal-delete-file"
          smallSquare
          hoverShadow
          context="red"
          outline
          @click="deleteDocument(selectedFile.id)"
        >
          <i class="fas fa-trash" aria-hidden="true" />
        </ui-button>
        <p v-if="!is_evidence && selectedFile.uploader" class="modal-text">
          <i class="fas fa-user fa-fw"></i>
          {{ userFormatter(selectedFile.uploader) }}
        </p>
        <p class="modal-text">
          <i class="fas fa-calendar-alt fa-fw"></i>
          {{ beautifyDate(selectedFile.created_at) }}
        </p>
      </div>
    </custom-modal>
  </div>
</template>

<script>
  import CustomModal from "@/components/modal/CustomModal";
  import UiButton from "@/components/ui-modules/Buttons/Button";

  export default {
    name: "FilePreviewModal",
    components: {
      CustomModal,
      UiButton
    },
    props: {
      selectedFile: {
        type: Object
      },
      report_status_id: {
        type: Number
      },
      downloadAction: {
        type: Function
      },
      deleteDocument: {
        type: Function
      },
      closeModal: {
        type: Function
      },
      is_evidence: {
        type: Boolean
      },
      isTimeline: {
        type: Boolean,
        default: false
      }
    },
    data() {
      return {};
    },
    computed: {
      canDeleteFile() {
        return (
          this.report_status_id < 6 &&
          this.selectedFile.uploader?.id ===
            JSON.parse(this.$store.state.auth.user)?.id
        );
      }
    },
    methods: {
      beautifyDate(date) {
        return this.moment(date).format("lll");
      },
      userFormatter(uploader) {
        return uploader.firstname + " " + uploader.lastname;
      }
    }
  };
</script>

<style lang="scss">
  .file-preview-modal {
    .modal-open-file,
    .modal-delete-file {
      text-align: center;
      float: right;
      font-size: 12px;
    }
    .modal-delete-file {
      margin-right: 5px;
    }
    .modal-text {
      margin-top: 8px;
      margin-bottom: 0px;
      color: $ui-text;
      font-family: "Campton-Medium";
      font-size: 16px;
    }
    .custom-modal-header,
    .modal-info {
      text-align: left;
    }
  }
</style>
