<template>
  <div class="authentication-layout">
    <div class="main row">
      <div class="authentication-left-content col-lg-5 col-md-6 col-12">
        <div class="authentication-left">
          <div class="authentication-header">
            <img
              src="@/assets/logo-notme.png"
              style="margin-bottom: 40px"
              height="35"
              alt=""
              class="img-center"
            />
            <h1 class="authentication-header-title">{{ title }}</h1>
            <h2 class="authentication-header-subtitle">{{ subtitle }}</h2>
          </div>
          <slot />
        </div>
        <footer class="bottom-banner">
          <language-selector size="mini" />
          <ul class="bottom-banner--links">
            <li
              v-for="(item, index) in bottom_links"
              :key="index"
              class="bottom-banner--link"
            >
              <a
                class="bottom-banner--link-text"
                target="_blank"
                :href="item.link"
                >{{ $t(`authentication_links.${item.title}`) }}</a
              >
            </li>
          </ul>
        </footer>
      </div>
      <div
        class="authentication-right-content col-lg-7 col-md-6 d-lg-flex d-none d-md-block"
      >
        <img
          class="img-fluid"
          style="object-fit: cover; height: 100%; width: 100%"
          :src="require(`@/assets/${authentication_image}.png`)"
        />
      </div>
    </div>
  </div>
</template>

<script>
  import LanguageSelector from "@/components/LanguageSelector";

  export default {
    name: "AuthenticationLayout",
    components: {
      LanguageSelector
    },
    props: {
      authentication_image: {
        type: String,
        default: "login-img"
      },
      title: {
        type: String
      },
      subtitle: {
        type: String
      }
    },
    data() {
      return {
        bottom_links: [
          { title: "help", link: "https://help.not-me.com/" },
          { title: "privacy", link: "https://not-me.com/privacy-policy/" },
          { title: "terms", link: "https://not-me.com/terms-of-use/" }
        ]
      };
    }
  };
</script>

<style lang="scss">
  .authentication-layout {
    height: 100vh;
    margin: 0;

    .main {
      margin: 0;
      height: 100%;

      a {
        font-family: "Campton-Bold";
        color: $ui-dark_red;

        &:hover {
          font-family: "Campton-Medium";
          text-decoration: none;
        }
      }

      .authentication-left-content {
        text-align: left;
        background-color: white;
        padding: 0;
        display: flex;
        flex-direction: column;

        .authentication-left {
          flex-grow: 1;
          padding: 25px 100px;
          @media (max-width: 1140px) {
            padding: 25px 50px;
          }

          .authentication-header {
            .authentication-header-title {
              font-size: 36px;
            }

            .authentication-header-subtitle {
              color: $ui-blue;
              font-size: 18px;
            }
            margin-bottom: 30px;
          }
        }
        .bottom-banner {
          border-top: 1px $ui-light_grey solid;
          font-size: 12px;
          line-height: 16px;
          padding: 5px 50px;
          display: flex;
          flex-wrap: wrap;
          justify-content: space-between;

          @media (max-width: 576px) {
            flex-direction: column;
          }

          .el-input__inner {
            border: none;
            color: $ui-subtitle;
            font-family: "Campton-Bold";
            padding: 0px;
          }

          .bottom-banner--links {
            list-style: none;
            margin: 6px -16px 0px -16px;
            padding: 0;
            .bottom-banner--link {
              margin: 0;
              display: inline-block;
              @media (max-width: 576px) {
                display: block;
              }
              .bottom-banner--link-text {
                display: inline-block;
                color: $ui-subtitle;
                margin-top: -6px;
                padding: 6px 16px;
                cursor: pointer;
                transition: all 0.25s;
                &:hover {
                  color: $ui-text;
                }
              }
            }
          }
        }
      }

      .authentication-right-content {
        position: fixed;
        right: 0;
        height: 100%;
        padding: 0;
        overflow: hidden;
        margin: 0 auto;
        display: flex;
        align-items: center;
        justify-content: center;
        background-color: white;
      }
    }
  }
</style>
