<template>
  <div class="report-value">
    <p class="report-value-title">{{ value.value }}</p>
  </div>
</template>

<script>
  export default {
    name: "ReportValue",
    props: {
      value: {
        type: Object
      }
    }
  };
</script>

<style lang="scss">
  @import "./styles.scss";
</style>
