<template>
  <text-value :value="new_value ? new_value : value" />
</template>

<script>
  import report_utils from "@/utils/report_utils";

  import TextValue from "../TypeQueryValue/TextValue";

  export default {
    name: "ScoreQueryValue",
    components: {
      TextValue
    },
    props: {
      value: {
        type: Object
      }
    },
    data() {
      return {
        new_value: null
      };
    },
    created() {
      if (this.value.value === undefined) {
        let id = parseInt(this.value.id);
        this.new_value = { id: id };
        this.new_value.value = this.$t(report_utils.getScoreTitle(id));
        this.new_value.color = report_utils.getScoreColor(id);
      }
    }
  };
</script>

<style></style>
