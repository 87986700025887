<template>
  <div class="col-sm-6 col-xl-3 top-widget">
    <information-popover :description="description" :isMaxHeight="true">
      <div :style="color_style" class="widget-box top-widget-content">
        <router-link :to="{ path: to, query: query }">
          <div class="widget-body">
            <div
              class="widget-icon"
              :style="{ '--color': color, '--color-bg': color + '1f' }"
            >
              <i
                :class="['fa widget-icon-img', data_icon]"
                aria-hidden="true"
              />
            </div>
            <div style="flex: 1 1 auto">
              <h2 class="data-nb-style">{{ data_nb }}</h2>
              <h5 class="data-title-style">{{ data_title }}</h5>
            </div>
          </div>
        </router-link>
      </div>
    </information-popover>
  </div>
</template>

<script>
  import InformationPopover from "@/components/InformationPopover";

  export default {
    name: "TopWidget",
    components: {
      InformationPopover
    },
    props: {
      color: {
        type: String
      },
      data_nb: {
        type: String,
        required: true
      },
      data_title: {
        type: String,
        required: true
      },
      data_icon: {
        type: String,
        required: true
      },
      to: {
        type: String
      },
      query: {
        type: Object,
        default: null
      },
      description: {
        type: String
      }
    },
    computed: {
      color_style() {
        // return "border-left-color: " + this.color;
      }
    }
  };
</script>

<style lang="scss" scoped>
  .top-widget-content {
    // border-left-width: 1.25rem;
    // border-left-style: solid;
    height: 100%;
    transition: all 0.1s ease-in-out;
  }

  .top-widget-content.widget-box {
    box-shadow: none;
  }

  a {
    color: $ui-text;
    &:hover {
      text-decoration: none;
    }
  }

  .img-top {
    margin-left: -30px;
    margin-top: auto;
    margin-bottom: auto;
  }

  .widget-body {
    padding: 1rem;
    text-align: left;
    display: flex;
    flex-direction: row;

    .left-icon {
      padding-left: 20px;
      padding-right: 20px;
      align-self: center;
    }

    .no-padding {
      // max-height: 50px;
      // background-color: #f40a12;
      padding: 0 10px 0px 0px;
    }
  }

  .widget-inner-left {
    padding-left: 10px;
    text-align: left;
  }

  .widget-icon {
    font-family: "Avenir";
    display: inline-block;
    font-size: 40px;
    line-height: 40px;
    background-color: var(--color-bg);
    // background-color: rgba($ui-red, 0.1);
    // background-color: rgba( #c6538c, 0.1);
    // background: rgba(var(--color), 0.1);
    // @include background-opacity(var(--color), 0.1);
    // background-color: #c1473a;    // background-color: var(--color);
    color: white;
    width: 50px;
    height: 50px;
    text-align: center;
    border-radius: 6px;
    margin-right: 1rem;
    vertical-align: bottom;
    flex: 0 0 50px;

    .widget-icon-img {
      font-size: 20px;
      text-align: center;
      display: inline;
      line-height: normal;
      vertical-align: baseline;
      top: 25%;
      color: var(--color);
    }

    // vertical-align: middle;
    // margin: 0 auto;
    // right: 0px;
    // float: right;
    // background-color: #c1473a;
    // height: 50px;
    // width: 50px;
    // border-radius: 25px;
    // background-image: url('../../../../static/icons-widget/todo-list.png');
    // background-repeat: no-repeat;
    // background-size: 30px;
    // background-position: center;
  }
  .widget-logo {
    vertical-align: middle;
    margin: 0 auto;
    right: 0px;
    float: right;
    max-height: 50px;
  }

  .data-nb-style {
    color: $ui-text;
    font-family: "Campton-Medium";
    margin-bottom: 0px;
    font-size: 22px;
  }

  .data-title-style {
    font-family: "Campton-Medium" !important;
    color: $ui-subtitle;
    font-weight: 500;
    margin-bottom: 0px;
    font-size: 15px;
  }
</style>
