<template>
  <div id="wrapper" :class="wrapperClass">
    <div v-if="loadHelper">
      <Navbar />
      <Sidebar />
      <ContentOverlay />
      <router-view />
    </div>
    <div v-loading.lock="!loadHelper" style="top: 200px" />
  </div>
</template>

<script>
  import Navbar from "./Navbar";
  import Sidebar from "./Sidebar";
  import ContentOverlay from "./ContentOverlay.vue";
  export default {
    components: {
      Navbar,
      Sidebar,
      ContentOverlay
    },
    data() {
      return {
        isOpenMobileMenu: false
      };
    },
    computed: {
      wrapperClass() {
        return {
          toggled: this.isOpenMobileMenu === true
        };
      },
      /** This helper is made to wait for store setup
       *  -> Wait for permissions
       *  -> Wait for e2e set
       *  -> Wait for language setup
       */
      loadHelper() {
        return (
          this.$store.state.auth.role.permissions &&
          (this.$store.getters.isE2Eenabled()
            ? this.$store.state.device.eThree !== null
            : true) &&
          this.$store.state.language === this.$i18n.locale
        );
      }
    },
    created() {
      window.bus.$on("menu/toggle", () => {
        window.setTimeout(() => {
          this.isOpenMobileMenu = !this.isOpenMobileMenu;
        }, 200);
      });
      window.bus.$on("menu/closeMobileMenu", () => {
        this.isOpenMobileMenu = false;
      });
    }
  };
</script>
<style lang="scss">
  @import "@/styles/layout.scss";

  @media (min-width: 768px) {
    .menu-toggle-btn {
      display: none !important;
    }

    .navbar-notme {
      display: none !important;
    }

    #wrapper {
      padding-left: calc(
        #{$sidebar-width-expanded} + #{$layout-padding-size}
      ) !important;
      padding-top: 15px;
      padding-right: $layout-padding-size;
    }
  }

  @media (max-width: 767px) {
    .main-content {
      margin: 0 0 0 70px !important;
    }

    .main-content__top {
      width: 100% !important;
      margin-left: 0 !important;
    }

    .main-content__title {
      margin: $layout-padding-size 0 $layout-padding-size 0px !important;
    }

    #wrapper {
      padding-left: $layout-padding-size !important;
      padding-right: $layout-padding-size !important;
    }

    #wrapper.toggled .content-overlay {
      display: block !important;
    }

    .sidebar-container {
      width: 0 !important;
      left: -$sidebar-width-expanded !important;
    }

    #wrapper.toggled .sidebar-container {
      width: $sidebar-width-expanded !important;
      left: $sidebar-width-expanded !important;
      z-index: 1500 !important;
    }
  }

  @media (max-width: 768px) {
    .main-content__title {
      margin: $layout-padding-size-mobile 0 $layout-padding-size-mobile 0px !important;
    }

    #wrapper {
      padding-left: $layout-padding-size-mobile !important;
      padding-right: $layout-padding-size-mobile !important;
    }
  }
</style>
