<template>
  <div v-if="report && !is_loading" class="report">
    <report-header
      :report="report"
      v-on:update-report-status="updateReportStatus"
    >
      <div class="report-header__actions-container">
        <report-dropdown-format
          v-if="
            isFlagEnabled('PDF_REPORT_FFLAG') && can('read assigned report')
          "
          :loading="is_loading_report_generate"
          :on-click="exportReport"
        />
        <ui-button
          v-if="can('update assigned report') && !isReportResolved()"
          context="green"
          v-on:click="openReportResolutionModal"
        >
          {{ $t("resolve") }}
        </ui-button>
        <el-tooltip
          v-if="
            can('update assigned report') &&
            isReportStatusUpdatable(report.status_id)
          "
          effect="dark"
          :content="$t('mark_as_unread')"
          placement="bottom"
        >
          <ui-button
            class="report-btn-header"
            full
            hoverShadow
            context="lighter_grey"
            @click="markAsUnread"
          >
            <i class="fas fa-eye-slash" />
            <media :query="{ maxWidth: 575 }">
              {{ $t("mark_as_unread") }}
            </media>
          </ui-button>
        </el-tooltip>
      </div>
      <ui-button
        v-if="
          can('update assigned report') &&
          !isReportStatusUpdatable(report.status_id)
        "
        clickable
        :disabled="!isReportSaveable()"
        context="red"
        className="top-button"
        v-on:click="saveReport"
      >
        {{ $t("save") }}
      </ui-button>
      <ui-button
        v-if="
          can('update assigned report') &&
          !isReportStatusUpdatable(report.status_id)
        "
        clickable
        :disabled="!isReportSubmittable()"
        context="dark_blue"
        className="top-button"
        @click="submitReport"
      >
        {{ $t("submit") }}
      </ui-button>
    </report-header>
    <div class="container">
      <div class="row">
        <div class="col-md-12 col-lg-9" style="padding: 0px">
          <report-banner v-if="chat_maintenance" />
          <report-tab
            :tabs="tabs"
            :activeTab="activeTab"
            :setActiveTab="setActiveTab"
          />
          <div v-if="activeTab === 'answers'" class="report-content-container">
            <div class="row">
              <div class="col-md-6 report-column">
                <report-step
                  v-for="(report_step, index) of getReportStepsFor([3, 4, 1])"
                  :key="index"
                  :info="report_step.info"
                  :report_step="report_step.step"
                />
              </div>
              <div class="col-md-6 report-column">
                <report-step
                  v-for="(report_step, index) of getReportStepsFor([2, 5])"
                  :key="index"
                  :info="report_step.info"
                  :report_step="report_step.step"
                />
              </div>
            </div>
          </div>
          <div v-else-if="activeTab === 'timeline'">
            <report-timeline
              :report="report"
              :itemToRefresh="itemToRefresh"
              v-on:clear-item-to-refresh="clearItemToRefresh"
            />
          </div>
          <report-notes
            v-on:save-notes="saveNotes"
            :report_id="this.report.id"
          />
          <!-- End chunk -->
        </div>
        <report-sidebar
          ref="sidebar"
          :report="report"
          :upload_progress="upload_progress"
          :isUrlUploading="isUrlUploading"
          v-on:save-assignees="saveAssignees"
          v-on:save-company="saveCompany"
          v-on:upload-document="uploadDocument"
          v-on:delete-document="deleteDocument"
          v-on:save-tags="saveTags"
          v-on:open-resolution="openReportResolutionModal"
        />
      </div>
    </div>
    <report-chat
      :canLoadMessages="startLoadMessages"
      :isShow="show_message"
      :report_id="report.id"
      :isUserAssigned="isCurrentUserAssigned"
      :e2e_enabled="report.e2e_enabled"
      :isReporterUser="isReporterUser"
      :isChatError="isChatError"
      :isLoading="is_loading_chat"
      v-on:assign-current-user="assignCurrentUser"
      @show="showMessageBox"
    />
    <report-resolution-modal
      :modalVisible="showModal"
      :isLoading="is_loading_resolution"
      @close="closeReportResolutionModal"
      v-on:save-resolution="saveResolution"
      :currentResolution="report.resolution"
    />
  </div>
  <no-data-content
    v-else-if="report === null && !is_loading"
    :show_no="false"
    title="report_not_found"
  />
  <div v-else v-loading.lock="is_loading" class="loader-middle" />
</template>

<script>
  import moment from "moment";
  import api from "@/api/api";
  import reportApi from "@/api/reportApi";
  import talesApi from "@/api/talesApi";
  import flashApi from "@/api/flashApi";
  import permissions_utils from "@/utils/permissions_utils";
  import url_utils from "@/utils/url_utils";
  import report_utils from "@/utils/report_utils";
  import {
    getFirestore,
    collection,
    Timestamp,
    query,
    where,
    orderBy,
    getDocs
  } from "firebase/firestore";

  import Card from "@/components/ui-modules/Card";
  import UiButton from "@/components/ui-modules/Buttons/Button";
  import Media from "vue-media";
  import NoDataContent from "@/components/NoDataContent";

  import ReportHeader from "./components/ReportHeader";
  import ReportSidebar from "./components/ReportSidebar";
  import ReportStep from "./components/ReportStep";
  import ReportNotes from "./components/ReportNotes";
  import ReportChat from "./components/ReportChat";
  import ReportBanner from "./components/ReportBanner";
  import ReportTab from "./components/ReportTab";
  import ReportTimeline from "./components/ReportTimeline";
  import ReportResolutionModal from "./components/ResolutionModal/index.vue";
  import ReportDropdownFormat from "./components/ReportDropdownFormat";

  export default {
    name: "Report",
    components: {
      Card,
      Media,
      NoDataContent,
      UiButton,
      ReportHeader,
      ReportSidebar,
      ReportStep,
      ReportNotes,
      ReportChat,
      ReportBanner,
      ReportTab,
      ReportTimeline,
      ReportResolutionModal,
      ReportDropdownFormat
    },
    data() {
      return {
        report: null,
        itemToRefresh: null,
        is_loading: true,
        is_loading_resolution: false,
        is_loading_chat: false,
        is_loading_report_generate: false,
        assignee_visible: false,
        show_message: false,
        current_user: JSON.parse(this.$store.state.auth.user),
        upload_progress: 0,
        isUrlUploading: false,
        chat_maintenance: false,
        startLoadMessages: false,
        tabs: [{ title: "answers", icon: "fa-clipboard" }],
        activeTab: "answers",
        showModal: false,
        isChatError: false
      };
    },
    created() {
      if (this.can("view report timeline")) {
        this.tabs.push({ title: "timeline", icon: "fa-calendar" });
      }

      this.getReport();
    },
    computed: {
      isCurrentUserAssigned() {
        return this.report.assignees.some((x) => x.id === this.current_user.id);
      },
      isReporterUser() {
        return !this.report.user || this.report.user?.role === "user";
      }
    },
    beforeRouteEnter(to, from, next) {
      next((vm) => {
        vm._backRoute = from.path;
      });
    },
    methods: {
      getReport() {
        const id = this.$router.currentRoute.params.id;
        reportApi
          .getReport(id)
          .then(async (response) => {
            if (response.status === 200) {
              this.report = response.data;
              this.checkFirebaseStatus();
              if (response.data.status_id === 3) {
                this.updateReportStatus(4);
              } else if (response.data.status_id > 3) {
                const [reportResolution, accusedResolution] = await Promise.all(
                  [
                    this.getLatestTimelineElement("report"),
                    this.getLatestTimelineElement("accused")
                  ]
                );

                this.report.resolution = {
                  conclusionsData: reportResolution.outcomes,
                  conclusionDetailsData: reportResolution.notes,
                  accusedOutcome: accusedResolution.outcomes,
                  accusedOutcomeDetails: accusedResolution.notes,
                  updated_by: reportResolution.updated_by
                };
                this.is_loading = false;
              } else {
                this.is_loading = false;
              }
            }
          })
          .catch((_) => {
            this.is_loading = false;
          });
      },
      async getLatestTimelineElement(outcomeType) {
        const baseUrl = url_utils.getEnvVariable("tales_api_url");

        try {
          const response = await talesApi.getTimelineElement(
            baseUrl,
            this.report.id,
            "resolution",
            { outcome_type: outcomeType }
          );

          const sortedData = response.data.sort((a, b) =>
            moment(b.updated_on).diff(moment(a.updated_on))
          );
          const latestDate = sortedData[0].updated_on;
          const latestElements = sortedData.filter((item) =>
            moment(item.updated_on).isSame(moment(latestDate))
          );

          const outcomes = latestElements.map((item) => item.outcome);
          const notes = latestElements[0].notes;
          const updated_by = latestElements[0].updated_by;
          const updated_on = latestElements[0].updated_on;

          return {
            outcomes,
            notes,
            updated_by,
            updated_on
          };
        } catch (_) {} // Do we want to toast an error message here?
      },
      async checkFirebaseStatus() {
        /* Check current firebase status */
        let current_date = Timestamp.fromDate(new Date());
        const db = getFirestore();

        const q = query(
          collection(db, "status"),
          orderBy("started_at", "asc"),
          where("started_at", "<=", current_date)
        );

        try {
          const querySnapshot = await getDocs(q);

          if (querySnapshot) {
            if (querySnapshot.docs.length > 0) {
              querySnapshot.docs.forEach((val) => {
                if (val.data().end_at >= current_date) {
                  if (val.data().type === "CHAT_MAINTENANCE") {
                    this.chat_maintenance = true;
                  }
                }
              });
            }
          }
        } finally {
          if (!this.chat_maintenance) {
            this.startLoadMessages = true;
          }
        }
      },
      markAsUnread() {
        reportApi
          .updateReportStatus(this.report.id, 3)
          .then((response) => {
            if (response.status === 200) {
              if (this._backRoute !== "/reports") {
                this.$router.push("/reports");
              } else {
                this.$router.go(-1);
              }
            }
          })
          .catch((_) => {
            this.is_loading = false;
          });
      },
      updateReportStatus(status) {
        return reportApi
          .updateReportStatus(this.report.id, status)
          .then((response) => {
            if (response.status === 200) {
              const newReport = { ...this.report, status_id: status };
              // If the new status is 'resolved', set 'resolved_at' with the current date-time
              if (status === 6 && !this.report.resolved_at) {
                newReport.resolved_at = new Date().toISOString();
              } else {
                // If the new status is not 'resolved', set 'resolved_at' to null
                newReport.resolved_at = null;
              }
              this.report = newReport;
              this.is_loading = false;
              this.$message({
                message: "Report status updated!",
                type: "success"
              });
              this.itemToRefresh = "info";
            }
          })
          .catch((_) => {
            this.is_loading = false;
          });
      },
      saveReport() {
        return this.updateReportStatus(2);
      },
      submitReport() {
        return this.updateReportStatus(3);
      },
      resolveReport() {
        return this.updateReportStatus(6);
      },
      can(name) {
        return permissions_utils.can(name);
      },
      beautifyDate(date) {
        return moment(date).format("lll");
      },
      clearItemToRefresh() {
        this.itemToRefresh = null;
      },
      updatePopoverVisible() {
        this.visible = !this.visible;
      },
      formatAssignees(assignees) {
        let formatted_assignees = [];
        assignees.forEach((assignee) => {
          formatted_assignees.push(assignee.id);
        });
        return formatted_assignees;
      },
      saveAssignees(value) {
        let assignees = [...value];
        let formatted_assignees = this.formatAssignees(assignees);
        this.updateAssignees(assignees, formatted_assignees);
      },
      formatTags(tags) {
        let formatted_tags = [];
        tags.forEach((tag) => {
          formatted_tags.push(tag.slug);
        });
        return formatted_tags;
      },
      saveTags(value) {
        let tags = [...value];
        let formatted_tags = this.formatTags(tags);
        this.updateTags(tags, formatted_tags);
      },
      saveCompany(value) {
        this.report.company = value;
        this.updateReportCompany(value.id);
      },
      saveNotes() {
        if (this.activeTab !== "timeline") {
          this.setActiveTab("timeline");
          setTimeout(() => {
            this.itemToRefresh = "notes";
          }, 1500);
        } else {
          this.itemToRefresh = "notes";
        }
      },
      updateReportCompany(company_id) {
        reportApi
          .updateReportCompany(this.report.id, company_id)
          .then((response) => {
            this.$message({
              message: this.$t("report_company_updated"),
              type: "success"
            });
          })
          .catch((err) => {
            this.$message({
              message: api.getErrorMessage(err),
              type: "error"
            });
          });
      },
      async generateReport(format) {
        this.is_loading_report_generate = true;
        try {
          const response = await flashApi.getGenerateReport(
            this.report.id,
            format
          );
          const url = window.URL.createObjectURL(new Blob([response.data]));
          const fileLink = document.createElement("a");
          fileLink.href = url;
          fileLink.setAttribute(
            "download",
            `report-${this.report.id}.${format}`
          );
          document.body.appendChild(fileLink);
          fileLink.click();
          document.body.removeChild(fileLink);
          this.$message({
            message: this.$t("report_generated"),
            type: "success"
          });
        } catch (error) {
          error.response.data.text().then((text) => {
            const res = JSON.parse(text);
            this.$message({
              message: res.detail,
              type: "error"
            });
          });
        } finally {
          this.is_loading_report_generate = false;
        }
      },
      updateAssignees(assignees, formatted_assignees) {
        this.is_loading_chat = true;
        reportApi
          .updateAssignees(this.report.id, formatted_assignees)
          .then((response) => {
            this.report.assignees = assignees;
            this.$message({
              message: this.$t("report_assignees_updated"),
              type: "success"
            });
            this.itemToRefresh = "assignees";
            this.is_loading_chat = false;
          })
          .catch((err) => {
            this.$message({
              message: api.getErrorMessage(err),
              type: "error"
            });
            this.isChatError = true;
            this.is_loading_chat = false;
          });
      },
      updateTags(tags, formatted_tags) {
        reportApi
          .updateTags(this.report.id, formatted_tags)
          .then((response) => {
            this.report.tags = tags;
            this.$message({
              message: this.$t("report_tags_updated"),
              type: "success"
            });
            this.itemToRefresh = "tags";
          })
          .catch((err) => {
            this.$message({
              message: api.getErrorMessage(err),
              type: "error"
            });
          });
      },
      uploadDocument(value) {
        let formData = new FormData();
        if (value.is_external_url) {
          this.isUrlUploading = true;
          formData.append("url", value.url);
          formData.append("is_external_url", value.is_external_url);
          if (value.title) {
            formData.append("title", value.title);
          }
        } else {
          value.files.forEach((element) => {
            formData.append("files[]", element);
          });
        }
        reportApi
          .uploadEvidence(this.report.id, formData, (progress) => {
            this.upload_progress = Math.floor(
              (progress.loaded * 100) / progress.total
            );
          })
          .then((response) => {
            if (response.status === 200) {
              this.getReport();
              this.itemToRefresh = "docs";
              this.$refs.sidebar.closeDocumentModal();
            }
          })
          .catch((err) => {
            this.$message.error(api.getErrorMessage(err));
          })
          .finally(() => {
            this.upload_progress = 0;
            this.isUrlUploading = false;
          });
      },
      deleteDocument(value) {
        this.$confirm(
          this.$t("delete_file_confirm"),
          this.$t("delete_file_confirm_description"),
          {
            confirmButtonText: this.$t("delete"),
            cancelButtonText: this.$t("cancel"),
            type: "error"
          }
        )
          .then(() => {
            reportApi
              .deleteEvidence(this.report.id, value)
              .then(() => {
                this.$message.success(this.$t("delete_file_success"));
                this.getReport();
                this.itemToRefresh = "docs";
              })
              .catch((err) => {
                this.$message.error(api.getErrorMessage(err));
              });
          })
          .catch(() => {});
      },
      isReportStatusUpdatable(status_id) {
        return report_utils.isReportStatusUpdatable(status_id);
      },
      isReportSaveable() {
        return report_utils.isReportStatus(this.report.status_id, 2);
      },
      isReportSubmittable() {
        return report_utils.isReportStatus(this.report.status_id, 3);
      },
      isReportResolved() {
        return report_utils.isReportStatus(this.report.status_id, 6);
      },
      getReportStepsFor(steps_id) {
        let steps = [];

        steps_id.forEach((step_id) => {
          const step_info = report_utils.getStep(step_id);
          steps.push({
            info: step_info,
            step: this.report.report_steps.find(
              (e) => e.step.name.toLowerCase() === step_info.name.toLowerCase()
            )
          });
        });

        return steps;
      },
      showMessageBox() {
        this.show_message = !this.show_message;
      },
      assignCurrentUser() {
        let user = {
          id: this.current_user.id,
          firstname: this.current_user.firstname,
          lastname: this.current_user.lastname,
          role: this.current_user.role
        };
        this.report.assignees.push(user);
        let formatted_assignees = this.formatAssignees(this.report.assignees);
        this.updateAssignees(this.report.assignees, formatted_assignees);
      },
      setActiveTab(tab) {
        this.activeTab = tab;
      },
      openReportResolutionModal() {
        this.showModal = true;
      },
      closeReportResolutionModal() {
        this.showModal = false;
      },
      exportReport(command) {
        const format = command.format;
        this.generateReport(format);
      },
      async saveResolution(resolution) {
        this.is_loading_resolution = true;

        try {
          // If the report is not resolved, resolve it first
          if (!this.isReportResolved()) {
            await this.resolveReport();
          }

          // Construct report type request payload
          const reportResolution = {
            type: "outcome_report",
            notes: resolution.conclusionDetailsData,
            outcomes: resolution.conclusionsData
          };

          // Construct accused type request payload
          const accusedResolution = {
            type: "outcome_accused",
            notes: resolution.accusedOutcomeDetails,
            outcomes: resolution.accusedOutcome
          };

          // Making simultaneous requests using Promise.all
          await Promise.all([
            reportApi.postReportResolution(this.report.id, reportResolution),
            reportApi.postReportResolution(this.report.id, accusedResolution)
          ]);

          this.$message({
            message: this.$t("report_resolution_saved"),
            type: "success"
          });

          this.report = {
            ...this.report,
            resolution: {
              ...resolution,
              updated_by: this.current_user.id,
              updated_on: moment().utc().format("YYYY-MM-DDTHH:mm:ss+00:00")
            }
          };

          this.closeReportResolutionModal();
        } catch (error) {
          this.$message({
            message: api.getErrorMessage(error),
            type: "error"
          });
        } finally {
          this.is_loading_resolution = false;
        }
      }
    }
  };
</script>

<style lang="scss" scoped>
  .report {
    margin-bottom: 20px;

    .report-header {
      &__actions-container {
        display: flex;
        flex-direction: row;
        align-items: center;
        gap: 10px;
        &-icon {
          margin-right: 4px;
          font-size: 14px;
        }
      }
    }

    .report-btn-header {
      font-size: 14px;
      line-height: 14px;
      @media (min-width: 576px) {
        padding: 12px;
        height: 40px;
        width: 40px;
      }
    }

    .container {
      @media (max-width: 768px) {
        max-width: none;
      }
    }

    .report-content-container {
      border: 1px $ui-light_grey solid;
      padding: 10px;
      border-radius: 10px;
      margin-bottom: 40px;

      @media (max-width: 768px) {
        margin-bottom: 0px;
        border: none;
      }

      .report-column {
        @media (max-width: 768px) {
          padding: 0px 5px;
        }
      }
    }

    .top-button {
      border-radius: 6px;
      padding: 4px 16px 2px 16px;
      margin: 5px 0px 5px 10px;
      @media (max-width: 576px) {
        margin: 0 0 8px;
        display: block;
        width: 100%;
      }
    }

    .loader-middle {
      top: 200px;
    }

    .report-card {
      text-align: left;

      .subtitle {
        color: $ui-subtitle;
        font-family: "Campton-Bold";
      }

      .sidebar-list {
        margin: 0;
        padding: 0;
        list-style: none;
        li + li {
          margin-top: 30px;
        }
      }
    }
  }
</style>
